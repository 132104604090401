import Money from './BetCostEntity';

export default class StakeEntity {
  value: number;
  cost: Money | null;

  constructor(value: number, cost: Money | null = null) {
    this.value = value;
    this.cost = cost;
  }
}
