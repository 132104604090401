import React, { useContext, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import UserBets from '../components/GamesPicker/UserBets';
import BetSlip from '../components/GamesPicker/BetSlip/BetSlip';

import { ConfigContext, UserBetsContext } from '../../shared/context';
import { RootStoreContext } from '../../lobby-engine/stores/RootStore';

import { UserBetTabInterface } from '../../shared/interfaces';

import { IS_LUCKYBALLS_CART, IS_RETAIL } from '../../shared/utils/themes';
import { pageNames } from '../../shared/utils';
import classNames from '../../shared/utils/classNames';
import { tabs } from '../../shared/utils/tabsConst';

import styles from '../components/GamesPicker/UserBets/UserBets.scss';
import themeStyles from '../assets/styles/lottery-styles.scss';

interface UserBetsContainerProps {
  onPageClose(): void;
  gameId: string;
}
/**
 * Access to history bets is limited for retail.
 * But in development, we still show it as it makes life way simpler.
 * @returns boolean
 */
const isRetail = () => {
  return IS_RETAIL && process.env.NODE_ENV != 'development';
};

const UserBetsContainer: React.FC<UserBetsContainerProps> = observer(
  ({ onPageClose, gameId }: UserBetsContainerProps) => {
    const userBetsContext = useContext(UserBetsContext);
    const betDetailsContext = useContext(RootStoreContext).betDetails;
    const navigation = useContext(RootStoreContext).navigation;
    const userToken = useContext(ConfigContext).userToken;
    const gameData = useContext(RootStoreContext).game.gameStructure.data;

    const getInitialPage = (): string => {
      if (IS_LUCKYBALLS_CART) {
        return pageNames.BET_SLIP;
      }

      if (gameData && gameData.allowInstantBets) {
        if (isRetail()) {
          return pageNames.USER_BETS_ACTIVE;
        }
        return pageNames.USER_BETS_HISTORY;
      }
      if (navigation.activePage == pageNames.USER_BETS_SINGLE) {
        return pageNames.USER_BETS_SINGLE;
      }
      return pageNames.USER_BETS_ACTIVE;
    };

    const getInitialBackPage = (): string => {
      if (isRetail()) {
        return pageNames.USER_BETS_ACTIVE;
      }
      return pageNames.USER_BETS_HISTORY;
    };

    const initialPage = getInitialPage();
    const [page, setPage] = useState<string>(initialPage);
    const [lastTab, setLastTab] = useState<string>(getInitialBackPage());

    const fetchUserBets = useCallback(
      (page = 1, showActiveBets = true): void => {
        userBetsContext.fetchUserBets(page, showActiveBets, userToken, gameId);
      },
      [userBetsContext, userToken, gameId]
    );

    const rememberLastTab = (pageName: string): void => {
      if (pageName == pageNames.USER_BETS_ACTIVE || pageName == pageNames.USER_BETS_HISTORY) {
        setLastTab(pageName);
      }
    };

    const onTabChange = (pageName: string): void => {
      setPage(pageName);
      rememberLastTab(pageName);
    };

    const renderTabOptions = (): JSX.Element[] => {
      return tabs.map((elem: UserBetTabInterface) => {
        //hide bets history on retail
        if (isRetail() && elem.name === pageNames.USER_BETS_HISTORY) {
          return null;
        }

        if (!isRetail() && elem.name === pageNames.USER_BETS_ACTIVE && gameData && gameData.allowInstantBets) {
          return null;
        }

        // if (elem.name === pageNames.USER_BETS_ACTIVE) {
        //   return null;
        // }

        if (elem.display) {
          return (
            <p
              key={elem.name}
              className={classNames(styles.headerOption, {
                [`${styles.headerOptionActive} ${themeStyles.headerOptionActive}`]: page === elem.name,
              })}
              onClick={(): void => onTabChange(elem.name)}
            >
              <FormattedMessage id={elem.messageId} />
            </p>
          );
        }

        return null;
      });
    };

    const renderContent = (): JSX.Element => {
      if (pageNames.BET_SLIP === page) {
        return <BetSlip />;
      }
      return (
        <UserBets
          isLoading={userBetsContext.isLoading}
          bets={userBetsContext.userBets}
          userBetsHeaders={userBetsContext.userBetsHeaders}
          error={userBetsContext.error}
          fetchUserBets={fetchUserBets}
          saveBetId={betDetailsContext.saveBetId}
          activePage={page}
          backToPage={lastTab}
          gameId={gameId}
          onTabChange={onTabChange}
        />
      );
    };

    return (
      <div className={`${styles.betsContainer} ${themeStyles.betsContainer}`}>
        <div className={styles.betsHeader}>
          <div className={styles.headerOptions}>
            <div className={styles.closeIconWrapper} onClick={(): void => onPageClose()}>
              <span className={styles.closeIcon}></span>
            </div>
            {renderTabOptions()}
          </div>
        </div>
        {renderContent()}
      </div>
    );
  }
);

export default UserBetsContainer;
