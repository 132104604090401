import { observable } from 'mobx';

import { constans } from '../../shared/utils';

import ConfigStoreInterface from '../../shared/interfaces/Stores/ConfigStoreInterface';

export default class ConfigStore implements ConfigStoreInterface {
  @observable isMobile: boolean;
  lotteryName: string;

  constructor() {
    this.checkIsMobile();
  }

  checkIsMobile(): void {
    this.isMobile = window.innerWidth <= 768;
  }

  setLotteryName(lotteryName: string): void {
    this.lotteryName = lotteryName;
  }

  get lineCountToAdd(): number {
    const isFastPicker = constans.lotteryNamesPickX.indexOf(this.lotteryName) != -1;

    if (this.isMobile || isFastPicker) {
      return constans.mobilePickerCount;
    } else {
      return constans.pickerCount;
    }
  }
}
