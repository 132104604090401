import { ApiInstructionsInterface } from '../../shared/interfaces/Api';

import { camelCaseChanger } from '../../shared/utils';
import { HeadersInterface } from '../../shared/interfaces';
import { LastDrawsResponseInterface, LastDrawDateResponseInterface } from '../../shared/interfaces/GamesPicker';

class ApiInstructionsConnector {
  public api: ApiInstructionsInterface;

  public constructor(api: ApiInstructionsInterface) {
    this.api = api;
  }

  async getLastDraws(
    gameId: string,
    date: string,
    utc: string,
    page: string,
    perPage: string
  ): Promise<LastDrawsResponseInterface> {
    const response = await this.api.getLastDraws(gameId, date, utc, page, perPage);

    if (!response.ok) {
      throw await response.json();
    }
    const headers: HeadersInterface = {};

    response.headers.forEach((val, key) => {
      const newKey = camelCaseChanger(key);

      headers[newKey] = val;
    });

    return {
      headers,
      data: await response.json(),
    };
  }

  async getLastDrawDate(gameId: string): Promise<LastDrawDateResponseInterface> {
    const response = await this.api.getLastDrawDate(gameId);

    if (!response.ok) {
      throw await response.json();
    }

    return {
      data: await response.json(),
    };
  }
}

export default ApiInstructionsConnector;
