import { observable, action, computed } from 'mobx';

import { CurrencyInterface } from '../../shared/interfaces';
import Money from '../entities/BetCostEntity';
import LineCostStoreInterface from '../../shared/interfaces/Stores/LineCostInterface';

/**
 * Analyzes default cost of line, and additional costs of line given by API
 * and finds out correct prize/cost for user based on his currency
 *
 * @see finalLineCost prop. This is the one you should use to get ticket cost.
 */
export default class LineCostStore implements LineCostStoreInterface {
  // Base cost of line as given by API
  @observable _baseLineCost: Money;
  // Final bet cost to be used by user
  @observable _finalLineCost: Money;
  // Any addtional currencies/costs as given by API
  @observable _additionalLineCosts: Money[] = [];
  // Desited currency of user as set by config of widget. This will be used as target for finding cost
  private desiredCurrency = 'UNKNOWN';

  constructor() {
    this._baseLineCost = new Money();
    this.findFinalCost();
  }

  /**
   * @inheritdoc
   */
  public configure(baseCost: CurrencyInterface, additionalCosts: CurrencyInterface[]): void {
    this._baseLineCost = new Money(baseCost);
    this._additionalLineCosts = [];
    if (additionalCosts && additionalCosts.length > 0)
      for (const cost of additionalCosts) {
        this._additionalLineCosts.push(new Money(cost));
      }
    this.findFinalCost();
  }

  /**
   * Calculates the final costs of ticket for user based on current configuration
   *
   * Checks if desired currency is on a list of additional currencies.
   * IF so, uses that currency to get ticket cost.
   * If not, falbbacks to default currency
   */
  private findFinalCost(): void {
    // Check if
    let shouldFallback = true;
    for (const additonalCost of this._additionalLineCosts) {
      if (additonalCost.currency === this.desiredCurrency) {
        this._finalLineCost = additonalCost;
        shouldFallback = false;
        break;
      }
    }
    if (shouldFallback) {
      this._finalLineCost = this._baseLineCost;
    }
  }

  /**
   * @inheritdoc
   */
  @action
  public setDesiredCurrency(currency: string): void {
    this.desiredCurrency = currency;
    this.findFinalCost();
  }

  /**
   * @inheritdoc
   */
  @computed
  public get finalLineCost(): Money {
    return this._finalLineCost;
  }
}
