import { RetailCashierUserEntity } from '../entities/RetailCashierUserEntity';
import ApiCashier from './ApiCashier';

class ApiCashierConnector {
  public api: ApiCashier;

  public constructor(api: ApiCashier) {
    this.api = api;
  }

  async getCashierDetails(gameId: string, token: string): Promise<RetailCashierUserEntity> {
    const response = await this.api.getCashierDetails(gameId, token);
    if (!response.ok) {
      throw await response.json();
    }
    return await response.json();
  }
}

export default ApiCashierConnector;
