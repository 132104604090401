import React from 'react';

import classNames from '../../../../shared/utils/classNames';

import styles from './Button.scss';

interface ButtonProps {
  type?: string;
  children: JSX.Element;
  onButtonClick(): void;
  btnClassName?: string;
  disable?: boolean;
}

const Button: React.FC<ButtonProps> = ({ type = 'default', btnClassName = '', children, disable, onButtonClick }) => {
  return (
    <div
      className={classNames(`${styles.defaultButton}`, {
        [styles.confirmButton]: type === 'confirm',
        [styles.dangerButton]: type === 'danger',
        [styles.inactiveButton]: type === 'inactive',
        [styles.disableButton]: disable,
        [btnClassName]: typeof btnClassName === 'string',
      })}
      onClick={onButtonClick}
    >
      {children}
    </div>
  );
};

export default Button;
