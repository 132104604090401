import * as React from 'react';
import ConfigInterface from '../interfaces/ConfigInterface';

const defaults: ConfigInterface = {
  elementName: 'defaultWidgetElementName',
  lang: 'EN',
  defaultLanguage: 'EN',
  lobbyId: '',
  brandId: null,
  lotteryId: '',
  currency: 'EUR',
  userToken: null,
  hiddenMode: false,
  onCloseRedirectTo: undefined,
  iFrameMode: false,
  noMoneyMessage: false,
};

const ConfigContext = React.createContext(defaults);

export default ConfigContext;
