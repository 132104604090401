import React, { useEffect } from 'react';

import Variant from './Variant';

import classNames from '../../shared/utils/classNames';

import useMobileWidth from '../../shared/hooks/useMobileWidth';

import { SectionInterface } from '../../shared/interfaces/Lobby/SectionInterface';
import { VariantInterface } from '../../shared/interfaces/Lobby/VersionInterface';

import styles from './Lobby.scss';

interface LobbyProps {
  variant: VariantInterface;
  onButtonClick: CallableFunction;
  id: string;
  isModalEnabled: boolean; //we need to set lobby unscrollable when modal is open to prevent weird scroll stacking
}

// Single Lobby
const Lobby: React.FC<LobbyProps> = (props: LobbyProps) => {
  const isMobile = useMobileWidth();

  useEffect(() => {
    //setting the body unscrollable
    if (isMobile && props.isModalEnabled) {
      document.body.style.height = '100vh';
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.height = null;
      document.body.style.overflow = null;
    }
  }),
    [isMobile, props.isModalEnabled];

  const renderSections = (): JSX.Element[] => {
    const { variant, onButtonClick } = props;

    return variant.lobbySections.map((elem: SectionInterface, index: number) => {
      if ((isMobile && elem.visibility === 'desktop') || (!isMobile && elem.visibility === 'mobile')) {
        return null;
      }

      return <Variant {...elem} {...{ onButtonClick }} key={`${elem.id}-${elem.type}-${index}`} />;
    });
  };

  return (
    <div
      className={classNames(`${styles.variantsContainer}`, { [styles.unscrollableContainer]: props.isModalEnabled })}
    >
      {renderSections()}
    </div>
  );
};

export default Lobby;
