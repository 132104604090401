import { AbstractSelection } from './AbstractSelection';

import { IntegerSelectionParamsInterface } from '../../../shared/interfaces/Entities/SelectionParams';
import { ClassSerializer } from '../../../shared/interfaces/ClassSerializer';
import { SelectionPlainInterface } from '../../../shared/interfaces/Stores';

export class IntegerSelection extends AbstractSelection implements ClassSerializer {
  constructor(params: IntegerSelectionParamsInterface) {
    super();
    this.type = 'integer';
    this.name = params.name;
    this.range = params.range;
    this.maxNumberCount = 1;
    this.minNumberCount = 1;
    this.maxAdditionalPermutationsNumberCount =
      params.additionalNumbersCount != undefined ? params.additionalNumbersCount : 0;
    this.numbers = new Array<number>(this.maxNumberCount);
  }

  turnPermutationOn(): void {
    this.isPermutationOn = true;
  }

  toPlainNormal(): SelectionPlainInterface {
    const numbers: { [k: string]: number } = {};
    numbers[this.name] = this.numbers[0];
    return numbers;
  }

  toPlainPerm(): number {
    return this.numbers[0];
  }
}
