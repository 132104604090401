import { action, observable, runInAction } from 'mobx';
import Money from '../entities/BetCostEntity';

import { WalletInterface } from '../../shared/interfaces';

import ApiPlayerBalance from '../services/ApiPlayerBalance';
import ApiPlayerBalanceConnector from '../services/ApiPlayerBalanceConnector';
import AppConfigStore from './AppConfigStore';

const api = new ApiPlayerBalance();
const apiConnector = new ApiPlayerBalanceConnector(api);

export default class PlayerBalanceStore {
  @observable isLoading = false;
  @observable data: Money = null;
  @observable wallet: WalletInterface = null;

  constructor(public appConfig: AppConfigStore) {}

  @action
  getPlayerBalance = async (gameId: string): Promise<void> => {
    this.isLoading = true;

    try {
      const playerBalance = await apiConnector.getPlayerBalance(gameId, this.appConfig.userToken);

      runInAction(() => {
        this.data = new Money(playerBalance);
        this.isLoading = false;
      });
    } catch (err) {
      this.isLoading = false;
    }
  };

  @action
  getPlayerWallet = async (): Promise<void> => {
    this.isLoading = true;
    try {
      const wallet = await apiConnector.getBalanceWallet(this.appConfig.userToken);
      runInAction(() => {
        this.wallet = wallet;
        this.isLoading = false;
      });
    } catch (err) {
      this.isLoading = false;
    }
  };
}
