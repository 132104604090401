import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import styles from './Select.scss';

interface OptionInterface {
  value: string | number;
  label: string;
}

interface SelectProps {
  defaultSelect?: string;
  options: OptionInterface[];
  isTranslated?: boolean;
  translationId?: string;
  translationVarName?: string;
  onChange(value: string): void;
}

const Select: React.FC<SelectProps> = (props: SelectProps) => {
  const { onChange, defaultSelect, translationVarName, translationId, isTranslated } = props;
  const intl = useIntl();
  const [selectedValue, setSelectedValue] = useState<string>(defaultSelect);

  const handleOptionChange = (value: string): void => {
    setSelectedValue(value);
    onChange(value);
  };

  const renderOptions = (): JSX.Element[] => {
    const { options } = props;
    return options.map((elem: OptionInterface) => {
      return (
        <option key={elem.label} value={elem.value}>
          {isTranslated ? intl.formatMessage({ id: translationId }, { [translationVarName]: elem.label }) : elem.label}
        </option>
      );
    });
  };

  return (
    <select className={styles.select} onChange={(e): void => handleOptionChange(e.target.value)} value={selectedValue}>
      {renderOptions()}
    </select>
  );
};

export default Select;
