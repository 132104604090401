import React from 'react';

import ModalConnector from '../../../shared/components/Modals/ModalConnector';
import { Modal } from '../generic';

import { DEPOSIT_MODAL } from '../../../shared/utils/modalNames';

import styles from './Modals.scss';

import error from '../../assets/images/error.png';

import config from '../../../../../app.config';

import { FormattedHTMLMessage } from 'react-intl';

interface DepositModalProps {
  isOpen: boolean;
  data: null;
  onClose(): void;
}

const DepositModal: React.FC<{}> = () => {
  return (
    <ModalConnector name={DEPOSIT_MODAL}>
      {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ isOpen, data, onClose }: DepositModalProps): JSX.Element => {
          return (
            <Modal {...{ onClose, isOpen }} contentOutside>
              <div className={styles.gameSummaryModal}>
                <img src={config.CDN_URL + error} className={styles.gameSummaryModalIcon} alt="icon" />
                <p className={styles.gameSummaryModalMainText}>
                  <FormattedHTMLMessage id={'play.modal.deposit'} />
                </p>
                {/* <hr />
              <div>
                <p className={styles.gameSummaryModalSecondaryText}>
                  <a
                    className={styles.gameSummaryModalCallToAction}
                    onClick={(): void => configContext.noMoneyCb()}
                  >
                    <FormattedMessage id={'play.modal.deposit.btn'} />
                  </a>
                </p>
              </div> */}
              </div>
            </Modal>
          );
        }
      }
    </ModalConnector>
  );
};

export default DepositModal;
