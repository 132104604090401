import { CurrencyInterface } from '../../shared/interfaces';

export default class Money implements CurrencyInterface {
  amount = 0;
  currency = 'UNKNOWN';

  getHumanAmount(): number {
    return this.amount / 100;
  }

  constructor(cost: CurrencyInterface | null = null) {
    if (cost) {
      this.amount = cost.amount;
      this.currency = cost.currency;
    }
  }

  /**
   * Coz, why not.
   * Welcome backo old C memories.
   */
  public toString(): string {
    return `${this.getHumanAmount()} ${this.currency}`;
  }
}

// value and currency only
