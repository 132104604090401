import Money from '../../lobby-engine/entities/BetCostEntity';
import { CurrencyInterface } from '../interfaces';
import { CachedStatsInterface, GameInterface } from '../interfaces/GamesPicker';
import {
  InstantTicketInterface,
  InstantTicketLineInterface,
  WinningSelectionPartsInterface,
} from '../interfaces/GamesPicker/TicketInterface';

//type simpleNumber = { name: string; value: number; isWon: boolean };

export class InstantTicket {
  cachedStats: CachedStatsInterface;
  createdAt: string;
  game: GameInterface;
  id: string;

  lines: InstantTicketLineInterface[];
  realLineCost: CurrencyInterface;
  stake: number;
  status: string;
  ticketNumber: number;
  winningSelectionPartsPerLine: WinningSelectionPartsInterface;

  constructor(ticket: InstantTicketInterface) {
    this.id = ticket.bet.id;
    this.cachedStats = ticket.bet.cachedStats;
    this.createdAt = ticket.bet.createdAt;
    this.game = ticket.bet.game;

    this.lines = ticket.bet.lines;
    this.realLineCost = ticket.bet.realLineCost;

    this.stake = ticket.bet.stake;
    this.status = ticket.bet.status;
    this.ticketNumber = ticket.bet.ticketNumber;
    this.winningSelectionPartsPerLine = ticket.winningSelectionPartsPerLine;
  }

  public get getTotalWinningsValue(): Money | undefined {
    if (this.status == 'won') {
      return new Money(this.cachedStats.totalRealWinnings);
    }
  }

  public get numberOfDrawnBalls(): number {
    let nrOfBalls = 0;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [_key, value] of Object.entries(this.lines[0].wonSelection.asArray)) {
      if (Array.isArray(value)) {
        nrOfBalls = nrOfBalls + value.length;
      } else {
        nrOfBalls += 1;
      }
    }
    return nrOfBalls;
  }

  /**
   * Returnes modified selections objects with winnings marked if possible.
   */
  // public get getLinesWithWinnings(): Record<string, { name: string; numbers: simpleNumber[] }> {
  //   const linesWithWinnings: Record<string, { name: string; numbers: simpleNumber[] }> = {};
  //   this.lines.map((line) => {
  //     for (const key in line.selection.asArray) {
  //       //iterate over all selections of a line
  //       if (Object.prototype.hasOwnProperty.call(line.selection.asArray, key)) {
  //         const selection = line.selection.asArray[key];
  //         const transformedSelection: {
  //           name: string;
  //           numbers: simpleNumber[];
  //         } = {
  //           name: key,
  //           numbers: [],
  //         };

  //         //if provided numbers are array
  //         if (Array.isArray(selection)) {
  //           for (const element, index of selection) {
  //             const number = {
  //               name: `${key}${index + 1}`,
  //               value: element,
  //               isWon: false,
  //             };
  //             transformedSelection.numbers.push(number);
  //           }
  //         }
  //         //if just a number
  //         else {
  //           const number = {
  //             name: `${key}1`,
  //             value: selection,
  //             isWon: false,
  //           };
  //           transformedSelection.numbers.push(number);
  //         }
  //         linesWithWinnings[line.id] = transformedSelection;
  //       }
  //     }

  //     return linesWithWinnings;
  //   });
  // }
}
