import { observable, action } from 'mobx';

import { ErrorMessage } from '../../shared/interfaces';
import { DataApiInterface } from '../../shared/interfaces/Entities/DataApi';

abstract class DataApi<DataType> implements DataApiInterface<DataType> {
  @observable data: DataType = null;
  @observable isLoading = false;
  @observable error: ErrorMessage | null = null;

  @action
  resetDataApi(): void {
    this.data = null;
    this.isLoading = false;
    this.error = null;
  }
}

export default DataApi;
