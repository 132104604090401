import TicketSelection from './TicketSelection';
import {
  TicketSelectionsInterface,
  PlainTicketSelection,
  SelectionPartInterface,
} from '../../../shared/interfaces/Entities/TicketSelections';

export default class TicketSelections implements TicketSelectionsInterface {
  selections: TicketSelection[] = [];

  constructor(selectionParts: SelectionPartInterface[]) {
    this.selections = selectionParts.map((selection: SelectionPartInterface) => {
      return new TicketSelection(selection);
    });
  }

  toPlain(): PlainTicketSelection[] {
    return this.selections.map((selection: TicketSelection) => {
      return selection.toPlain();
    });
  }
}
