import { UNAUTHORIZED } from './errorSlugs';

import { ErrorModalInterface } from '../interfaces/Lobby/ModalsInterface';

export const defaultErrorLoginData: ErrorModalInterface = {
  errorData: {
    id: 'play.modal.error.401',
    slug: UNAUTHORIZED,
  },
  code: 401,
  closePicker: null,
};
