import React, { useCallback, useEffect } from 'react';

import { BetsList } from './BetsList';
import BetDetailsContainer from '../../../containers/BetDetailsContainer';

import { UserBetDataInterface } from '../../../../shared/interfaces/GamesPicker';
import { ErrorMessage, HeadersInterface } from '../../../../shared/interfaces';

import { pageNames } from '../../../../shared/utils';

export interface UserBetsProps {
  isLoading: boolean;
  bets: UserBetDataInterface[];
  userBetsHeaders: HeadersInterface;
  error: ErrorMessage;
  activePage: string;
  backToPage: string;
  gameId: string;
  saveBetId(id: string, globalIntId?: number, secretCode?: number): void;
  fetchUserBets(page: number, showActiveBets: boolean): void;
  onTabChange(pageName: string): void;
}

export const UserBets: React.FC<UserBetsProps> = ({
  fetchUserBets,
  saveBetId,
  onTabChange,
  activePage,
  backToPage,
  bets,
  error,
  isLoading,
  userBetsHeaders,
  gameId,
}: UserBetsProps) => {
  const showActiveBets = activePage === pageNames.USER_BETS_ACTIVE;

  useEffect(() => {
    fetchUserBets(1, showActiveBets);
  }, [fetchUserBets, showActiveBets]);

  const onPaginationPageChange = useCallback(
    (page: number) => {
      fetchUserBets(page, showActiveBets);
    },
    [fetchUserBets, showActiveBets]
  );

  const onBetDetailsOpen = useCallback(
    (id: string, globalIntId, secretCode): void => {
      saveBetId(id, globalIntId, secretCode);
      onTabChange(pageNames.USER_BETS_SINGLE);
    },
    [saveBetId, onTabChange]
  );

  const renderContent = (): JSX.Element => {
    if (activePage === pageNames.USER_BETS_SINGLE) {
      return <BetDetailsContainer onBetDetailsClose={(): void => onTabChange(backToPage)} gameId={gameId} />;
    }

    return (
      <BetsList
        userBets={bets}
        userBetsHeaders={userBetsHeaders}
        userBetsLoading={isLoading}
        userBetsError={error}
        onBetDetailsOpen={onBetDetailsOpen}
        onPaginationPageChange={onPaginationPageChange}
        activePage={activePage}
      />
    );
  };

  return renderContent();
};

export default UserBets;
