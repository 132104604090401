import appConfig from '../../../../app.config';

import { IS_RETAIL } from './themes';

export function getUrlWithParams<T extends {}>(apiUrl: string, params?: T): string {
  const url = new URL(apiUrl);
  url.search = new URLSearchParams(params).toString();

  return url.href;
}

export function getProperApiUrl<T extends {}>(endPoint?: string, params?: T): string {
  const url = IS_RETAIL ? '/api/public/retail-widget/v1/me-shop' : '/api/public/widget/v1/me-player';
  // const url = '/api/public/widget/v1/me-player';
  const desiredUrl = appConfig.APP_URL + url + endPoint;

  if (params) {
    return getUrlWithParams(desiredUrl, params);
  }

  return desiredUrl;
}
