import { AbstractSelection } from './AbstractSelection';

import { RangeSelectionParamsInterface } from '../../../shared/interfaces/Entities/SelectionParams';
import { ClassSerializer } from '../../../shared/interfaces/ClassSerializer';

export class RangeSelection extends AbstractSelection implements ClassSerializer {
  constructor(params: RangeSelectionParamsInterface) {
    super();
    this.type = 'range';
    this.name = params.name;
    this.range = params.range;
    this.maxNumberCount = params.maxNumberCount;
    this.minNumberCount = params.minNumberCount;
    this.allowNonUniqueNumbers = params.allowNonUniqueNumbers;
    this.maxAdditionalPermutationsNumberCount =
      params.additionalNumbersCount != undefined ? params.additionalNumbersCount : 0;
    this.numbers = new Array<number>(this.maxNumberCount);
  }
}
