import { action, observable } from 'mobx';

import LineCostStoreInterface from '../../shared/interfaces/Stores/LineCostInterface';
import StakeEntity from '../entities/StakeEntity';
import Money from '../entities/BetCostEntity';
import StakesStoreInterface from '../../shared/interfaces/Stores/StakesInterface';

/**
 * TODO: Potentialy refactor so StakeStore actually uses Money instead of just percentage values.
 * But, this will make it more complicated, which might not be desired.
 */
export default class StakeStore implements StakesStoreInterface {
  rawStakesList: number[] = [];
  @observable selectedStake: StakeEntity;
  @observable stakesList: StakeEntity[] = [];
  costStore: LineCostStoreInterface;

  constructor(baseBetCost: LineCostStoreInterface) {
    this.costStore = baseBetCost;
    this.selectedStake = new StakeEntity(100, this.costStore.finalLineCost);
  }

  @action getDefaultStake = (): void => {
    if (this.stakesList.length) {
      this.selectedStake =
        this.stakesList.length < 3 ? this.stakesList[this.stakesList.length - 1] : this.stakesList[2];

      return;
    }
    //we dont have stakes on super 4 -> returning default value of 100%
    this.selectedStake = new StakeEntity(100, this.costStore.finalLineCost);
  };

  @action setStakesValues = (): void => {
    const newStakes: StakeEntity[] = [];
    this.rawStakesList.forEach((elem: number) => {
      const cost = new Money();
      cost.amount = (elem / 100) * this.costStore.finalLineCost.amount;
      cost.currency = this.costStore.finalLineCost.currency;
      newStakes.push(new StakeEntity(elem, cost));
    });
    this.stakesList = newStakes;
    this.getDefaultStake();
  };

  @action
  changeSelectedStake(stake: StakeEntity): void {
    this.selectedStake = stake;
  }

  @action
  resetStakes(): void {
    this.stakesList = [];
    this.selectedStake = new StakeEntity(100, this.costStore.finalLineCost);
  }

  setRawStakes(stakes: number[]): void {
    this.rawStakesList = stakes;
  }
}
