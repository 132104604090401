interface ChangeableClassesInterface {
  [key: string]: boolean;
}

export default function classNames(staticClasses: string, changeableClasses?: ChangeableClassesInterface): string {
  if (typeof staticClasses !== 'string') {
    return null;
  }

  return staticClasses.concat(
    ' ',
    Object.entries(changeableClasses)
      .map(([key, value]) => (value ? key : ''))
      .join(' ')
  );
}
