import React from 'react';

import styles from './NumberBalls.scss';
import LineEntity from '../../../../../lobby-engine/entities/LineEntity';
import { selectionType } from '../../../../../shared/interfaces/Entities/SelectionParams';

interface NumberBallsProps {
  line: LineEntity;
  classNames?: string;
  additionalClassNames?: string;
  type?: string;
}

const NumberBalls: React.FC<NumberBallsProps> = ({ line, classNames, additionalClassNames }: NumberBallsProps) => {
  const renderBall = (ballNumber: number, classes: string, id: string): JSX.Element => {
    return (
      <div className={`${classes}`} key={id}>
        {ballNumber}
      </div>
    );
  };

  const renderSelection = (sel: selectionType): JSX.Element[] => {
    return [...Array(sel.maxNumberCount)].map((numPos, index) => {
      const number = sel.numbers[index];
      if (sel.isSubType) {
        return null;
      }

      if (sel.name === 'main') {
        return renderBall(number, `${styles.baseBall} ${classNames}`, `main-${index}`);
      } else {
        return renderBall(
          number,
          `${styles.baseBall} ${styles.baseAdditionalBall} ${additionalClassNames}`,
          `additional-${index}`
        );
      }
    });
  };

  return (
    <div className={styles.baseBallsWrapper}>
      {line.selectionsArray.map((sel) => {
        return renderSelection(sel);
      })}
    </div>
  );
};

export default NumberBalls;
