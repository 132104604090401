import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';

import { Select } from '../../../generic';
import Spinner from '../../../../../shared/components/Spinner';
import Pagination from '../../Pagination';

import { DrawInterface } from '../../../../../shared/interfaces/GamesPicker';
import { ErrorMessage, HeadersInterface } from '../../../../../shared/interfaces';

import { getDaysOptions, getMonthOptions, getYearOptions } from '../../../../../shared/utils/selectOptions';
import { getDateFromData, getToday } from '../../../../../shared/utils';

import numberStyles from '../../../../assets/styles/numbers.scss';
import styles from '../Instructions/Instructions.scss';
import themeStyles from '../../../../assets/styles/lottery-styles.scss';
interface StateDateInterface {
  day: string;
  month: string;
  year: string;
}
interface GameResultsProps {
  lastDraws: DrawInterface[];
  drawsHeaders: HeadersInterface;
  isLoading: boolean;
  isError: ErrorMessage;
  onDateChange(date: string): void;
  onPageChange(page: number): void;
}

const GameResults: React.FC<GameResultsProps> = observer((props: GameResultsProps) => {
  const { lastDraws, onDateChange, isError, isLoading, drawsHeaders, onPageChange } = props;

  const [date, setDate] = useState<StateDateInterface>({ ...getToday() });

  const onOptionChange = (name: string, value: string): void => {
    setDate((prevState: StateDateInterface) => {
      const newData = {
        ...prevState,
        [name]: value,
      };
      const dateToString = getDateFromData(newData);
      onDateChange(dateToString);

      return newData;
    });
  };

  const renderNumbers = (numbers: number[]): JSX.Element[] => {
    return numbers.map((elem: number, index: number) => {
      return (
        <div
          key={`${index}-${elem}`}
          className={`${numberStyles.betNumber} ${numberStyles.betNumberBright} ${themeStyles.betNumber}`}
        >
          {elem}
        </div>
      );
    });
  };

  const renderResults = (): JSX.Element[] | JSX.Element => {
    if (isLoading && !lastDraws) {
      return <Spinner size={75} />;
    }

    if (isError !== null) {
      return (
        <p className={styles.noResults}>
          <FormattedMessage id={'error.message.default'} />
        </p>
      );
    }

    if (!lastDraws) {
      return null;
    }

    if (lastDraws.length === 0 || isError) {
      return (
        <p className={styles.noResults}>
          <FormattedMessage id={'game.rules.message.empty'} />
        </p>
      );
    }

    return lastDraws.map((elem: DrawInterface) => {
      if (!elem.wonBetSelection.asArray) {
        return null;
      }

      return (
        <div key={`${elem.id}`} className={styles.resultsCol}>
          <p className={styles.resultsTxt}>
            <FormattedMessage id="learn.text.results" values={{ date: dayjs(elem.drawDatetime).format('HH:mm') }} />
          </p>
          <div className={`${numberStyles.betNumbers} ${numberStyles.betNumbersCentered}`}>
            {renderNumbers(elem.wonBetSelection.asArray['main'])}
          </div>
        </div>
      );
    });
  };

  return (
    <React.Fragment>
      <div className={`${styles.results} ${themeStyles.results}`}>
        <div className={`${styles.resultsHeader} ${themeStyles.resultsHeader}`}>
          <p className={styles.resultsHeaderTitle}>
            <FormattedMessage id="bets.results" />
          </p>
          <div className={styles.selectWrapper}>
            <p className={styles.resultsHeaderSelectDate}>
              <FormattedMessage id={'info.game.results.select.date'} />
            </p>
            <div className={`${styles.selectionWrapper} ${styles.selectSize}`}>
              <Select
                options={getDaysOptions(date.year, date.month)}
                onChange={(value): void => onOptionChange('day', value)}
                defaultSelect={date.day}
              />
              <Select
                options={getMonthOptions()}
                onChange={(value): void => onOptionChange('month', value)}
                defaultSelect={date.month}
                isTranslated
                translationId="select.month"
                translationVarName="month"
              />
              <Select
                options={getYearOptions(date.year)}
                onChange={(value): void => onOptionChange('year', value)}
                defaultSelect={date.year}
              />
            </div>
          </div>
        </div>
        <div className={styles.resultsBody}>{renderResults()}</div>
        <div className={styles.paginationWrapper}>
          <Pagination
            theme="light"
            previousLabel={'-'}
            nextLabel={'+'}
            onPageChange={onPageChange}
            pageCount={parseInt(drawsHeaders.xLastPage)}
            paginationPage={parseInt(drawsHeaders.xPage)}
          />
        </div>
      </div>
    </React.Fragment>
  );
});

export default GameResults;
