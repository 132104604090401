import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Spinner from '../../../../../shared/components/Spinner';
import BetsListView from './BetsListView';
import { Error } from '../../../generic';

import { UserBetDataInterface } from '../../../../../shared/interfaces/GamesPicker';
import { ErrorMessage, HeadersInterface } from '../../../../../shared/interfaces';

import ownStyles from './BetsList.scss';

interface BetsListProps {
  userBets: UserBetDataInterface[];
  userBetsHeaders: HeadersInterface;
  userBetsLoading: boolean;
  userBetsError: ErrorMessage;
  activePage: string;
  onBetDetailsOpen(id: string, globalIntId?: number, secretCode?: number): void;
  onPaginationPageChange(page: number): void;
}

const BetsList: React.FC<BetsListProps> = ({
  activePage,
  userBets,
  userBetsLoading,
  userBetsError,
  userBetsHeaders,
  onBetDetailsOpen,
  onPaginationPageChange,
}: BetsListProps) => {
  const renderBetsList = (): JSX.Element => {
    if ((!userBets && !userBetsError) || userBetsLoading) {
      return <Spinner />;
    }

    if (userBetsError) {
      return <Error errorCode={userBetsError.statusCode} slug={userBetsError.slugCode} />;
    }

    if (userBets.length === 0) {
      return (
        <p className={ownStyles.infoTxt}>
          <FormattedMessage id="bets.no.bets" values={{ page: activePage }} />
        </p>
      );
    }

    return (
      <BetsListView
        userBets={userBets}
        userBetsHeaders={userBetsHeaders}
        onBetDetailsOpen={onBetDetailsOpen}
        onPaginationPageChange={onPaginationPageChange}
      />
    );
  };

  return renderBetsList();
};

export default BetsList;
