exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".jDnoUhas_pkruYTfd6A5Q{background-color:#fff;color:#000;width:83mm;border-bottom:1px dotted #000;padding-bottom:1em;padding-top:1em;page-break-after:avoid}._2reNk3af-xhZu2F02Nd5s8{display:flex;flex-direction:column;align-items:center}._2reNk3af-xhZu2F02Nd5s8 ._2OgrEgZl2CeHtZwOqswFjr{flex:1;min-height:100px;display:flex;align-items:center}._2reNk3af-xhZu2F02Nd5s8 ._2OgrEgZl2CeHtZwOqswFjr img{margin:auto;height:auto;width:100%;max-height:100px;-webkit-filter:grayscale(100%);filter:grayscale(100%)}._2reNk3af-xhZu2F02Nd5s8 ._3Wm5Qfb6zvtdIPzTbka6Y8{flex:1;font-weight:bold;font-size:2em;word-wrap:break-word;text-align:center}._2v0QGpilb7tExy-cx4El5J{display:flex;flex-direction:column;gap:1em;padding-top:1em}._2URTlljBA8Ttb4EAHgZKXN ._2AJLeGbbI0LjFLb_6TUw6_{display:flex;flex-direction:row;justify-content:space-between;align-items:flex-end}._2URTlljBA8Ttb4EAHgZKXN ._2AJLeGbbI0LjFLb_6TUw6_ ._2Pa1iTMR77rZ7Vtig9XrEm,._2URTlljBA8Ttb4EAHgZKXN ._2AJLeGbbI0LjFLb_6TUw6_ ._3RN9_g04Q1RG_g3k1kETF2{padding:0 .5rem}._2URTlljBA8Ttb4EAHgZKXN ._2AJLeGbbI0LjFLb_6TUw6_ ._3RN9_g04Q1RG_g3k1kETF2{font-size:.7em;color:#585858}._3O_vl6ahNn2CnrI0fI3mLY ._2bd5Mt2-i4LuZ2u-v-hSql{display:flex;flex-direction:row;flex-wrap:wrap;justify-content:center;gap:1em;padding:.5em 0}._3O_vl6ahNn2CnrI0fI3mLY ._2bd5Mt2-i4LuZ2u-v-hSql ._1yQS1Ts5HEUHFeyT3ZgPdu{flex:0 0 2em;width:2em;height:2em;border:2px solid #000;border-radius:1em;display:flex;align-items:center;justify-content:center}._3O_vl6ahNn2CnrI0fI3mLY ._2bd5Mt2-i4LuZ2u-v-hSql ._1yQS1Ts5HEUHFeyT3ZgPdu ._34Y5_8QXRMegCavTcIGq_B{margin:auto}._3O_vl6ahNn2CnrI0fI3mLY ._2bd5Mt2-i4LuZ2u-v-hSql ._2rDBzL5QCxFNCVuNwhJgp2{border-color:#cdcdcd}", ""]);

// Exports
exports.locals = {
	"container": "jDnoUhas_pkruYTfd6A5Q",
	"gameInfoContainer": "_2reNk3af-xhZu2F02Nd5s8",
	"gameLogo": "_2OgrEgZl2CeHtZwOqswFjr",
	"gameName": "_3Wm5Qfb6zvtdIPzTbka6Y8",
	"drawsContainer": "_2v0QGpilb7tExy-cx4El5J",
	"drawInfoContainer": "_2URTlljBA8Ttb4EAHgZKXN",
	"drawBasicInfo": "_2AJLeGbbI0LjFLb_6TUw6_",
	"drawDate": "_2Pa1iTMR77rZ7Vtig9XrEm",
	"drawNumber": "_3RN9_g04Q1RG_g3k1kETF2",
	"drawResults": "_3O_vl6ahNn2CnrI0fI3mLY",
	"numbersRow": "_2bd5Mt2-i4LuZ2u-v-hSql",
	"number": "_1yQS1Ts5HEUHFeyT3ZgPdu",
	"span": "_34Y5_8QXRMegCavTcIGq_B",
	"mainNumber": "_2rDBzL5QCxFNCVuNwhJgp2"
};