import { HeadersInterface } from '../interfaces';

const perPage = 5;
const defaultPaginationPage = 1;

// user bets values
const defaultHeaders: HeadersInterface = {
  cacheControl: 'no-cache',
  contentType: 'application/json',
  xLastPage: '0',
  xPage: '1',
  xPerPage: '5',
  xTotal: '0',
};

const defaultResultsHeaders: HeadersInterface = {
  ...defaultHeaders,
  xPerPage: '50',
};

// Game values
const defaultLinesCount = [4];
const mobilePickerCount = 1;
const pickerCount = 4;
const defaultDrawsNumber = 1;
const defaultDrawsNumberIndex = 0;
const defaultStakeValue = 1;
const fiveNinety = '5/90';
const defaultChance = 5;
const defaultTurbo = 5;
const defaultNap = 5;

//draws
const mobileDrawCount = 6;
const desktopDrawCount = 8;
const defaultNumberOfDraws = [
  {
    active: true,
    value: 1,
    disable: false,
  },
  {
    active: false,
    value: 2,
    disable: false,
  },
  {
    active: false,
    value: 4,
    disable: false,
  },
  {
    active: false,
    value: 8,
    disable: false,
  },
];

const lotteryNamesDrawSelection: string[] = [
  '5/90',
  'Turbo2',
  'Turbo2Editec',
  'Chance Plus',
  'ChancePlus',
  'RNG 5/90',
  'RNG Turbo2',
  'RNG Turbo2Editec',
  'RNG Chance Plus',
  'RNG ChancePlus',
  'Rng - RngChancePlus',
];

const lotteryDropdown: string[] = [
  '5/90',
  'Turbo2',
  'Turbo2Editec',
  'Chance Plus',
  'ChancePlus',
  'RNG 5/90',
  'RNG Turbo2',
  'RNG Turbo2Editec',
  'RNG Chance Plus',
  'RNG ChancePlus',
  'Rng - RngChancePlus',
];

const lotteryChangeablePickCount: string[] = [
  '5/90',
  'Chance Plus',
  'ChancePlus',
  'RNG 5/90',
  'RNG Chance Plus',
  'RNG ChancePlus',
  'Rng - RngChancePlus',
];

const lotteryTurboTypePicker: string[] = ['Turbo2', 'Turbo2Editec'];

const lotteryNamesPickX: string[] = [
  'ils-pick-3',
  'rng-pick-3',
  'standalone-pick-3',
  'standalone-pick-4',
  'rng-pick-4',
  'Standalone Pick 4',
  'Standalone Pick 3',
  'Rng Pick 4',
];

export default {
  perPage,
  defaultDrawsNumber,
  defaultHeaders,
  defaultResultsHeaders,
  defaultPaginationPage,
  defaultLinesCount,
  defaultTurbo,
  defaultNap,
  mobilePickerCount,
  pickerCount,
  defaultStakeValue,
  fiveNinety,
  mobileDrawCount,
  desktopDrawCount,
  defaultNumberOfDraws,
  defaultChance,
  lotteryNamesDrawSelection,
  lotteryNamesPickX,
  lotteryDropdown,
  lotteryChangeablePickCount,
  lotteryTurboTypePicker,
  defaultDrawsNumberIndex,
};
