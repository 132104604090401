export default {
  GAME_PLAY: 'GAME_PLAY',
  GAME_RULES: 'GAME_RULES',
  GAME_INFORMATION: 'GAME_INFORMATION',
  USER_BETS: 'USER_BETS',
  USER_BETS_HISTORY: 'USER_BETS_HISTORY',
  USER_BETS_ACTIVE: 'USER_BETS_ACTIVE',
  USER_BETS_SINGLE: 'USER_BETS_SINGLE',
  BET_SLIP: 'BET_SLIP',
  LOBBY: 'LOBBY',
  SCAN_SLIP: 'SCAN_SLIP',
  RETAIL_DRAW_RESULTS: 'RETAIL_DRAW_RESULTS',
};
