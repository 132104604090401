import React from 'react';
import { FormattedMessage } from 'react-intl';

import Spinner from '../../../../../shared/components/Spinner';
import { Error } from '../../../generic';

import { ErrorMessage, ImageInterface } from '../../../../../shared/interfaces';

import { pageNames } from '../../../../../shared/utils';

import mainStyles from '../../../../assets/styles/index.scss';
import styles from './Instructions.scss';
import themeStyles from '../../../../assets/styles/lottery-styles.scss';
import buttonStyles from '../../../../components/GamesPicker/Information/Instructions/Instructions.scss';

interface InstructionsProps {
  onClose: CallableFunction;
  onPageChange(page: string): void;
  onPageClose: CallableFunction;
  instructionPage: string;
  isLoading: boolean;
  error: ErrorMessage;
  img: ImageInterface;
}

const Instructions: React.FC<InstructionsProps> = (props: InstructionsProps) => {
  const { instructionPage, error, isLoading, onPageChange, onPageClose, img } = props;
  const renderContent = (): JSX.Element => {
    return (
      <React.Fragment>
        <div className={`${styles.instructions} ${themeStyles.instructions}`}>
          <div className={styles.instructionsHeader}>
            <div
              onClick={(): void => onPageClose()}
              className={`${mainStyles.closeIconWrapper} ${styles.instructionsHeaderCloseIcon}`}
            >
              <span className={mainStyles.closeIcon}></span>
            </div>
            {img && <img className={styles.instructionsLogo} src={img.url} alt="logo" />}
            <span className={`${styles.instructionsHeaderTitle} ${themeStyles.instructionsHeaderTitle}`}>
              <FormattedMessage id="learn.title" />
            </span>
          </div>
          <div className={styles.instructionsBody}>
            {instructionPage && (
              <div
                dangerouslySetInnerHTML={{
                  __html: instructionPage,
                }}
              />
            )}
          </div>
          <div className={`${styles.instructionsGameRulesWrapper} ${styles.hideRules}`}>
            <p className={styles.instructionsGameRules} onClick={(): void => onPageChange(pageNames.GAME_RULES)}>
              <FormattedMessage id="game.rules.link.open" />
            </p>
          </div>
          <div className={buttonStyles.infoCentered}>
            <button className={buttonStyles.infoBtn} onClick={(): void => onPageClose()}>
              <FormattedMessage id="learn.btn1" />
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderPage = (): JSX.Element => {
    if (isLoading || (!instructionPage && !error)) {
      return (
        <div className={styles.spinnerWrapper}>
          <Spinner />
        </div>
      );
    }

    if (error) {
      return <Error errorCode={error.statusCode} slug={error.slugCode} />;
    }

    return renderContent();
  };

  return <div className={styles.infoContainer}>{renderPage()}</div>;
};

export default Instructions;
