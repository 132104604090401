import React, { useState, useRef } from 'react';

import { FormattedMessage } from 'react-intl';
import useOuterClick from '../../../../../shared/hooks/useOuterClick';

import { GameChancesDropdownInterface } from '../../../../../shared/interfaces/GamesPicker';

import chevronDown from '../../../../assets/images/chevron-down.svg';
import styles from './Dropdown.module.scss';

import config from '../../../../../../../app.config.js';
import LineEntity from '../../../../../lobby-engine/entities/LineEntity';

interface DropdownProps {
  options: GameChancesDropdownInterface[];
  selectedParameter: number;
  line: LineEntity;
  onOptionSelect(option: number): void;
}

const Dropdown: React.FC<DropdownProps> = (props: DropdownProps) => {
  const { options, onOptionSelect, selectedParameter, line } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>();

  useOuterClick(ref, () => setIsOpen(false), isOpen);

  const onDropdownOpenClick = (): void => {
    setIsOpen(true);
  };

  const onOptionClick = (option: number): void => {
    onOptionSelect(option);
    setIsOpen(false);
  };

  const renderOptions = (): JSX.Element[] => {
    return options.map((elem: GameChancesDropdownInterface, index: number) => {
      return (
        <div
          key={`${index}-${elem.label}`}
          className={styles.dropdownItem}
          onClick={(): void => onOptionClick(elem.value)}
        >
          {elem.label}
        </div>
      );
    });
  };

  const renderHeader = (): JSX.Element => {
    if (line.isPermutationOn) {
      return (
        <FormattedMessage
          id="play.bet.text.info.pick.perm"
          values={{
            name: options[0].name,
            parameterNumber: selectedParameter,
            number: line.realLinesCount,
          }}
        />
      );
    }

    return (
      <FormattedMessage
        id="play.bet.text.info.pick"
        values={{
          name: options[0].name,
          parameterNumber: selectedParameter,
          number: line.leftToChooseWholeLine(),
        }}
      />
    );
  };

  return (
    <div className={styles.dropdownContainer} ref={ref}>
      <div className={`${styles.dropdownItem} ${styles.dropdownItemActive}`} onClick={onDropdownOpenClick}>
        <p className={styles.dropdownSelected}>{renderHeader()}</p>
        <img className={styles.dropdownIcon} src={config.CDN_URL + chevronDown} alt="down" />
      </div>
      {isOpen && <div className={styles.dropdownOptions}>{renderOptions()}</div>}
    </div>
  );
};

export default Dropdown;
