import React from 'react';
import { classNames } from '../../utils';

import ownStyles from './Bar.scss';

interface BarProps extends React.HTMLAttributes<HTMLDivElement> {
  type?: 'info' | 'success' | 'danger' | 'primary';
  className?: string;
}

const Bar: React.FC<BarProps> = ({ children, type, className, ...rest }) => {
  const typeClass = ownStyles[`${type}Type`];

  return (
    <div
      className={classNames(`${ownStyles.bar} ${typeClass}`, {
        [className]: typeof className === 'string',
      })}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Bar;
