import React, { useState, useContext, ReactText, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import Countdown from '../../../Countdown';

import { ImageInterface } from '../../../../../shared/interfaces';
import { DrawInterface } from '../../../../../shared/interfaces/GamesPicker';
import InstantTicketsBar from '../InstantTicketsBar/InstantTicketsBar';

import Money from '../../../../../lobby-engine/entities/BetCostEntity';
import DrawTimerEntity from '../../../../../lobby-engine/entities/DrawTimeEntity';

import {
  classNames,
  frankCurrencyFormatter,
  numberFormatter,
  constans,
  getHumanAmount,
} from '../../../../../shared/utils';

import ConfigContext from '../../../../../shared/context/ConfigContext';

import styles from './GameTopBar.scss';
import mainStyles from '../../../../assets/styles/index.scss';

import useMobileWidth from '../../../../../shared/hooks/useMobileWidth';

interface GameTopBarProps {
  areInstantBets: boolean;
  name: string;
  timesStake: ReactText;
  fastPicker: boolean;
  turboPicker: boolean;
  jackpotAmount: string;
  lotteryStructureName: string;
  image: ImageInterface | null;
  nearestDraw: DrawInterface;
  countdown: DrawTimerEntity;
  getNearestDraw(): void;
  onClose: CallableFunction;
  playerBalance?: string | null;
}

/**
 *
 * TODO: Provided jackpot should be of Money type.
 */
/**
 *
 * @param props
 */
const GameTopBar: React.FC<GameTopBarProps> = (props: GameTopBarProps) => {
  const {
    nearestDraw,
    getNearestDraw,
    onClose,
    name,
    image,
    fastPicker,
    turboPicker,
    lotteryStructureName,
    timesStake,
    countdown,
    areInstantBets,
    playerBalance,
  } = props;
  const [betStatus, setBetStatus] = useState<string>('open');
  const appConfiguration = useContext(ConfigContext);
  const isMobile = useMobileWidth();

  const chancePicker = constans.fiveNinety === lotteryStructureName;
  const isWinUp = constans.lotteryNamesDrawSelection.includes(lotteryStructureName);

  const onDrawStatusChange = useCallback((status: string): void => setBetStatus(status), []);

  const getWinUpToFormattedValue = (value: number): string => {
    return value.toLocaleString(appConfiguration.lang ? appConfiguration.lang : 'EN');
  };

  const renderLotteryName = (): JSX.Element => {
    return <p className={`${styles.sectionText} ${styles.gameName}`}>{name}</p>;
  };

  const renderSecondaryBar = (): JSX.Element => {
    if (areInstantBets) {
      return <InstantTicketsBar />;
    }

    return (
      <div
        className={classNames(`${styles.section} ${styles.sectionRight}`, {
          [styles.sectionRightFast]: fastPicker,
          [styles.sectionRightBetClosed]: betStatus === 'closed' && fastPicker,
          [styles.sectionRightBetLast]: betStatus === 'last' && fastPicker,
          [styles.sectionRightBetOpen]: betStatus === 'open' && fastPicker,
        })}
      >
        <div
          className={classNames(`${styles.countdownSection}`, {
            [styles.countdownSectionFast]: fastPicker,
          })}
        >
          <p className={`${styles.sectionText} ${styles.drawInfo}`}>
            {fastPicker ? (
              <FormattedMessage id={'fast.play.countdown'} values={{ betStatus }} />
            ) : (
              <FormattedMessage id={'play.countdown1'} />
            )}
          </p>
          <div>
            <Countdown
              drawDate={nearestDraw.closeDatetime}
              {...{ getNearestDraw, onDrawStatusChange, betStatus, countdown }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderJackpot = (): JSX.Element => {
    if (isWinUp) {
      return (
        timesStake && (
          <p className={`${styles.sectionText} ${styles.jackpot} ${styles.jackpotPadding}`}>
            <span style={{ fontSize: '75%' }}>
              <FormattedMessage
                id={'game.top.bar.win.up'}
                values={{ value: getWinUpToFormattedValue(getHumanAmount(Number(timesStake))) }}
              />
            </span>
          </p>
        )
      );
    }

    const jackpotMoney = new Money(nearestDraw.jackpot);
    const isMillions = jackpotMoney.getHumanAmount() / 1000000 > 1;
    return (
      <p className={`${styles.sectionText} ${styles.jackpot}`}>
        <FormattedMessage
          id={`game.top.bar${isMillions ? '.million' : ''}.jackpot`}
          values={{
            value: numberFormatter(jackpotMoney.getHumanAmount()),
            currency: frankCurrencyFormatter(jackpotMoney.currency),
          }}
        />
      </p>
    );
  };

  return (
    <div
      className={classNames(styles.gameTopBarContainer, {
        [styles.gameTopBarContainerFast]: fastPicker,
        [styles.gameTopBarContainerTurbo]: turboPicker,
        [styles.gameTopBarContainerChance]: chancePicker,
      })}
    >
      <div
        className={classNames(`${styles.countdownWrapper} ${styles.section} ${styles.sectionLeft}`, {
          [styles.sectionLeftFast]: fastPicker,
        })}
      >
        {areInstantBets && playerBalance && !isMobile && <div className={styles.playerBalance}></div>}
        <div className={styles.gameLogoWrapper}>
          {image && image.url ? (
            <img className={styles.gameLogo} src={props.image ? props.image.url : ''} alt="logo" />
          ) : (
            ''
          )}
        </div>
        <div onClick={(): void => onClose()} className={`${mainStyles.closeIconWrapper} ${styles.closeIconWrapper}`}>
          <span className={`${mainStyles.closeIcon} ${styles.closeIcon}`}></span>
        </div>
        <div className={styles.gameInfoWrapper}>
          <div className={styles.sectionTextWrapper}>
            {renderLotteryName()}
            {!isWinUp && fastPicker && (
              <p className={`${styles.sectionText} ${styles.sectionTextCommon} ${styles.sectionTextJackpot}`}>
                <FormattedMessage id={'fast.topbar.subtitle'} />
              </p>
            )}
          </div>
          {!fastPicker && <div className={styles.sectionTextWrapper}>{nearestDraw.jackpot && renderJackpot()}</div>}
        </div>
      </div>
      {renderSecondaryBar()}
    </div>
  );
};

export default GameTopBar;
