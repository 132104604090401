const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const decapitalize = (str: string): string => {
  return str.charAt(0).toLowerCase() + str.slice(1);
};

const camelCaseChanger = (key: string): string => {
  const toCamelCase = key
    .split('-')
    .map((elem: string) => capitalize(elem))
    .join('');

  return decapitalize(toCamelCase);
};

export default camelCaseChanger;
