import * as React from 'react';

import styles from './Spinner.scss';

interface SpinnerInterface {
  size?: number;
  align?: string;
  class?: string;
}

const Spinner: React.FC<SpinnerInterface> = (props): JSX.Element => (
  <div className={`${styles.spinnerContainer} ${styles.spinnerTextCenter} ${props.class}`}>
    <svg
      className={styles.spinner}
      width={`${props.size}px`}
      height={`${props.size}px`}
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className={styles.innerCircle} fill="none" strokeWidth="5" strokeLinecap="round" cx="33" cy="33" r="30" />
    </svg>
  </div>
);

Spinner.defaultProps = {
  align: 'center',
  size: 50,
  class: null,
};

export default Spinner;
