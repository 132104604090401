import { WinningSelectionInterface } from '../../shared/interfaces/GamesPicker';

export default class WonSelection {
  public setWinningSelection(selection: string[], isPerm: boolean): WinningSelectionInterface {
    if (!selection || selection.length === 0) {
      return {};
    }

    if (isPerm) {
      return this.setDesiredPermutationWinningSelection(selection);
    }

    return this.setDesiredWinningSelections(selection);
  }

  private setDesiredPermutationWinningSelection = (data: string[]): WinningSelectionInterface => {
    const winnings: WinningSelectionInterface = {};

    winnings['main'] = data.map((elem: string) => parseInt(elem));

    return winnings;
  };

  private setDesiredWinningSelections = (data: string[]): WinningSelectionInterface => {
    const desiredWinningSelection: WinningSelectionInterface = {};
    const winningSelection = data.map((elem: string) => {
      const index = elem.match(/\d+/)[0];
      const name = elem.replace(/[0-9]/g, '');

      return {
        name,
        index,
      };
    });

    winningSelection.forEach((elem) => {
      const { name, index } = elem;
      const numberIndex = parseInt(index);

      if (!desiredWinningSelection[name]) {
        desiredWinningSelection[name] = [numberIndex];
        return;
      }

      desiredWinningSelection[name].push(numberIndex);
      return;
    });

    return desiredWinningSelection;
  };
}
