import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import TopBar from '../GamesPicker/TopBar';
import RetailUserBets from '../RetailUserBets/RetailUserBets';
import ScanBarrcode from '../GamesPicker/ScanBarrcode/ScanBarrcode';
import RetailDrawResults from '../GamesPicker/RetailDrawResults/RetailDrawResults';

import LobbyContainer from '../../containers/LobbyContainer';
import GamesPickerContainer from '../../containers/GamesPickerContainer';

import { RETAIL_TOP_BAR } from '../../../shared/utils/constans/TopBarConst';
import { pageNames } from '../../../shared/utils';

import { RootStoreContext } from '../../../lobby-engine/stores/RootStore';

import styles from '../../assets/styles/index.scss';
import ownStyles from './Retail.scss';
import Spinner from '../../../shared/components/Spinner';
import InlineError from '../../../shared/components/Error/InlineError';
import { FormattedMessage } from 'react-intl';

const Retail: React.FC<{}> = observer(() => {
  const { navigation, retailCashierStore } = useContext(RootStoreContext);

  const onPickerClose = (): void => {
    navigation.changePage(pageNames.LOBBY);
  };

  const renderContent = (): JSX.Element => {
    switch (navigation.activePage) {
      case pageNames.LOBBY:
        return <LobbyContainer />;
      case pageNames.USER_BETS:
      case pageNames.USER_BETS_SINGLE:
      case pageNames.USER_BETS_ACTIVE:
      case pageNames.USER_BETS_HISTORY:
        return <RetailUserBets />;
      case pageNames.SCAN_SLIP:
        return <ScanBarrcode />;
      case pageNames.RETAIL_DRAW_RESULTS:
        return <RetailDrawResults />;
      default:
        return <GamesPickerContainer onClose={onPickerClose} />;
    }
  };

  const renderCashierInfo = (): JSX.Element => {
    if (retailCashierStore.cashierData) {
      return (
        <div className={ownStyles.cashierInfoContainer}>
          <div className={ownStyles.cashierName}>
            <FormattedMessage id={'retail.cashierid'} />: <span>{retailCashierStore.cashierData.userName}</span>
          </div>
          <div className={ownStyles.shopName}>
            <FormattedMessage id={'retail.shopid'} />: <span>{retailCashierStore.cashierData.shopId}</span>
          </div>
        </div>
      );
    }
    return (
      <div className={ownStyles.cashierInfoContainer}>
        <div className={ownStyles.cashierName}>
          <FormattedMessage id={'retail.cashierid'} />: <span>...</span>
        </div>
        <div className={ownStyles.shopName}>
          <FormattedMessage id={'retail.shopid'} />: <span>...</span>
        </div>
      </div>
    );
  };
  const renderInnerPage = (): JSX.Element => {
    if (retailCashierStore.isLoading) {
      return (
        <div className={ownStyles.spinnerContainer}>
          <Spinner />
        </div>
      );
    }
    if (retailCashierStore.error) {
      return <InlineError error={retailCashierStore.error} />;
    }
    return renderContent();
  };

  return (
    <div className={`${ownStyles.container}`}>
      <TopBar topBarItems={RETAIL_TOP_BAR} showFullscreenToggleButton={true} />
      <div className={`${styles.container} ${styles['pb-5']}`}>
        <div className={`${styles.row} ${ownStyles.cashierInfo}`}>{renderCashierInfo()}</div>
        <div className={`${styles.row} ${styles.justifyCenter}`}>
          <div className={`${styles['col-md-10']} ${styles['col-xs-12']} ${styles['col-12']}`}>{renderInnerPage()}</div>
        </div>
      </div>
    </div>
  );
});

export default Retail;
