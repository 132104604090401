import {
  ticketSelectionValue,
  PlainTicketSelection,
  TicketSelectionInterface,
  SelectionPartInterface,
} from '../../../shared/interfaces/Entities/TicketSelections';

export default class TicketSelection implements TicketSelectionInterface {
  value: ticketSelectionValue;
  name: string;
  type: string;

  constructor(selectionPart: SelectionPartInterface) {
    this.name = selectionPart.name;
    this.type = selectionPart.type;

    if (this.type === 'boolean') {
      this.value = false;
    }
  }

  changeValue(value: ticketSelectionValue): void {
    this.value = value;
  }

  toPlain(): PlainTicketSelection {
    return { name: this.name, value: this.value };
  }
}
