import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import LineEntity from '../../../../../lobby-engine/entities/LineEntity';

import { selectionType } from '../../../../../shared/interfaces/Entities/SelectionParams';

import { classNames } from '../../../../../shared/utils';

import styles from './FastPickerLine.scss';
import { FastPickerLineButton } from './FastPickerLineButton';

interface FastPickerLineProps {
  line: LineEntity;
  onLineClick(id: string): void;
  onRefreshBetClick(id: string): void;
  onRemoveLine(id: string): void;
  type?: string;
  isInstant?: boolean;
}

const FastPickerLine: React.FC<FastPickerLineProps> = observer((props: FastPickerLineProps) => {
  const { onLineClick, onRefreshBetClick, onRemoveLine, line, type = 'default', isInstant } = props;
  const selections = useMemo(() => line.selectionsArray, [line]);

  const renderNumbers = (selectionName: string, selection: number[]): JSX.Element[] => {
    return line.main(selectionName).map((elem: number, index: number) => {
      const isWon = selection.includes(index + 1);

      return (
        <FastPickerLineButton
          key={index}
          number={elem}
          selectionName={selectionName}
          isNumberWon={isWon}
          isInstant={isInstant}
          delayedTimeForWonAmination={6000}
        />
      );
      // return (
      //   <div key={index} className={classNames(styles.fastLineNumber, {
      //     [styles.fastLineBonusNumber]: selectionName !== 'main',
      //     [styles.fastLineNumberWon]: isWon,
      //   })}>
      //     {elem}
      //   </div>
      // );
    });
  };

  const renderSelections = (): JSX.Element[][] => {
    return selections.map((elem: selectionType) => {
      if (elem.isSubType) {
        return null;
      }

      return renderNumbers(elem.name, elem.wonSelection);
    });
  };

  return (
    <div
      className={classNames(styles.fastLineWrapper, {
        [styles.fastLineWrapperCart]: type === 'cart',
      })}
    >
      <div data-testid="numbers" className={styles.fastLineNumbersWrapper} onClick={(): void => onLineClick(line.id)}>
        {renderSelections()}
      </div>
      <div className={styles.fastLineIconWrapper}>
        <div
          onClick={(): void => onRefreshBetClick(line.id)}
          className={`${styles.fastLineIcon} ${styles.fastLineIconRefresh}`}
        >
          <span></span>
        </div>
        <div
          onClick={(): void => onRemoveLine(line.id)}
          className={`${styles.fastLineIcon} ${styles.fastLineIconClose}`}
        >
          <span></span>
        </div>
        <div className={`${styles.fastLineIcon} ${styles.fastLineIconTiles}`}>
          <span></span>
        </div>
      </div>
    </div>
  );
});

export default FastPickerLine;
