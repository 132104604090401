/**
 * This store is dedidcated for handlign of pre-purchases, purchase and post-purchase flow
 * for mutiple tickets/draws.
 *
 * It's a child of BetDetails store that was used both for handling logic of
 * * post-purchase bet display for receipt and success screens
 * * bet details logic for single bet detail screen and scanning
 *
 * Due to limitations of BetDetails store RetailStore was able to print only single bet during purchase
 *
 * This store replaces BetDetailsStore and separates logic of purchase from logic of single bet display.
 * Focusing on pre purchase, purchase, and post purchase states of aplication.
 *
 * With small changes this can be used to handle cart from different games.
 *
 */

import { observable } from 'mobx';
import { ErrorMessage } from '../../shared/interfaces';
import { BetDetailsInterface } from '../../shared/interfaces/GamesPicker';
import { ILSError } from '../../shared/objects';
import ApiLines from '../services/ApiLines';
import ApiLinesConnector from '../services/ApiLinesConnector';
import ApiUserBets from '../services/ApiUserBets';
import ApiUserBetsConnector from '../services/ApiUserBetsConnector';
import AppConfigStore from './AppConfigStore';

export default class PurchaseStore {
  api = new ApiUserBets();
  apiConnector = new ApiUserBetsConnector(this.api);
  apiLines = new ApiLines();
  apiLinesConnector = new ApiLinesConnector(this.apiLines);

  @observable isLoading = false;
  // Marks if at least one of ticket could not be fetched
  @observable isPartialError = false;
  // Counts total number of handled purchases
  @observable purchasedBetsCounter: number | undefined = undefined;
  // Counts total number of bets to be puchased/fetched after purchase
  @observable totalBetsToPurchase: number | undefined = undefined;

  //Array of id's of purchased bets in last call to fetch
  @observable lastPurchasedBets: string[] = [];
  //Array of bet objects fetched from API
  @observable bets: Array<BetDetailsInterface | ILSError> = [];

  appConfig: AppConfigStore;
  @observable public error: ErrorMessage | null = null;

  constructor(appConfig: AppConfigStore) {
    this.appConfig = appConfig;
  }

  reset(): void {
    this.bets = [];
    this.error = null;
    this.isPartialError = false;
    this.isLoading = true;
  }
  /**
   * Fetches all bets for printout purposes
   * @param purchasedBets - string[] of bet's to fetch
   */
  async fetchBets(purchasedBets: string[]): Promise<void> {
    this.totalBetsToPurchase = purchasedBets.length;
    this.purchasedBetsCounter = 0;
    this.lastPurchasedBets = purchasedBets;
    this.isLoading = true;
    this.error = null;
    this.isPartialError = false;
    this.bets = [];

    await Promise.all(
      purchasedBets.map(async (betId: string) => {
        try {
          const bet = await this.fetchBet(betId);
          this.bets.push(bet);
          this.purchasedBetsCounter += 1;
        } catch (error) {
          const ilsError = new ILSError(error);
          this.bets.push(ilsError);
          this.purchasedBetsCounter += 1;
          this.isPartialError = true;
        }
      })
    );
    this.isLoading = false;
  }

  async refetchBets(): Promise<void> {
    this.reset();
    return this.fetchBets(this.lastPurchasedBets);
  }

  /**
   * Fetches bet by ID with all required deps
   * @param betId - bet id
   * @returns
   */
  async fetchBet(betId: string): Promise<BetDetailsInterface> {
    const bet = await this.apiConnector.getSingleBet(betId, this.appConfig.userToken, this.appConfig.gameId);
    bet.lines = await this.apiLinesConnector.getBetLines(this.appConfig.userToken, betId, this.appConfig.gameId);
    bet.permutations = await this.apiLinesConnector.getBetPermutations(
      this.appConfig.userToken,
      betId,
      this.appConfig.gameId
    );

    return bet;
  }
}
