import React, { useContext, useEffect } from 'react';
//import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

//import Input from '../../../shared/components/Input/Input';

import GamesPickerContainer from '../../containers/GamesPickerContainer';

import { RootStoreContext } from '../../../lobby-engine/stores/RootStore';

//import { pageNames } from '../../../shared/utils';

import ownStyles from './RetailUserBets.scss';
//import styles from '../../assets/styles/index.scss';
import Spinner from '../../../shared/components/Spinner';
import { ErrorPage } from '../../pages';

const RetailUserBets: React.FC<{}> = observer(() => {
  const { game } = useContext(RootStoreContext);

  useEffect(() => {
    if (!game.gameStructure.data) {
      game.fetchGameStructure();
    }
  }, [game]);

  // const onTicketIdChange = (value: string): void => {
  //   if (value.length === 36) {
  //     betDetails.fetchBetBySearchId(value);
  //     navigation.changePage(pageNames.USER_BETS_SINGLE);
  //   }
  // };

  const renderContent = (): JSX.Element => {
    if (game.gameStructure.isLoading) {
      return (
        <div className={ownStyles.container}>
          <Spinner />
        </div>
      );
    }

    if (game.gameStructure.error) {
      return ErrorPage(game.gameStructure.error);
    }

    if (!game.gameStructure.data) {
      return null;
    }
    return (
      <React.Fragment>
        {/* <div className={`${ownStyles.inputContainer} ${styles['mb-3']}`}>
          <Input
            inputClassName={ownStyles.input}
            labelClassName={ownStyles.label}
            containerClassName={ownStyles.formGroup}
            onValueChange={(value): void => onTicketIdChange(value)}
            name="ticketId"
            type="text"
            label={<FormattedMessage id="retail.ticket.input.label" />}
          />
          <div className={`${ownStyles.icon}`}>
            <span className={`${styles.icon} ${styles.search}`} />
          </div>
        </div> */}

        <GamesPickerContainer
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClose={(): void => {}}
        />
      </React.Fragment>
    );
  };

  return <div>{renderContent()}</div>;
});

export default RetailUserBets;
