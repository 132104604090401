exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._9sRXa1bSsvHbFcBHu9jaz{display:flex;flex-wrap:wrap;align-items:flex-start;width:100%}@media(min-width: 769px){._9sRXa1bSsvHbFcBHu9jaz{width:80%}}._9sRXa1bSsvHbFcBHu9jaz ._3DQQTA7lLzx48TluYPM8B0{display:flex;flex-direction:column;width:100%}@media(min-width: 769px){._9sRXa1bSsvHbFcBHu9jaz ._3DQQTA7lLzx48TluYPM8B0{width:70%;padding:0 .5em}}._9sRXa1bSsvHbFcBHu9jaz ._1V1hW1i-7A8zse64NWuCos{display:flex;width:100%;flex-direction:column;align-items:center}@media(min-width: 769px){._9sRXa1bSsvHbFcBHu9jaz ._1V1hW1i-7A8zse64NWuCos{width:30%;padding:0 .5em}}._9sRXa1bSsvHbFcBHu9jaz ._1V1hW1i-7A8zse64NWuCos ._37hkthzx26R_kJnR4diwMl{border-bottom:1px solid #d1d1d1;display:flex;width:100%;justify-content:space-between;padding:1em 0}._9sRXa1bSsvHbFcBHu9jaz ._1V1hW1i-7A8zse64NWuCos .MVNPApXIiXQUqQfIGiVfo{justify-content:space-around;border:none}._9sRXa1bSsvHbFcBHu9jaz ._1V1hW1i-7A8zse64NWuCos ._2kwBrg-7RFfbjC8skfbmLY{font-size:1.125em;color:#000}._9sRXa1bSsvHbFcBHu9jaz ._1V1hW1i-7A8zse64NWuCos ._1mP8JVk4ZnoQULVucFe3iG{color:#717171}._9sRXa1bSsvHbFcBHu9jaz ._1V1hW1i-7A8zse64NWuCos .y1Z4-ps_0tZpBOThiZhuf{font-size:1.3125em;font-weight:bold}", ""]);

// Exports
exports.locals = {
	"container": "_9sRXa1bSsvHbFcBHu9jaz",
	"lotteriesContainer": "_3DQQTA7lLzx48TluYPM8B0",
	"summaryContainer": "_1V1hW1i-7A8zse64NWuCos",
	"balanceWrapper": "_37hkthzx26R_kJnR4diwMl",
	"totalWrapper": "MVNPApXIiXQUqQfIGiVfo",
	"priceTxt": "_2kwBrg-7RFfbjC8skfbmLY",
	"priceTxtLighter": "_1mP8JVk4ZnoQULVucFe3iG",
	"priceTxtBigger": "y1Z4-ps_0tZpBOThiZhuf"
};