export const EDITEC_THEME = 'editec';
export const LTECH_FAST_THEME = 'ltechFast';
export const LTECH_THEME = 'ltech';
export const IVORY_COAST = 'ivoryCoast';
export const INTERNATIONAL = 'international';
export const PREMIERBET_LOTTO = 'premierbetLotto';
export const LUCKY_BALLS_CART = 'luckyballsCart';
export const LUCKY_BALLS_DARK = 'luckyBallsDarkTheme';
export const STYLE_V2 = 'styleV2';
export const INSTANT_TICKETS = 'instantTickets';
export const RETAIL = 'retail';
export const RETAIL_LIGHT = 'retailLight';
export const JACKPOT_ARUBA = 'jackpotAruba';
export const LUCKYBALLS_V2 = 'luckyballsV2';
export const LUCKYJACK24 = 'luckyjack24';
export const PANDAJACK24 = 'pandajack24';
const envTheme = process.env.THEME;

// All this themes are based on Ltech one.
export const IS_EDITEC_THEME = envTheme === EDITEC_THEME || envTheme === IVORY_COAST || envTheme === PREMIERBET_LOTTO;
export const IS_LTECH_THEME = envTheme === LTECH_THEME;
export const IS_LUCKYBALLS_CART = envTheme === LUCKY_BALLS_CART;
export const IS_INSTANT_THEME = envTheme === INSTANT_TICKETS;
export const IS_LTECH_FAST =
  envTheme === LTECH_FAST_THEME ||
  envTheme === INTERNATIONAL ||
  envTheme === LUCKY_BALLS_DARK ||
  envTheme === STYLE_V2 ||
  envTheme === JACKPOT_ARUBA ||
  envTheme === LUCKYBALLS_V2 ||
  envTheme === LUCKYJACK24 ||
  IS_INSTANT_THEME;
export const IS_RETAIL = envTheme === RETAIL || envTheme === RETAIL_LIGHT;
