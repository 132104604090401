import React from 'react';
import QRCode from 'react-qr-code';

import { classNames } from '../../../../shared/utils';

import ownStyles from './Barcode.scss';

interface BarcodeProps {
  value: string;
  format?: string;
  displayValue?: boolean;
  displayPassedValue?: boolean;
  barcodeClassName?: string;
  wrapperClassName?: string;
  valueClassName?: string;
  lineColor?: string;
  backgroundColor?: string;
}

const Barcode: React.FC<BarcodeProps> = ({
  value,
  displayPassedValue = false,
  wrapperClassName,
  valueClassName,
}: BarcodeProps) => {
  return (
    <div
      className={classNames(ownStyles.container, {
        [wrapperClassName]: typeof wrapperClassName === 'string',
      })}
    >
      <QRCode value={value} size={128} />
      {displayPassedValue && value && (
        <div
          className={classNames(ownStyles.value, {
            [valueClassName]: typeof valueClassName === 'string',
          })}
        >
          {value}
        </div>
      )}
    </div>
  );
};

export default Barcode;
