import React from 'react';
import { FormattedMessage } from 'react-intl';
import { classNames } from '../../utils';

import ownStyles from './Text.scss';

interface TextProps extends React.HTMLAttributes<HTMLParagraphElement> {
  align?: 'left' | 'center' | 'right';
  type?: 'default' | 'primary' | 'danger' | 'warning' | 'success' | 'light';
  size?: 'small' | 'medium' | 'large';
  fontWeight?: 'medium' | 'bold' | 'thin';
  textId?: string;
  formattedMsgValues?: { [key: string]: number | string };
  className?: string;
}

const Text: React.FC<TextProps> = ({
  align,
  type,
  size,
  children,
  textId,
  fontWeight,
  className,
  formattedMsgValues,
  ...rest
}: TextProps) => {
  function renderIntl(): JSX.Element | null {
    if (!textId) {
      return null;
    }

    return <FormattedMessage id={textId} values={formattedMsgValues || null} />;
  }

  return (
    <p
      className={classNames(`${ownStyles.text}`, {
        [ownStyles[type]]: typeof type === 'string',
        [ownStyles[`${align}Align`]]: typeof align === 'string',
        [ownStyles[`${size}Size`]]: typeof size === 'string',
        [ownStyles[fontWeight]]: typeof fontWeight === 'string',
        [className]: typeof className === 'string',
      })}
      {...rest}
    >
      {children}
      {renderIntl()}
    </p>
  );
};

export default Text;
