import { observer } from 'mobx-react';
import React, { useContext, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useReactToPrint } from 'react-to-print';

import { RootStoreContext } from '../../lobby-engine/stores/RootStore';

import Receipt from '../components/GamesPicker/Receipt/Receipt';
import { ErrorPage } from '../pages';
import Spinner from '../../shared/components/Spinner';

import { getErrorMessage } from '../../shared/utils';

import ownStyles from './styles.scss';
import styles from '../assets/styles/index.scss';
import Button from '../components/generic/Button/Button';
import Money from '../../lobby-engine/entities/BetCostEntity';
import { ERROR_MODAL } from '../../shared/utils/modalNames';

interface ReceiptContainerProps {
  isPayout: boolean;
  isCopy: boolean;
  canceled?: boolean;
}

const ReceiptContainer: React.FC<ReceiptContainerProps> = observer(
  ({ isPayout, canceled, isCopy }: ReceiptContainerProps) => {
    const { betDetails, modal, retailCashierStore } = useContext(RootStoreContext);
    //  const { payoutError, setPayoutErrro } = useState(false);
    const {
      modal: { showModal },
    } = useContext(RootStoreContext);
    // const lottery = game.currentLottery;
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    const handleSideBtnClick = async (): Promise<void> => {
      if (isPayout) {
        try {
          await betDetails.payBet();
        } catch (error) {
          const errorData = getErrorMessage(error.statusCode, error.slugCode);
          showModal(ERROR_MODAL, { errorData, code: error.statusCode });
          return;
        }
      }
      return handlePrint();
    };

    const isLoading = (): boolean => {
      return (
        betDetails.error == null &&
        (!betDetails.betDetails ||
          betDetails.isLoading ||
          betDetails.lines.isLoading ||
          betDetails.permutations.isLoading)
      );
    };
    const isPaid = (): boolean => {
      return betDetails.betDetails.paidOutAt != null;
    };

    const renderContent = (): JSX.Element => {
      if (isLoading()) {
        return <Spinner />;
      }

      if (betDetails.error) {
        return ErrorPage(betDetails.error.message);
      }

      return (
        <div className={ownStyles.printPreview}>
          <Receipt
            bet={betDetails.betDetails}
            {...{ isPayout, canceled, isCopy, cashierUser: retailCashierStore.cashierData }}
          />
        </div>
      );
    };

    const renderCloseButton = (): JSX.Element => {
      return (
        <Button
          btnClassName={`${ownStyles.retailBtn} ${styles.print} ${ownStyles.print}`}
          onButtonClick={modal.hideModal}
        >
          <span className={`${styles.icon} ${styles.close}`} />
        </Button>
      );
    };

    const renderActionButton = (): JSX.Element => {
      const action = isPayout ? 'pay' : 'print';
      const disabled = isPayout && isPaid();
      return (
        <Button
          btnClassName={`
        ${ownStyles.retailBtn}
        ${styles[action]}
        ${ownStyles[action]}
`}
          onButtonClick={handleSideBtnClick}
          disable={isLoading() || disabled}
        >
          <>
            <span className={`${styles.icon} ${styles[action]} `} />
            <FormattedMessage id={`retail.ticket.modal.${action}`} />
          </>
        </Button>
      );
    };

    const renderInfoBox = (): JSX.Element => {
      if (isPayout) {
        if (!isLoading()) {
          const totalWinnings = new Money(betDetails.betDetails.cachedStats.totalRealWinnings);
          if (isPaid()) {
            return (
              <div className={`${ownStyles.infoContainer} `}>
                <div className={`${ownStyles.retailBtn} ${ownStyles.danger}`}>
                  <FormattedMessage id={`retail.ticket.modal.paid`} />
                </div>
              </div>
            );
          } else {
            return (
              <div className={`${ownStyles.infoContainer}`}>
                <div className={`${ownStyles.retailBtn}`}>
                  <FormattedMessage id={`retail.ticket.modal.should_pay`} />
                  <br />
                  <strong>{totalWinnings.toString()}</strong>
                </div>
              </div>
            );
          }
        }
      }
    };

    const renderButtons = (): JSX.Element => {
      return (
        <div className={ownStyles.retailBtnContainer}>
          {renderActionButton()}

          {renderCloseButton()}
        </div>
      );
    };

    return (
      <div className={`${ownStyles.receipt} ${styles['py-2']} `} ref={componentRef}>
        <div className={ownStyles.receiptContainer}>{renderContent()}</div>
        {renderInfoBox()}
        {renderButtons()}
      </div>
    );
  }
);

export default ReceiptContainer;
