import Api from './Api';

import { ApiLobbyInterface } from '../../shared/interfaces/Api';

import config from '../../../../app.config.js';

class ApiLobby extends Api implements ApiLobbyInterface {
  apiUrl = config.APP_URL;

  getLobbies(): Promise<Response> {
    return this.get(`${this.apiUrl}/api/public/widget/v1/lobbies`);
  }

  getLobby(lobbyId: string): Promise<Response> {
    return this.get(`${this.apiUrl}/api/public/widget/v1/lobbies/${lobbyId}`);
  }
}

export default ApiLobby;
