import * as React from 'react';

import { classNames, constans } from '../../../../shared/utils';

import styles from './Pagination.scss';

interface PaginationProps {
  previousLabel: string;
  nextLabel: string;
  onPageChange(direction: number): void;
  pageCount: number;
  paginationPage: number;
  theme?: string;
}

const Pagination: React.FC<PaginationProps> = (props: PaginationProps) => {
  const { previousLabel, nextLabel, pageCount, paginationPage, onPageChange, theme } = props;

  const onLabelClick = (direction: number): void => {
    onPageChange(paginationPage + direction);
  };

  return (
    <div
      className={classNames(`${styles.pagination}`, {
        [styles.paginationLight]: theme === 'light',
      })}
    >
      <div
        className={classNames(`${styles.label} ${styles.labelPrev}`, {
          [styles.labelDisable]: paginationPage === constans.defaultPaginationPage,
        })}
        onClick={paginationPage > constans.defaultPaginationPage ? (): void => onLabelClick(-1) : undefined}
      >
        {previousLabel}
      </div>
      <div
        className={classNames(styles.pageItem, {
          [styles.pageItemLight]: theme === 'light',
        })}
      >
        {paginationPage} of {pageCount}
      </div>
      <div
        className={classNames(`${styles.label} ${styles.labelNext}`, {
          [styles.labelDisable]: paginationPage === pageCount,
        })}
        onClick={paginationPage != pageCount ? (): void => onLabelClick(1) : undefined}
      >
        {nextLabel}
      </div>
    </div>
  );
};

export default Pagination;
