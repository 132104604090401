import React, { useState, useEffect, useContext, useCallback } from 'react';

import { observer } from 'mobx-react';

import Lobby from '../components/Lobby';
import Spinner from '../../shared/components/Spinner';
import { ErrorPage } from '../pages';
import { Modal } from '../components/generic';
import GamesPickerContainer from './GamesPickerContainer';
import { IS_RETAIL, pageNames } from '../../shared/utils';
import ConfigContext from '../../shared/context/ConfigContext';
import { RootStoreContext } from '../../lobby-engine/stores/RootStore';
import styles from '../components/Lobby.scss';
import { validateTokenAndShowModal } from '../../shared/utils/authorization';

interface Window {
  ILSOpenGame?: Function;
}

const LobbyContainer: React.FC<{}> = observer(() => {
  const [modalEnabled, setModalEnabled] = useState<boolean>(false);
  const config = useContext(ConfigContext);
  const { modal, appConfig, navigation, lobby, game, retailCashierStore, brandStore } = useContext(RootStoreContext);
  const showModal = modal.showModal;
  // const modalEmail = i18n.i18nData ? i18n.i18nData.supportEmail : null;
  const hiddenMode = config.hiddenMode;
  const gameId = config.gameId;

  useEffect(() => {
    lobby.setLanguage(config.lang);
  }, [lobby, config.lang]);

  useEffect(() => {
    if (!lobby.variant && !hiddenMode) {
      lobby.getLobbies(config.lobbyId);
    }
  }, [lobby, config.lobbyId, hiddenMode]);

  useEffect(() => {
    if (!lobby.variant) {
      return;
    }
    const gameId = lobby.variant.lobbySections[0].button.link;
    game.setGameId(gameId);
    if (IS_RETAIL) {
      retailCashierStore.fetchCashierDetails(gameId);
    }
  }, [game, lobby.variant]);

  // causes Infinite loops...
  // useEffect(()=>{ //adding dynamic i18n email to main error modal
  //   i18n.getI18n(game.gameId, config.lang).then(()=>{
  //     defaultErrorLoginData.errorData.email = modalEmail;
  //   });
  // })

  useEffect(() => {
    appConfig.setUserToken(config.userToken);
  }, [appConfig, config.userToken]);

  useEffect(() => {
    appConfig.setLang(config.lang);
  }, [config.lang]);

  useEffect(() => {
    brandStore.setBrandId(config.brandId);
  }, [config.brandId]);

  const closeGameModal = useCallback((): void => {
    const { onCloseRedirectTo } = config;

    setModalEnabled(false);
    modal.hideModal();
    //If redirect param is available
    if (onCloseRedirectTo) {
      window.location.replace(decodeURIComponent(onCloseRedirectTo as string));
    }
  }, [modal]);

  const onLobbyVariantClick = useCallback(
    (gameId: string): void => {
      const isUserLoggedIn = validateTokenAndShowModal(config, modal, true);
      //Break the flow if user is not logged in
      if (!isUserLoggedIn) {
        return;
      }

      if (!IS_RETAIL) {
        setModalEnabled(true);
        // showModal(GAME_MODAL, {
        //   onClose: closeGameModal
        // });
      }

      game.setGameId(gameId);
      navigation.changePage(pageNames.GAME_PLAY);
      setModalEnabled(true);
    },
    [config, showModal, navigation, game]
  );

  useEffect(() => {
    //exposing displayGameModal function to the outside world
    if (hiddenMode && !gameId) {
      (window as unknown as Window & typeof globalThis).ILSOpenGame = (gameId: string): void => {
        game.setGameId(gameId);
        onLobbyVariantClick(gameId);
      };
    }
    if (hiddenMode && gameId) {
      onLobbyVariantClick(gameId);
    }
  }, [onLobbyVariantClick, hiddenMode, game]);

  const renderLobbies = (): JSX.Element => {
    const lobbyData = lobby.data;

    if (!lobbyData.variants) {
      return ErrorPage('NO_PUBLISHED_LOBBY');
    }
    const lobbyLangVariant = lobby.variant;
    const lobbyDesiredVariant = lobbyLangVariant || lobbyData.variants[0];

    if (!lobbyDesiredVariant || lobbyDesiredVariant.lobbySections.length === 0) {
      return ErrorPage('EMPTY_LOBBY');
    }

    return (
      <Lobby
        variant={lobbyDesiredVariant}
        id={lobby.data.id}
        onButtonClick={onLobbyVariantClick}
        isModalEnabled={modalEnabled}
      />
    );
  };

  const renderPage = (): JSX.Element => {
    if (lobby.error) {
      return ErrorPage(lobby.error);
    }

    if (!lobby || !lobby.data) {
      return (
        <div className={styles.spinnerContainer}>
          <Spinner size={75} />
        </div>
      );
    }

    return renderLobbies();
  };

  const renderModal = (): JSX.Element => {
    const onClose = closeGameModal;

    return (
      <Modal size="large" {...{ onClose }}>
        <GamesPickerContainer {...{ onClose }} />
      </Modal>
    );
  };

  return (
    <>
      {!hiddenMode && renderPage()}
      {modalEnabled && renderModal()}
    </>
  );
});

export default LobbyContainer;
