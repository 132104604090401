exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1pYhvKnsFkBbdwdQJxGD9s{position:relative;width:80%;margin:38px auto 20px auto}._1pYhvKnsFkBbdwdQJxGD9s .B1f7XN1-nn9UDKa7SKLLX{width:50px;height:50px;position:absolute;top:-25%;left:50%;transform:translateX(-50%)}@media(min-width: 576px){._1pYhvKnsFkBbdwdQJxGD9s .B1f7XN1-nn9UDKa7SKLLX{top:-32%}}@media(min-width: 769px){._1pYhvKnsFkBbdwdQJxGD9s .B1f7XN1-nn9UDKa7SKLLX{width:75px;height:75px;top:-35%}}._1pYhvKnsFkBbdwdQJxGD9s ._1x62QF2hglmFstRQnyDdke{color:#e61621;background:#f1f1f1;box-shadow:0 0 5px #cfcfcf;border:1px solid #ccc;border-radius:10px !important;padding:25px 30px;margin-top:1em}@media(min-width: 769px){._1pYhvKnsFkBbdwdQJxGD9s ._1x62QF2hglmFstRQnyDdke{width:auto;padding:35px 50px;margin:0}}._1pYhvKnsFkBbdwdQJxGD9s ._1rDRDIPFZPeL0VUCL6q1m6{font-size:16px;text-align:center}@media(min-width: 769px){._1pYhvKnsFkBbdwdQJxGD9s ._1rDRDIPFZPeL0VUCL6q1m6{font-size:22px}}", ""]);

// Exports
exports.locals = {
	"errorContainer": "_1pYhvKnsFkBbdwdQJxGD9s",
	"errorImg": "B1f7XN1-nn9UDKa7SKLLX",
	"errorMessage": "_1x62QF2hglmFstRQnyDdke",
	"errorMessageTxt": "_1rDRDIPFZPeL0VUCL6q1m6"
};