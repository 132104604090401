import { observable, action, runInAction, computed } from 'mobx';

import ApiGamesConnector from '../services/ApiGamesConnector';
import ApiGames from '../services/ApiGames';

import {
  GameCreatorInterface,
  GameStructureStoreInterface,
  GameStoreBetPostInterface,
  SelectionPartRangeInterface,
} from '../../shared/interfaces/GamesPicker';
import { ImageInterface } from '../../shared/interfaces';
import PostBetResponseInterface from '../../shared/interfaces/GamesPicker/PostBetResponseInterface';
import ConfigStoreInterface from '../../shared/interfaces/Stores/ConfigStoreInterface';
import { GameStoreInterface } from '../../shared/interfaces/Stores';
import { LotteryDataInterface } from '../../shared/interfaces/Stores/LotteriesStoreInterface';

import InstantGameStore from './InstantGameStore';
import AppConfigStore from './AppConfigStore';
import RetailStore from './RetailStore';
import LotteriesStore from './LotteriesStore';
import LotteryEntity from '../entities/selections/LotteryEntity';

import { constans, firstLetterFormatter } from '../../shared/utils';
import PlayerBalanceStore from './PlayerBalanceStore';

const api = new ApiGames();
const apiConnector = new ApiGamesConnector(api);

export default class GameStore implements GameStoreInterface {
  private _gameId: string;
  private _currentLottery: LotteryEntity;
  baseCurrency: string;
  instantGame: InstantGameStore;

  constructor(
    public lotteryConfig: ConfigStoreInterface,
    public lotteries: LotteriesStore,
    public appConfig: AppConfigStore,
    public retail: RetailStore,
    public playerBalance: PlayerBalanceStore
  ) {}

  @observable isInstant = false;

  @observable gameStructure: GameStructureStoreInterface = {
    data: null,
    isLoading: false,
    error: null,
  };

  @observable betPost: GameStoreBetPostInterface = {
    isLoading: false,
    error: null,
    isSuccess: false,
  };

  @computed get gameId(): string {
    return this._gameId;
  }

  set gameId(value: string) {
    this._gameId = value;
  }

  @observable isFastPicker = false;
  @observable logo: ImageInterface = null;
  @observable lotteryStructureName = '';
  @observable lotteryName: string = null;

  @action
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // you can pass callback function to postInstantBet, what should happen after instant bets process ends
  postBet = async (token: string, callback?: () => void): Promise<PostBetResponseInterface> => {
    if (this.gameStructure.data.allowInstantBets) {
      this.instantGame.postInstantBet(this.gameId, token, callback);
      return;
    }

    await this.postNormalBet(token);
  };

  postNormalBet = async (token: string): Promise<void> => {
    this.betPost.isLoading = true;
    this.betPost.isSuccess = false;
    this.betPost.error = null;

    try {
      await Promise.all(
        this.lotteries.lotteriesList.map(async (elem: LotteryEntity) => {
          await apiConnector.postBets(elem.toPlain(), elem.gameId, token);
        })
      );

      this.betPost.isLoading = false;
      this.betPost.isSuccess = true;
    } catch (error) {
      this.betPost.isLoading = false;
      this.betPost.error = error;
    }
  };

  @action
  fetchGameStructure = async (gameId?: string): Promise<void> => {
    this.gameStructure.isLoading = true;
    this.gameStructure.error = null;
    this.lotteryConfig.checkIsMobile();
    try {
      const gameStructure = await apiConnector.getGameStructure(this.gameId || gameId);

      runInAction(() => {
        const permStructure = gameStructure.permutationsStructure
          ? gameStructure.permutationsStructure.selectionParts
          : null;
        const selectionStructure = gameStructure.selectionsStructures.betLine;
        const selectionParts = permStructure ? permStructure : selectionStructure;
        const permutationsEnabled = permStructure && gameStructure.permutationsEnabled;

        this.lotteryStructureName = gameStructure.lottery.structure.name;
        this.lotteryConfig.setLotteryName(this.lotteryStructureName);
        this.gameStructure.data = gameStructure;
        this.baseCurrency = gameStructure.betLineBasePrice.currency;
        this.logo = gameStructure.image;
        this.lotteryName = gameStructure.name.split(' ').join('').replace('/', '');

        const lotteryData: LotteryDataInterface = {
          name: gameStructure.displayedName,
          selectionParts,
          permutationsEnabled,
          lineCountToAdd: this.lotteryConfig.lineCountToAdd,
          maxLinesPerBet: gameStructure.maxLinesPerBet,
          lotteryStructureName: gameStructure.lottery.structure.name,
          id: gameStructure.id,
          image: gameStructure.image,
          stakes: gameStructure.stakes,
          ticketSelection: gameStructure.selectionsStructures.ticket,
          betLineBasePrice: gameStructure.betLineBasePrice,
          betLineAdditionalPrices: gameStructure.betLineAdditionalPrices,
        };

        if (!this.currentLottery) {
          this.lotteries.addLottery(lotteryData);
          this.currentLottery = this.lotteries.getCurrentLottery();
        }

        if (gameStructure.allowInstantBets) {
          this.instantGame = new InstantGameStore(this.playerBalance);
          this.instantGame.lottery = this.lotteries.getCurrentLottery();
        }

        this.isFastPicker = constans.lotteryNamesPickX.indexOf(gameStructure.lottery.structure.name) != -1;

        this.gameStructure.isLoading = false;
        this.isInstant = gameStructure.allowInstantBets;
      });
    } catch (error) {
      runInAction(() => {
        this.gameStructure.error = error.message;
        this.gameStructure.isLoading = false;
      });
    }
  };

  @action
  getGameDataCreator = (): GameCreatorInterface => {
    const {
      maxLinesPerBet,
      selectionsStructures: { betLine },
    } = this.gameStructure.data;

    const lotteryStructureData = betLine.map((elem: SelectionPartRangeInterface) => {
      if (elem.type === 'range') {
        return {
          minNumberCount: elem.minNumberCount,
          maxNumberCount: elem.maxNumberCount,
          range: elem.range,
          maxLinesPerBet,
        };
      }

      if (elem.type === 'integer') {
        return {
          integerRange: elem.range,
          additionalParamName: firstLetterFormatter(elem.name),
        };
      }
    });

    return lotteryStructureData.reduce((a, b) => {
      // if (a === {}) {
      //   return;
      // }

      return { ...a, ...b };
    }, {});
  };

  @action
  saveLottery(): void {
    this.currentLottery.confirmLottery();
  }

  setGameId(gameId: string): void {
    this.gameId = gameId;
    this.appConfig.setGameId(gameId);
  }

  @action
  resetGameData = (): void => {
    // this.gameStructure.data = null;
    this.currentLottery = null;
    this.betPost = {
      isLoading: false,
      isSuccess: false,
      error: null,
    };
    // reset all
  };

  @action
  resetGameStructure = (): void => {
    this.gameStructure.data = null;
  };

  get currentLottery(): LotteryEntity {
    return this._currentLottery;
  }

  set currentLottery(value: LotteryEntity) {
    this._currentLottery = value;
  }

  get displayName(): string {
    return this.gameStructure.data.displayedName;
  }
}
