import React, { useContext } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import ModalConnector from '../../../shared/components/Modals/ModalConnector';
import Modal from '../../../shared/components/Modals/Modal';
import Spinner from '../../../shared/components/Spinner';

import { PENDING_MODAL } from '../../../shared/utils/modalNames';

import { RootStoreContext } from '../../../lobby-engine/stores/RootStore';

import success from '../../assets/images/success.png';
import error from '../../assets/images/error.png';

import config from '../../../../../app.config';

import styles from './Modals.scss';
import ownStyles from './PendingModal.scss';

import { ConfigContext } from '../../../shared/context';
import { pageNames } from '../../../shared/utils';

interface PendingModalProps {
  isOpen: boolean;
  data: {
    isPending: boolean;
    isError: boolean;
  };
  onClose(): void;
}

const PendingModal: React.FC<{}> = observer((): JSX.Element => {
  const configContext = useContext(ConfigContext);
  const { navigation, game, brandStore } = useContext(RootStoreContext);

  const myBetsPage = (): void => {
    navigation.changePage(pageNames.USER_BETS);
  };

  const clearNumbers = (): void => {
    const lines = game.currentLottery.lines.linesList;
    lines.forEach((line) => {
      line.clearSelection();
    });
  };

  /**
   * Decides if current config allows to display a "Deposit" button.
   *
   * @returns
   */
  const shouldDisplayDepositButton = (): boolean => {
    if (game.betPost.error.statusCode == 400 && configContext.iFrameMode && configContext.noMoneyMessage) {
      return true;
    }
    return (
      game.betPost.error.statusCode == 400 && configContext.noMoneyCb && typeof configContext.noMoneyCb === 'function'
    );
  };

  /**
   * If deposit button is enabled, react with corrct callback
   * on it's click
   */
  const onDepositClick = (): void => {
    if (configContext.iFrameMode) {
      window.top.postMessage('noMoneyMsg', '*');
    } else {
      configContext.noMoneyCb();
    }
  };

  function renderPendingStage(): JSX.Element {
    return (
      <>
        <p className={ownStyles.pendingTxt}>
          <FormattedHTMLMessage id="play.modal.pending.p1" />
        </p>
        <Spinner />
      </>
    );
  }

  function renderSuccessStage(onClose: () => void): JSX.Element {
    const brandData = brandStore.data();
    const additionalMessage = brandData.customMessages?.afterBetPurchase || undefined;
    return (
      <>
        <p className={ownStyles.successTxt}>
          <FormattedMessage id="play.modal.success.p1" />
        </p>

        <div>
          <img className={ownStyles.successImg} src={config.CDN_URL + success} alt="success" />
        </div>
        {additionalMessage && <p className={ownStyles.additionalTxt}>{additionalMessage}</p>}
        <hr className={ownStyles.hrWidth} />
        <div className={styles.modalButtons}>
          <p className={`${styles.gameSummaryModalSecondaryText} ${styles.buttonModalAction}`}>
            <a
              className={styles.gameSummaryModalCallToAction}
              onClick={(): void => {
                onClose();
                clearNumbers();
              }}
            >
              <FormattedMessage id={'play.modal.btn'} />
            </a>
          </p>
          <p className={`${styles.gameSummaryModalSecondaryText} ${styles.buttonModalAction}`}>
            <a
              className={styles.gameSummaryModalCallToAction}
              onClick={(): void => {
                myBetsPage();
                onClose();
              }}
            >
              <FormattedMessage id={'bets.modal.btn'} />
            </a>
          </p>
        </div>
      </>
    );
  }

  const renderErrorMessages = (): JSX.Element => {
    const lines = game.currentLottery.lines.validLinesCount;
    switch (game.betPost.error.statusCode) {
      case 400:
        return <FormattedMessage id="play.modal.deposit" />;
      default:
        return <FormattedMessage id="play.modal.error.p3" values={{ linesCount: lines }} />;
    }
  };

  const renderButtons = (onClose: () => void): JSX.Element => {
    if (shouldDisplayDepositButton()) {
      return (
        <a className={styles.gameSummaryModalCallToAction} onClick={(): void => onDepositClick()}>
          <FormattedMessage id={'play.modal.deposit.btn'} />
        </a>
      );
    } else {
      return (
        <a
          className={styles.gameSummaryModalCallToAction}
          onClick={(): void => {
            onDepositClick();
            onClose();
            clearNumbers();
          }}
        >
          <FormattedMessage id={'play.modal.btn'} />
        </a>
      );
    }
  };

  function renderErrorStage(onClose: () => void): JSX.Element {
    return (
      <>
        <p className={ownStyles.successTxt}>{renderErrorMessages()}</p>
        <div>
          <img className={ownStyles.successImg} src={config.CDN_URL + error} alt="error" />
        </div>
        <hr className={ownStyles.hrWidth} />
        <div className={styles.modalButtons}>
          <p className={`${styles.gameSummaryModalSecondaryText} ${styles.buttonModalAction}`}>
            {renderButtons(onClose)}
          </p>
          <p className={`${styles.gameSummaryModalSecondaryText} ${styles.buttonModalAction}`}>
            <a
              className={styles.gameSummaryModalCallToAction}
              onClick={(): void => {
                myBetsPage();
                onClose();
              }}
            >
              <FormattedMessage id={'bets.modal.btn'} />
            </a>
          </p>
        </div>
      </>
    );
  }

  function renderContent(onClose: () => void): JSX.Element {
    if (game.betPost.isLoading) {
      return renderPendingStage();
    }

    if (game.betPost.isSuccess) {
      return renderSuccessStage(onClose);
    }

    if (game.betPost.error) {
      return renderErrorStage(onClose);
    }

    return null;
  }

  return (
    <ModalConnector name={PENDING_MODAL}>
      {({ isOpen, onClose }: PendingModalProps): JSX.Element => {
        return (
          <Modal
            {...{ isOpen }}
            modalClassName={ownStyles.modalContainer}
            contentOutside
            onClose={game.betPost.isLoading ? null : onClose}
          >
            <div className={ownStyles.pendingModalContainer}>
              <div className={styles.buttonCloseModal} onClick={(): void => onClose()}>
                <span className={styles.closeIcon}></span>
              </div>
              {renderContent(onClose)}
            </div>
          </Modal>
        );
      }}
    </ModalConnector>
  );
});

export default PendingModal;
