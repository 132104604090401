import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import styles from './FastPicker.scss';

const FastPickerEditecInfo: React.FC<{}> = () => {
  return (
    <div className={styles.fastPickerInfo}>
      <div className={styles.fastPickerInfoHead}>
        <p className={styles.fastPickerInfoHeadTxt}>
          <FormattedMessage id="fast.editec.picker.info.head" />
        </p>
      </div>
      <div className={`${styles.fastPickerInfoSection} ${styles.fastPickerInfoSectionFlex}`}>
        <p className={`${styles.fastPickerInfoSectionTxt} ${styles.fastPickerInfoSectionTxtMargin}`}>
          <span className={styles.fastPickerInfoSectionNumber}>1. </span>
          <FormattedMessage id={'fast.editec.picker.info.p1'} />
        </p>
        <p className={styles.fastPickerInfoSectionTxtMLeft}>
          <FormattedHTMLMessage id="fast.editec.picker.info.p1a" />
        </p>
        <p className={styles.fastPickerInfoSectionTxtMLeft}>
          <FormattedHTMLMessage id="fast.editec.picker.info.p1b" />
        </p>
      </div>
      <div className={styles.fastPickerInfoSection}>
        <p className={styles.fastPickerInfoSectionTxt}>
          <span className={styles.fastPickerInfoSectionNumber}>2. </span>
          <FormattedMessage id={'fast.editec.picker.info.p2'} />
        </p>
      </div>
      <div className={styles.fastPickerInfoSection}>
        <p className={`${styles.fastPickerInfoSectionTxt} ${styles.fastPickerInfoSectionTxtLine}`}>
          <span className={styles.fastPickerInfoSectionNumber}>3. </span>
          <FormattedMessage id={'fast.editec.picker.info.p3'} />
        </p>
      </div>
      <div className={styles.fastPickerInfoSection}>
        <p className={`${styles.fastPickerInfoSectionTxt} ${styles.fastPickerInfoSectionTxtM}`}>
          <span className={styles.fastPickerInfoSectionNumber}>4. </span>
          <FormattedMessage id={'fast.editec.picker.info.p4'} />
        </p>
      </div>
    </div>
  );
};

export default FastPickerEditecInfo;
