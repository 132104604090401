import { observable } from 'mobx';

import ResultSelection from './selections/ResultSelection';

import { WonBetSelectionInterface, WinningSelectionInterface } from '../../shared/interfaces/GamesPicker';
import { CurrencyInterface } from '../../shared/interfaces';
import WonSelection from './WonSelection';

export default class ResultEntity {
  id: string;
  isPerm: boolean;
  lineWinnings: CurrencyInterface;
  @observable selections: Map<string, ResultSelection> = new Map<string, ResultSelection>();

  constructor(
    selection: WonBetSelectionInterface,
    winningSelections: string[] = [],
    permutation = false,
    lineWinnings?: CurrencyInterface
  ) {
    this.id = selection.id;
    this.isPerm = permutation;
    this.lineWinnings = lineWinnings;

    Object.keys(selection.asArray).forEach((elem: string) => {
      const wonSelection = new WonSelection();
      const properWinningSelections: WinningSelectionInterface = wonSelection.setWinningSelection(
        winningSelections,
        this.isPerm
      );
      const properElemWinningSelection = properWinningSelections[elem] || [];

      this.selections.set(elem, new ResultSelection(elem, selection.asArray[elem], properElemWinningSelection));
    });
  }

  get selectionsArray(): Array<ResultSelection> {
    return Array.from(this.selections.keys())
      .map((elem: string) => this.selections.get(elem))
      .sort((elem: ResultSelection) => {
        if (elem.name === 'main') {
          return -1;
        }
      });
  }
}
