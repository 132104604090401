/** Fetches draw results for retail printouts
 * It's separate from normal last draws results due to separate domain/logic.
 * This one can download results for multiple games at once and fetches all of draws for selected day
 */

import { action, observable } from 'mobx';

import { ImageInterface } from '../../shared/interfaces';
import { DrawInterface } from '../../shared/interfaces/GamesPicker';

import DataFilter from '../entities/DataFilter';

import { DATE } from '../utils/filtersConstans';
import ApiGamesConnector from '../services/ApiGamesConnector';
import ApiGames from '../services/ApiGames';
import ApiInstructions from '../services/ApiInstructions';
import ApiInstructionsConnector from '../services/ApiInstructionsConnector';
import { getTimezone } from '../../shared/utils';

const apiGames = new ApiGames();
const apiGamesConnector = new ApiGamesConnector(apiGames);
const apiInstructions = new ApiInstructions();
const apiInstructionsConnector = new ApiInstructionsConnector(apiInstructions);

export interface GameStructure {
  displayedName: string;
  id: string;
  image: ImageInterface | null;
  draws: DrawInterface[];
}

class RetailDrawResultsStore {
  @observable data: {
    [k: string]: GameStructure;
  } = {};

  @observable gamesMetaData: {
    [k: string]: GameStructure;
  } = {};

  @observable enabledGames: string[];
  @observable drawsLimit: number | null;

  @observable isLoading: boolean;
  @observable isLoadingMeta: boolean;
  @observable error: boolean;
  dataFilters: DataFilter;
  metaDataLoaded: boolean;

  constructor() {
    this.dataFilters = new DataFilter(DATE);
    this.drawsLimit = 1;
    this.enabledGames = [];
    this.error = false;
    this.metaDataLoaded = false;
  }

  getGamesMetaData = async (gameIds: string[]): Promise<void> => {
    //Don't load metadata if it's loaded alrady
    if (this.metaDataLoaded) return;
    this.isLoading = true;
    this.isLoadingMeta = true;
    for (const gameId of gameIds) {
      try {
        const gameInfo = await this.getSingleGameMetaData(gameId);
        this.gamesMetaData[gameId] = gameInfo;
        this.error = false;
      } catch (err) {
        this.error = true;
        this.isLoading = false;
        this.isLoadingMeta = false;
      }
    }
    if (!this.error) this.metaDataLoaded = true;

    this.isLoading = false;
    this.isLoadingMeta = false;
  };

  getSingleGameMetaData = async (gameId: string): Promise<GameStructure> => {
    const gameData = await apiGamesConnector.getGameStructure(gameId);
    const gameDetails: GameStructure = {
      displayedName: gameData.displayedName,
      id: gameData.id,
      image: gameData.image,
      draws: [],
    };
    return gameDetails;
  };

  /**
   * Loads basic draw info needed for printout
   * plus all draws results from specifed period
   * @param gameIds - array with game ids for which to download data
   */
  getDrawsAndResults = async (gameIds: string[], date: string): Promise<void> => {
    const tz = getTimezone(date);
    this.resetDrawsData();

    this.isLoading = true;
    this.error = false;
    for (const gameId of gameIds) {
      try {
        const gameInfo = await this.getSingleGameData(gameId, date, tz);
        if (gameInfo.draws.length > 0) {
          this.data[gameId] = gameInfo;
        }
      } catch (err) {
        this.error = true;
      }
    }
    this.isLoading = false;
  };

  @action
  /**
   * Gets single game/lottery basic details from API
   * @param gameId - id of game
   */
  getSingleGameData = async (gameId: string, date: string, tz: string): Promise<GameStructure> => {
    //Copy already fetched game meta into draws/results data set
    const gameData = this.gamesMetaData[gameId];
    const draws = await this.getSingleGameDraws(gameId, date, tz);
    const gameDetails: GameStructure = {
      displayedName: gameData.displayedName,
      id: gameData.id,
      image: gameData.image,
      draws,
    };
    return gameDetails;
  };

  /**
   * Gets draws for given game.
   * @param gameId - id of game
   * @param getMore
   * @returns
   */
  @action
  getSingleGameDraws = async (gameId: string, date: string, tz: string): Promise<DrawInterface[]> => {
    let draws: DrawInterface[] = [];

    let isMore = true;
    let correctPerPage = 30;
    let correctPage = 1;

    //Try to apply limits in inital call so we don't attack API
    if (this.drawsLimit > 0) {
      correctPerPage = this.drawsLimit;
    }

    while (isMore) {
      const response = await apiInstructionsConnector.getLastDraws(
        gameId,
        date,
        tz,
        correctPage.toString(10),
        correctPerPage.toString(10)
      );

      if (draws !== null) {
        draws.push(...response.data);
      } else {
        draws = response.data;
      }

      //If our perPage is out of API limits, we need to adjust.
      correctPerPage = Number(response.headers.xPerPage);
      correctPage = Number(response.headers.xPage) + 1;

      isMore = response.headers.xLastPage !== response.headers.xPage && response.headers.xLastPage !== '0';
      // Overwrite isMore if we've reached limit
      if (draws.length >= this.drawsLimit) {
        isMore = false;
      }
    }
    return draws;
  };

  @action
  resetDrawsData = (): void => {
    this.dataFilters.reset();
    this.data = {};
    this.isLoading = false;
    this.error = false;
  };
}

export default RetailDrawResultsStore;
