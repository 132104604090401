import Api from './Api';

import { ApiLinesInterface } from '../../shared/interfaces/Api';

import { BETS, LINES, PERMUTATIONS } from '../../shared/utils/constans/endPoints';
import { getProperApiUrl } from '../../shared/utils/apiUtils';

import config from '../../../../app.config.js';

class ApiLines extends Api implements ApiLinesInterface {
  apiUrl = config.APP_URL;

  getBetLines(userToken: string, betId: string, gameId: string): Promise<Response> {
    const properParams = {
      gameId,
      token: userToken,
      'search[permutation][hide-all]': true,
    };
    const endPoint = `${BETS}/${betId}${LINES}`;
    const properApiUrl = getProperApiUrl(endPoint, properParams);

    return this.get(properApiUrl);
  }

  getBetPermutations(userToken: string, betId: string, gameId: string): Promise<Response> {
    const properParams = {
      token: userToken,
      gameId,
    };
    const endPoint = `${BETS}/${betId}${PERMUTATIONS}`;
    const properApiUrl = getProperApiUrl(endPoint, properParams);

    return this.get(properApiUrl);
    /* eslint-enable max-len */
  }
}

export default ApiLines;
