export default class ResultSelection {
  numbers: number[] = [];
  // on normal line we get indexes of won numbers but in permutation we get won numbers
  winnings: number[] = [];
  isSubType = false;
  name: string;

  constructor(name: string, selection: number[] | number, winningSelection: number[]) {
    if (name === 'turbo' || name === 'chance' || name === 'nap' || name === 'xtra') {
      this.isSubType = true;
    }

    this.name = name;
    this.numbers = typeof selection === 'object' ? [...selection] : [selection];
    this.winnings = winningSelection;
  }
}
