const factorial = (n: number): number => {
  let result = 1;

  if (n === 0 || n === 1) {
    return result;
  }

  for (let i = 1; i <= n; i++) {
    result *= i;
  }

  return result;
};

export const calculateCombination = (collectionCount: number, elementsCount: number, repeat: boolean): number => {
  let numerator: number;
  let denominator: number;

  if (repeat) {
    numerator = factorial(collectionCount - elementsCount - 1);
    denominator = factorial(collectionCount) * factorial(elementsCount - 1);

    return numerator / denominator;
  }

  numerator = factorial(collectionCount);
  denominator = factorial(elementsCount) * factorial(collectionCount - elementsCount);

  return numerator / denominator;
};
