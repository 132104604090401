import dayjs from 'dayjs';

interface OptionInterface {
  value: string | number;
  label: string;
}

// YEARS
export const getYearOptions = (currentYear: string): OptionInterface[] => {
  const parsedYear = parseInt(currentYear);
  const yearsArr = [parsedYear - 1, parsedYear, parsedYear + 1];

  return yearsArr.map((elem: number) => {
    return {
      label: elem.toString(),
      value: elem,
    };
  });
};

//MONTHS
export const getMonthOptions = (): OptionInterface[] => {
  return [...Array(12)].map((elem: undefined, index: number) => {
    return {
      label: dayjs().month(index).format('MMM'),
      value: index + 1,
    };
  });
};

//DAYS
export const getDaysOptions = (year: string, month: string): OptionInterface[] => {
  const daysInMonth = dayjs(`${year}-${month}-1`).daysInMonth();

  if (!daysInMonth) {
    return [];
  }

  return [...Array(daysInMonth)].map((elem: undefined, index: number) => {
    return {
      label: `${index + 1}`,
      value: index + 1,
    };
  });
};
