exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".rI66ZG6lc00YMa3IkVx-U{height:100%;display:flex;flex-direction:column;align-items:center;color:#b6c5cf;box-shadow:0 7px 32px #678ca5;background-color:#071a25}.rI66ZG6lc00YMa3IkVx-U ._3mmq2u2CUd3XjHzUGqF0gM{flex:1 1 80%;width:100%;display:flex;justify-content:center;align-items:center;flex-direction:column;gap:1em}.rI66ZG6lc00YMa3IkVx-U ._3mmq2u2CUd3XjHzUGqF0gM ._2UCR1czyYAGPaj63rj4k0o{width:100%}.rI66ZG6lc00YMa3IkVx-U ._3mmq2u2CUd3XjHzUGqF0gM ._2X--IxtPZ09Gj4BuUuW9oz{font-size:1em;margin:0 .5em;text-transform:capitalize}.rI66ZG6lc00YMa3IkVx-U ._3mmq2u2CUd3XjHzUGqF0gM ._1_DoPuUO0a9FlKuzCBW8h4{color:#b6c5cf;font-weight:bold;margin:0 .5em}.rI66ZG6lc00YMa3IkVx-U ._3mmq2u2CUd3XjHzUGqF0gM ._3ma7DFK_5BGIgdq-xH386q{flex:1;max-height:150px}.rI66ZG6lc00YMa3IkVx-U ._3mmq2u2CUd3XjHzUGqF0gM ._3ma7DFK_5BGIgdq-xH386q img{height:auto;width:100%;max-width:150px}.rI66ZG6lc00YMa3IkVx-U ._3Fq-huavSQMjUNIn9BC2m3{flex:0 0 2em;display:flex;justify-content:space-between;margin-top:.375rem;width:100%;text-align:center;color:#071a25}.rI66ZG6lc00YMa3IkVx-U ._3Fq-huavSQMjUNIn9BC2m3 ._3d4ZM9Ruuj_GciQ0JS0goE{flex:1;display:flex;flex-direction:column;align-content:center;justify-content:center;font-size:.875rem;box-shadow:none;width:auto;margin:0 .375rem;text-transform:none;background-color:#c1d030}.rI66ZG6lc00YMa3IkVx-U ._2EUN1HTuiRtLWVLKg0QPZe{display:flex;justify-content:space-between;margin:.5rem 0;width:100%}.rI66ZG6lc00YMa3IkVx-U ._2EUN1HTuiRtLWVLKg0QPZe ._3d4ZM9Ruuj_GciQ0JS0goE{flex:1;display:flex;align-content:center;justify-content:center;font-size:.875rem;box-shadow:none;width:auto;margin:0 .375rem;text-transform:none}@media print{.rI66ZG6lc00YMa3IkVx-U ._2EUN1HTuiRtLWVLKg0QPZe{display:none}}", ""]);

// Exports
exports.locals = {
	"ticket": "rI66ZG6lc00YMa3IkVx-U",
	"ticketDataContainer": "_3mmq2u2CUd3XjHzUGqF0gM",
	"row": "_2UCR1czyYAGPaj63rj4k0o",
	"label": "_2X--IxtPZ09Gj4BuUuW9oz",
	"value": "_1_DoPuUO0a9FlKuzCBW8h4",
	"imgContainer": "_3ma7DFK_5BGIgdq-xH386q",
	"infoContainer": "_3Fq-huavSQMjUNIn9BC2m3",
	"retailBtn": "_3d4ZM9Ruuj_GciQ0JS0goE",
	"retailBtnContainer": "_2EUN1HTuiRtLWVLKg0QPZe"
};