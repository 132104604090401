exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ba3rmb8wv1Wbvmqj4TbxQ{display:flex;flex-direction:column;align-items:center;justify-content:center;width:100%;color:#fff}._1pl3ECUDULW0BBUgYZ7jKi{background-color:#fff;color:#000}._1pl3ECUDULW0BBUgYZ7jKi img{filter:grayscale(100%)}._1ZiHVV-_eiSazGvOP-_LJi{flex:0 0}._3cIgh1UD8tpQprSTwlKf1B{flex:1;display:flex;align-items:center;justify-content:center;flex-direction:column}._3cIgh1UD8tpQprSTwlKf1B ._1gKnayOcUkILSzN_sccjDN{font-weight:bold}._3cIgh1UD8tpQprSTwlKf1B ._1DA0ZwluUtaNpWqhBouSaZ{font-size:.8em}", ""]);

// Exports
exports.locals = {
	"root": "ba3rmb8wv1Wbvmqj4TbxQ",
	"print": "_1pl3ECUDULW0BBUgYZ7jKi",
	"icon": "_1ZiHVV-_eiSazGvOP-_LJi",
	"messageContainer": "_3cIgh1UD8tpQprSTwlKf1B",
	"errorCode": "_1gKnayOcUkILSzN_sccjDN",
	"message": "_1DA0ZwluUtaNpWqhBouSaZ"
};