import LineEntity from '../entities/LineEntity';
import { IntegerSelection } from '../entities/selections/IntegerSelection';
import { RangeSelection } from '../entities/selections/RangeSelection';

const lineFactory = (
  lotteryName: string,
  selectionParts: Array<IntegerSelection | RangeSelection>,
  permutationsEnabled: boolean
): LineEntity => {
  return new LineEntity(lotteryName, selectionParts, permutationsEnabled);
};

export default lineFactory;
