import { observable, action, runInAction } from 'mobx';

import ApiUserBets from '../services/ApiUserBets';
import ApiUserBetsConnector from '../services/ApiUserBetsConnector';

import { UserBetDataInterface, UserBetsParamsInterface } from '../../shared/interfaces/GamesPicker';
import { ErrorMessage, HeadersInterface } from '../../shared/interfaces';
import UserBetsStoreInterface from '../../shared/interfaces/Stores/UserBetsInterface';

import { constans } from '../../shared/utils';

const api = new ApiUserBets();
const apiConnector = new ApiUserBetsConnector(api);

class UserBets implements UserBetsStoreInterface {
  @observable public isLoading = false;
  @observable public userBets: UserBetDataInterface[] | null = null;
  @observable public userBetsHeaders: HeadersInterface | null = null;
  @observable public error: ErrorMessage | null = null;

  @action
  fetchUserBets = async (page: number, showActiveBets: boolean, userToken: string, gameId: string): Promise<void> => {
    const params: UserBetsParamsInterface = {
      page,
      showActiveBets,
      userToken,
      perPage: constans.perPage,
      gameId,
    };
    this.isLoading = true;

    try {
      const userBets = await apiConnector.getUserBets(params);

      runInAction((): void => {
        this.isLoading = false;
        this.userBets = userBets.data;
        this.userBetsHeaders = userBets.headers;
      });
    } catch (error) {
      runInAction((): void => {
        this.isLoading = false;
        this.error = error;
      });
    }
  };

  @action
  clearUserBets = (): void => {
    this.userBets = null;
    this.userBetsHeaders = null;
    this.isLoading = false;
    this.error = null;
  };
}

export default UserBets;
