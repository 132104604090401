import { useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { observer } from 'mobx-react';

import { RootStoreContext } from '../../../lobby-engine/stores/RootStore';

interface ChildrenData {
  isOpen: boolean;
  data: object;
  onClose(): void;
}

interface ModalConnectorProps {
  name: string;
  children: (arg0: ChildrenData) => JSX.Element;
}

const ModalConnector: React.FC<ModalConnectorProps> = observer(({ name, children }: ModalConnectorProps) => {
  const modalData = useContext(RootStoreContext).modal;
  const isOpen = modalData.isOpen && modalData.name === name;

  useEffect(() => {
    //setting the body unscrollable
    if (modalData.isOpen) {
      document.body.style.height = '10vh';
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.height = null;
      document.body.style.overflow = null;
    }
  }),
    [modalData.isOpen];

  const renderContent = (): JSX.Element => {
    if (!isOpen) {
      return null;
    }

    return ReactDOM.createPortal(
      children({
        isOpen,
        data: modalData.modalProps,
        onClose: modalData.hideModal,
      }),
      document.body
    );
  };

  return renderContent();
});

export default ModalConnector;
