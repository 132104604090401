import { GameStructure } from '../../../../lobby-engine/stores/RetailDrawResultsStore';
import React, { Fragment, ReactFragment } from 'react';
import { DrawInterface } from '../../../../shared/interfaces/GamesPicker';
import ownStyles from './RetailDrawResultsPrint.scss';
import { getDateAsString } from '../../../../shared/utils';

interface RetailDrawReceipeProps {
  game: GameStructure;
}

const RetaiDrawReceipePrint: React.FC<RetailDrawReceipeProps> = ({ game }: RetailDrawReceipeProps) => {
  const renderGameDetails = () => {
    return (
      <div className={ownStyles.gameInfoContainer}>
        {game.image?.url && (
          <div className={ownStyles.gameLogo}>
            <img src={game.image.url} alt="logo"></img>
          </div>
        )}
        <div className={ownStyles.gameName}>{game.displayedName}</div>
      </div>
    );
  };

  const renderDrawDetails = (draw: DrawInterface) => {
    return (
      <div className={ownStyles.drawInfoContainer} key={`${draw.id}_info`}>
        <div className={ownStyles.drawBasicInfo}>
          <div className={ownStyles.drawDate}>{getDateAsString(draw.drawDatetime, 'DD/MM/YY HH:mm')}</div>
          <div className={ownStyles.drawNumber}>#{draw.drawNumber}</div>
        </div>
        <div className={ownStyles.drawResults} key={`${draw.id}_winnings`}>
          {renderWinningSelection(draw.wonBetSelection.asArray, draw.id)}
        </div>
      </div>
    );
  };

  const renderDraws = () => {
    return <div className={ownStyles.drawsContainer}>{game.draws.map((draw) => renderDrawDetails(draw))}</div>;
  };

  const renderWinningSelection = (winningSelections: { [k: string]: number | number[] }, drawId: string) => {
    const numbers = Object.keys(winningSelections);
    return (
      <div className={ownStyles.numbersRow} key={`${drawId}_winning_numbers`}>
        {numbers.map((key) => {
          //Selection key is type of numberes drawn. This can be "main", or some other types as "bonus"
          //Lets mark if it's main number or other
          const numberClass = key == 'main' ? ownStyles.mainNumber : ownStyles.otherNumber;
          //Numbers can be given as array or as single number
          const selection = winningSelections[key];
          let selectionText: ReactFragment[] | ReactFragment = [];
          if (Array.isArray(selection)) {
            selectionText = selection.map((value, index) => (
              <div key={`won_number_${drawId}_${key}_${index}`} className={`${ownStyles.number} ${numberClass}`}>
                <span>{value}</span>
              </div>
            ));
          } else {
            <div key={`won_number_${drawId}_${key}`} className={numberClass}>
              {selection}
            </div>;
          }
          return <Fragment key={`won_numbers_${key}_${drawId}`}>{selectionText}</Fragment>;
        })}
      </div>
    );
  };

  return (
    <div className={ownStyles.container} key={game.id}>
      {renderGameDetails()}
      {renderDraws()}
    </div>
  );
};

export default RetaiDrawReceipePrint;
