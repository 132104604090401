import { computed } from 'mobx';

import Money from '../entities/BetCostEntity';

import StakesStoreInterface from '../../shared/interfaces/Stores/StakesInterface';
import { LinesStoreInterface } from '../../shared/interfaces/Stores/LinesInterface';
import { DrawsStoreInterface } from '../../shared/interfaces/Stores/DrawsInterface';
import LineCostStoreInterface from '../../shared/interfaces/Stores/LineCostInterface';
import { TicketPlainInterface, TicketStoreInterface } from '../../shared/interfaces/Stores/TicketInterface';
import TicketSelections from '../entities/selections/TicketSelections';
import { SelectionPartInterface } from '../../shared/interfaces/Entities/TicketSelections';

export default class TicketStore implements TicketStoreInterface {
  stakes: StakesStoreInterface;
  lines: LinesStoreInterface;
  draws: DrawsStoreInterface;
  baseCost: LineCostStoreInterface;
  _betCost: Money;
  ticketSelections: TicketSelections = null;

  constructor(
    stakes: StakesStoreInterface,
    lines: LinesStoreInterface,
    draws: DrawsStoreInterface,
    baseCost: LineCostStoreInterface
  ) {
    this.stakes = stakes;
    this.lines = lines;
    this.draws = draws;
    this.baseCost = baseCost;
    this._betCost = baseCost.finalLineCost;
  }

  /**
   * Sets currency in which ticket will be submited.
   * @param currency
   */
  public setDesiredCurrency(currency: string): void {
    this.baseCost.setDesiredCurrency(currency);
    this.stakes.setStakesValues();
  }

  setTicketSelection(selectionStructure: SelectionPartInterface[]): void {
    if (selectionStructure) {
      this.ticketSelections = new TicketSelections(selectionStructure);
    } else {
      this.ticketSelections = null;
    }
  }

  @computed
  public get betCost(): Money {
    const baseCost = this.baseCost.finalLineCost.amount;
    const linesCount = this.lines.permutationsEnabled ? this.lines.realValidLinesCount : this.lines.validLinesCount;
    if (!this.stakes.stakesList) {
      const newCost = new Money();
      newCost.amount = baseCost * linesCount * this.draws.selectedNumberDraws;
      this._betCost = newCost;
    } else {
      const newCost = new Money();
      newCost.amount = linesCount * this.stakes.selectedStake.cost.amount * this.draws.selectedNumberDraws;
      this._betCost = newCost;
    }
    this._betCost.currency = this.baseCost.finalLineCost.currency;
    return this._betCost;
  }

  public get isValid(): boolean {
    return this.lines.validLinesCount > 0;
  }

  public toPlain(): TicketPlainInterface {
    const ticketSelections = this.ticketSelections ? this.ticketSelections.toPlain() : this.ticketSelections;

    return {
      draws: this.draws.toPlain(),
      currency: this._betCost.currency,
      stake: this.stakes.selectedStake.value,
      ...this.lines.toPlain(),
      ticketSelection: ticketSelections,
    };
  }
}
