import ConfigInterface from '../interfaces/ConfigInterface';
import ModalStoreInterface from '../interfaces/Stores/ModalInterface';
import { defaultErrorLoginData } from './modalConst';
import { ERROR_MODAL } from './modalNames';

/**
 * Checkes whenever a user token was provided to the lobby in correct form.
 * Will return true if token is correct,
 * Will return false if token is incorrect (empty, not defined) and will call
 *
 * Allow for demo will force to return it true if the demo capability is enabled.
 *
 * Returns true
 */
export function validateTokenAndShowModal(
  config: ConfigInterface,
  modal: ModalStoreInterface,
  allowForDemo = false
): boolean {
  if (!config.userToken) {
    //If we want to allow this part for demo, and demo is allowed in config, return it.
    if (config.allowDemo && allowForDemo) {
      return true;
    }
    //If working in iframe mode, send message to parent
    if (config.iFrameMode) {
      window.top.postMessage('noLoginMsg', '*');
    }
    //If normal,js,mode, check if callback is defined and call it if needed.
    else {
      if (typeof config.loginCb === 'function') {
        modal.showModal(ERROR_MODAL, {
          ...defaultErrorLoginData,
          errorCb: { cb: config.loginCb, txt: 'error.callback.login.txt' },
        });
      }
    }

    return false;
  }
  return true;
}
