import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './GameRules.scss';

interface GameRulesProps {
  onPageClose(): void;
  i18nGameRules: string;
}

//TODO: i18n-> remove static generated i18n rules
const GameRules: React.FC<GameRulesProps> = (props: GameRulesProps) => {
  const { onPageClose, i18nGameRules } = props;

  if (i18nGameRules) {
    return <div dangerouslySetInnerHTML={{ __html: i18nGameRules }} />;
  }

  return (
    <React.Fragment>
      <div className={styles.gameRulesWrapper}>
        <h1 className={styles.gameRulesHead}>
          <FormattedMessage id="game.rules.title" />
        </h1>
        <p className={styles.gameRulesTxt}>
          <FormattedMessage id="game.rules.terms" />
        </p>
        <p className={styles.gameRulesInfoTxt}>
          <FormattedMessage id="game.rules.p1" />
        </p>
        <p className={styles.gameRulesInfoTxt}>
          <FormattedMessage id="game.rules.p2" />
        </p>
        <p className={styles.gameRulesInfoTxt}>
          <FormattedMessage id="game.rules.p3" />
        </p>
        <p className={styles.gameRulesInfoTxt}>
          <FormattedMessage id="game.rules.p4" />
        </p>
        <p className={styles.gameRulesInfoTxt}>
          <FormattedMessage id="game.rules.p4a" />
        </p>
        <p className={styles.gameRulesInfoTxt}>
          <FormattedMessage id="game.rules.p4b" />
        </p>
        <p className={styles.gameRulesInfoTxt}>
          <FormattedMessage id="game.rules.p5" />
        </p>
        <p className={styles.gameRulesInfoTxt}>
          <FormattedMessage id="game.rules.p6" />
        </p>
        <p className={styles.gameRulesInfoTxt}>
          <FormattedMessage id="game.rules.p7" />
        </p>
        <p className={styles.gameRulesInfoTxt}>
          <FormattedMessage id="game.rules.p8" />
        </p>
        <p className={styles.gameRulesInfoTxt}>
          <FormattedMessage id="game.rules.p9" />
        </p>
        <p className={styles.gameRulesInfoTxt}>
          <FormattedMessage id="game.rules.p10" />
        </p>
        <p className={styles.gameRulesInfoTxt}>
          <FormattedMessage id="game.rules.p11" />
        </p>
        <p className={styles.gameRulesInfoTxt}>
          <FormattedMessage id="game.rules.p12" />
        </p>
        <p className={styles.gameRulesInfoTxt}>
          <FormattedMessage id="game.rules.p13" />
        </p>
        <p className={styles.gameRulesInfoTxt}>
          <FormattedMessage id="game.rules.p14" />
        </p>
        <p className={styles.gameRulesInfoTxt}>
          <FormattedMessage id="game.rules.p15" />
        </p>
        <p className={styles.gameRulesInfoTxt}>
          <FormattedMessage id="game.rules.p16" />
        </p>
        <p className={styles.gameRulesInfoTxt}>
          <FormattedMessage id="game.rules.p17" />
        </p>
        <p className={styles.gameRulesInfoTxt}>
          <FormattedMessage id="game.rules.p18" />
        </p>
        <p className={styles.gameRulesInfoTxt}>
          <FormattedMessage id="game.rules.p19" />
        </p>
        <p className={styles.gameRulesInfoTxt}>
          <FormattedMessage id="game.rules.p20" />
        </p>
        <p className={styles.gameRulesInfoTxt}>
          <FormattedMessage id="game.rules.p21" />
        </p>
        <p className={styles.gameRulesInfoTxt}>
          <FormattedMessage
            id="game.rules.link"
            values={{
              link: (
                <a className={styles.gameRulesBreakWord} href="https://editec.force.com/Cameroon/s/?language=en_US">
                  <FormattedMessage id="game.rules.link.content" />
                </a>
              ),
            }}
          />
        </p>
      </div>
      <div className={styles.gameRulesBtnWrapper}>
        <div className={styles.gameRulesBtn} onClick={onPageClose}>
          <FormattedMessage id="learn.btn1" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default GameRules;
