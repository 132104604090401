import { observable, action, runInAction } from 'mobx';
import { ErrorMessage } from '../../shared/interfaces';
import { WinningSelectionInterface } from '../../shared/interfaces/GamesPicker';
import { InstantTicketInterface } from '../../shared/interfaces/GamesPicker/TicketInterface';

import InstantGameStage from '../entities/InstantGameStage';
import ResultEntity from '../entities/ResultEntity';
import LotteryEntity from '../entities/selections/LotteryEntity';
import WonSelection from '../entities/WonSelection';
import PlayerBalanceStore from './PlayerBalanceStore';

import ApiInstantGame from '../services/ApiInstantGame';
import ApiInstantGameConnector from '../services/ApiInstantGameConnector';
import { TICKET_STATUS_WON } from '../utils/ticketsConstans';
import { InstantTicket } from '../../shared/objects/InstantTicket';

const api = new ApiInstantGame();
const apiConnector = new ApiInstantGameConnector(api);

export default class InstantGameStore {
  @observable public ticket: InstantTicket;
  public lottery: LotteryEntity;
  @observable stage: InstantGameStage;
  @observable error: ErrorMessage = null;
  @observable isLoading = false;
  @observable winningSelection: ResultEntity = null;

  constructor(public playerBalance: PlayerBalanceStore) {
    this.stage = new InstantGameStage(this);
  }

  @action
  postInstantBet = async (gameId: string, token: string, callback?: () => void): Promise<void> => {
    const body = this.lottery.toPlain();
    this.isLoading = true;

    try {
      const response: InstantTicketInterface = await apiConnector.postInstantTickets(body, gameId, token);
      this.ticket = new InstantTicket(response);
      const selection = response.bet.lines[0].wonSelection;

      this.winningSelection = new ResultEntity(selection);
      this.saveWinningSelectionToLines();
      this.stage.onTimeoutClear = () => {
        this.lottery.lines.setNewLines();
        if (callback) {
          callback();
        }
      };
      this.stage.setProperStage(response.bet.status);
      this.stage.setDisplaySelection();
      this.stage.startTimeout();
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.stage.setErrorStage();
        this.stage.startTimeout();
      });
    } finally {
      this.isLoading = false;
    }
  };

  saveWinningSelectionToLines(): void {
    const bet = this.ticket;
    const wonSelectionParts = this.ticket.winningSelectionPartsPerLine;
    const lines = this.lottery.lines.linesList;

    if (bet.status !== TICKET_STATUS_WON) {
      return null;
    }

    bet.lines.forEach((elem, index) => {
      const winningSelection = wonSelectionParts[elem.id];
      const line = lines[index];
      const wonSelection = new WonSelection();

      if (!winningSelection || !elem.won) {
        return;
      }

      line.selectionsArray.forEach((elem) => {
        const properWinningSelections: WinningSelectionInterface = wonSelection.setWinningSelection(
          winningSelection,
          elem.isPermutationOn
        );
        const properElemWinningSelection = properWinningSelections[elem.name] || [];

        elem.wonSelection = properElemWinningSelection;
      });
    });
  }
}
