import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import LabeledValue from '../../generic/LabeledValue';
import { WonBetSelectionInterface } from '../../../../shared/interfaces/GamesPicker';
import Money from '../../../../lobby-engine/entities/BetCostEntity';
import { classNames, getDateAsString } from '../../../../shared/utils';
import ownStyles from './InstantReceipt.scss';
import styles from '../../../assets/styles/index.scss';
import { RetailCashierUserEntity } from '../../../../lobby-engine/entities/RetailCashierUserEntity';
import { RootStoreContext } from '../../../../lobby-engine/stores/RootStore';
import { InstantBetDetailsLinesInterface } from '../../../../shared/interfaces/GamesPicker/InstantBetDetailsInterface';
import { InstantTicket } from '../../../../shared/objects/InstantTicket';

interface InstantReceiptProps {
  bet: InstantTicket;
  isPayout: boolean;
  isCopy: boolean;
  canceled?: boolean;
  cashierUser: RetailCashierUserEntity;
}

const InstantReceipt: React.FC<InstantReceiptProps> = ({
  bet,
  isPayout,
  canceled,
  isCopy,
  cashierUser,
}: InstantReceiptProps) => {
  const totalLineCost = new Money(bet.cachedStats.totalRealCost);
  const totalWinnings = new Money(bet.cachedStats.totalRealWinnings);
  const isWinning = bet.status && bet.status.toLowerCase() == 'won';
  const { brandStore } = useContext(RootStoreContext);
  const drawResults = bet.lines[0].wonSelection;
  const realLineCost = new Money(bet.realLineCost);
  const brandData = brandStore.data();

  /**
   * Renders a numbers of a single selection
   * @param line
   * @param elem
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderSelectionNumbers = (line: any, elem: string): JSX.Element[] | JSX.Element => {
    let i = 0;
    if (typeof line[elem] === 'number') {
      return (
        <div key={`line_number_${i++}`} className={ownStyles.txtLg}>
          {line[elem]}
        </div>
      );
    }
    //render array based selections
    return line[elem].map((lineElem: number, index: number) => {
      return (
        <div className={ownStyles.txtLg} key={`${line.id}-${index}-${lineElem}`}>
          {lineElem}
          {index !== line[elem].length - 1 && <>-</>}
        </div>
      );
    });
  };

  /**
   * Renders single selection row (picked numbers with a name of selection)
   * in correct way: With either the name or not, with markings of winnings
   *
   * @param line
   * @returns
   */
  const renderSelection = (line: { [key: string]: number[] | number }): JSX.Element[] => {
    return Object.keys(line)
      .sort((elem) => {
        if (elem === 'main') {
          return -1;
        }
      })
      .map((elem: string) => {
        //render main without a name prefix
        if (elem == 'main')
          return (
            <div key={`selection_${elem}`} className={`${ownStyles.numbers}`}>
              {renderSelectionNumbers(line, elem)}
            </div>
          );
        //render rest of selections with name prefixed
        return (
          <div key={`selection_${elem}`} className={`${ownStyles.numbers}`}>
            <div className={`${ownStyles.txtLg} ${ownStyles['selection-name']}`}>{elem}:&nbsp;</div>
            {renderSelectionNumbers(line, elem)}
          </div>
        );
      });
  };

  /**
   * Renders line block
   * * Name of selection
   * * Numbers
   * * Perms info if applicable
   */
  const renderSelectionBlock = <
    T extends {
      id: string;
      selection?: WonBetSelectionInterface;
      asSimpleNumbersArray?: { [key: string]: number[] | number };
    },
  >(
    lines: T[],
    isPermNext: boolean
  ): JSX.Element[] => {
    return lines.map((line: T, lineIndex: number) => {
      const isPerm = typeof line.asSimpleNumbersArray === 'object';
      return (
        <React.Fragment key={line.id}>
          <div className={`${ownStyles.selections}`}>
            <div className={`${ownStyles.txtLg} ${ownStyles.selections.name}`}></div>
            <div className={`${ownStyles.txtLg} ${ownStyles.selections.value}`}>
              {renderSelection(line.asSimpleNumbersArray ? line.asSimpleNumbersArray : line.selection.asArray)}
              {isPerm && <span>&#183;P</span>}
            </div>
          </div>
          {lines.length !== lineIndex - 1 && isPermNext && <hr />}
        </React.Fragment>
      );
    });
  };

  /**
   * Render all lines for normal and permutaions lines
   * @returns
   */
  const renderLinesData = (): JSX.Element | JSX.Element[] => {
    const lines = bet.lines;
    return (
      <React.Fragment>
        {lines && lines.length > 0 && renderSelectionBlock<InstantBetDetailsLinesInterface>(lines, undefined)}
      </React.Fragment>
    );
  };

  return (
    <div
      className={classNames(`${ownStyles.container}`, {
        [styles['my-2']]: !isPayout,
      })}
    >
      <div className={ownStyles.wrapper}>
        {canceled && (
          <div className={ownStyles.canceledWrapper}>
            <FormattedMessage id="receipt.p8" />
          </div>
        )}
        <div className={ownStyles.logoWrapper}>
          {brandData.retail.logoUrl && (
            <img className={ownStyles.logo} src={brandData.retail.logoUrl} alt="Ticket Logo" />
          )}
        </div>
        <hr />
        <div className={`${ownStyles.row}   `}>
          <LabeledValue
            labelClassName={`${ownStyles.label} ${ownStyles.txtMd} ${ownStyles.txtBold} `}
            valueClassName={ownStyles.txtMd}
            labelName={<FormattedMessage id="receipt.label1" />}
            value={cashierUser.shopId}
          />
          <LabeledValue
            labelClassName={`${ownStyles.label} ${ownStyles.txtMd} ${ownStyles.txtBold} `}
            valueClassName={ownStyles.txtMd}
            labelName={<FormattedMessage id="receipt.label2" />}
            value={cashierUser.userName}
          />
        </div>
        <hr />
        <hr />
        <div className={ownStyles.row}>
          <LabeledValue
            labelClassName={`${ownStyles.label} ${ownStyles.txtXLg} ${ownStyles.txtBold} `}
            valueClassName={`${ownStyles.txtXLg} ${ownStyles.txtBold} `}
            labelName={<FormattedMessage id="receipt.label3" />}
            value={`${bet.game.displayedName}`}
          />
        </div>
        <div className={ownStyles.row}>
          <LabeledValue
            labelClassName={`${ownStyles.label} ${ownStyles.txtLg} `}
            valueClassName={`${ownStyles.txtLg} `}
            labelName={<FormattedMessage id="receipt.label.purchased.at" />}
            value={getDateAsString(bet.createdAt, 'DD/MM/YY HH:mm:ss')}
          />
        </div>
        <div className={ownStyles.row}>
          <div className={`${ownStyles.selection} ${styles['p-0']} `}>
            <div className={`${ownStyles.txtLg}`}>
              <FormattedMessage id="receipt.label4" />:
            </div>
            <div className={ownStyles.lines}>{renderLinesData()}</div>
          </div>
        </div>
        <div className={`${ownStyles.row} ${ownStyles['row-stake']}`}>
          <div className={`${ownStyles.stakeContainer} ${styles['pt-2']} `}>
            <div className={`${ownStyles.txtLg}`}>
              <FormattedMessage id="receipt.label.stake_per_line" />
            </div>
            <div className={`${ownStyles.txtLg}`}>{realLineCost.toString()}</div>
          </div>
        </div>
        <div className={ownStyles.row}>
          <div className={`${ownStyles.resultsContainer} ${styles['pt-2']} `}>
            <div className={`${ownStyles.txtLg}`}>
              <FormattedMessage id="receipt.label.draw_results" />
            </div>
            <div className={`${ownStyles.txtXXLg}`}>{renderSelection(drawResults.asArray)}</div>
          </div>
        </div>

        <hr />
        <div className={ownStyles.row}>
          <div>
            <LabeledValue
              labelClassName={`${ownStyles.label} ${ownStyles.txtLg} `}
              valueClassName={`${ownStyles.txtLg} ${ownStyles.txtBold} `}
              labelName={<FormattedMessage id="receipt.label7" />}
              value={totalLineCost.toString()}
            />
          </div>
          <div>
            {isWinning && (
              <LabeledValue
                labelClassName={`${ownStyles.label} ${ownStyles.txtLg} `}
                valueClassName={`${ownStyles.txtLg} ${ownStyles.txtBold} `}
                labelName={<FormattedMessage id="receipt.label8" />}
                value={totalWinnings.toString()}
              />
            )}
            {!isWinning && (
              <LabeledValue
                labelClassName={`${ownStyles.label} ${ownStyles.txtLg} `}
                valueClassName={`${ownStyles.txtLg} ${ownStyles.txtBold} `}
                labelName={<FormattedMessage id="receipt.label8" />}
                value="Lost"
              />
            )}

            {isCopy && (
              <p className={`${ownStyles.txtLg} ${styles['m-0']} `}>
                <FormattedMessage id="receipt.p1" />
              </p>
            )}
          </div>
        </div>
        <hr />
        <div className={ownStyles.txtCenter}>
          <p className={`${ownStyles.txtS} ${styles['m-0']} `}>
            {brandData.retail.claimLine1 && <span dangerouslySetInnerHTML={{ __html: brandData.retail.claimLine1 }} />}
          </p>
          <p className={`${ownStyles.txtS} ${ownStyles.txtBold} ${styles['m-0']} `}>
            {brandData.retail.claimLine2 && <span dangerouslySetInnerHTML={{ __html: brandData.retail.claimLine2 }} />}
          </p>
        </div>
      </div>
    </div>
  );
};

export default InstantReceipt;
