import { observer } from 'mobx-react';
import React, { useContext, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useReactToPrint } from 'react-to-print';
import { RootStoreContext } from '../../lobby-engine/stores/RootStore';
import { ErrorPage } from '../pages';
import Spinner from '../../shared/components/Spinner';
import ownStyles from './styles.scss';
import styles from '../assets/styles/index.scss';
import Button from '../components/generic/Button/Button';
import { ILSError } from '../../shared/objects';
import InlineError from '../../shared/components/Error/InlineError';
import InstantReceipt from '../components/GamesPicker/InstantReceipt/InstantReceipt';

/**
 * Used in InstantTicketModal to contenerize
 * the receipt
 */
const InstantTicketReceiptContainer: React.FC = observer(() => {
  const { modal, retailCashierStore } = useContext(RootStoreContext);
  const [printCalled, setPrintCalled] = useState<number>(0);

  const betsProvider = useContext(RootStoreContext).game.instantGame;
  const bet = betsProvider.ticket;
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleSideBtnClick = async (): Promise<void> => {
    setPrintCalled(printCalled + 1);
    return handlePrint();
  };

  const isLoading = (): boolean => {
    return betsProvider.error == null && betsProvider.isLoading;
  };

  const renderContent = (): JSX.Element => {
    if (isLoading()) {
      return <Spinner />;
    }

    if (betsProvider.error) {
      return ErrorPage(betsProvider.error.message);
    }

    return (
      <div className={ownStyles.printPreview}>
        {bet instanceof ILSError ? (
          <InlineError error={bet} key={bet.code} theme="print" />
        ) : (
          <InstantReceipt
            bet={bet}
            {...{
              isPayout: false,
              canceled: false,
              isCopy: printCalled > 0 ? true : false,
              cashierUser: retailCashierStore.cashierData,
            }}
            key={bet.id}
          />
        )}
      </div>
    );
  };

  const renderCloseButton = (): JSX.Element => {
    return (
      <Button
        btnClassName={`${ownStyles.retailBtn} ${styles.print} ${ownStyles.print}`}
        onButtonClick={modal.hideModal}
      >
        <span className={`${styles.icon} ${styles.close}`} />
      </Button>
    );
  };

  const renderActionButton = (): JSX.Element => {
    return (
      <Button
        btnClassName={`
        ${ownStyles.retailBtn}
      }
      }
`}
        onButtonClick={handleSideBtnClick}
        disable={isLoading()}
      >
        <>
          <span className={`${styles.icon} ${styles['print']} `} />
          <FormattedMessage id={`retail.ticket.modal.print`} />
        </>
      </Button>
    );
  };

  const renderButtons = (): JSX.Element => {
    return (
      <div className={ownStyles.retailBtnContainer}>
        {renderActionButton()}
        {renderCloseButton()}
      </div>
    );
  };

  const renderWinLostInfo = (): JSX.Element => {
    if (!isLoading() && bet) {
      const amount = bet.getTotalWinningsValue;
      if (amount) {
        return (
          <div className={`${ownStyles.infoContainer}`}>
            <div className={`${ownStyles.retailBtn}`}>
              <FormattedMessage id={`retail.ticket.modal.should_pay`} />
              <br />
              <strong>{amount.toString()}</strong>
            </div>
          </div>
        );
      }
    } else {
      return <></>;
    }
  };

  return (
    <div className={`${ownStyles.receipt} ${styles['py-2']} `} ref={componentRef}>
      <div className={ownStyles.receiptContainer}>{renderContent()}</div>
      {renderWinLostInfo()}
      {renderButtons()}
    </div>
  );
});

export default InstantTicketReceiptContainer;
