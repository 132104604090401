import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import { observable, action, runInAction } from 'mobx';

import ApiDraw from '../services/ApiDraw';
import ApiDrawConnector from '../services/ApiDrawConnector';

import { ErrorMessage } from '../../shared/interfaces';
import { DrawInterface, LastDrawsStoreInterface } from '../../shared/interfaces/GamesPicker';
import { DrawsNumberInterface, DrawsStoreInterface } from '../../shared/interfaces/Stores/DrawsInterface';

import constans from '../../shared/utils/constans';
import { ApiParams } from '../../shared/interfaces/Api/ApiUtils';

import TimerEntity from '../entities/DrawTimeEntity';

dayjs.extend(dayOfYear);
const api = new ApiDraw();
const apiConnector = new ApiDrawConnector(api);

class DrawsStore implements DrawsStoreInterface {
  @observable public isLoading = false;
  @observable public draws: DrawInterface[] = null;
  @observable public error: ErrorMessage | null = null;
  @observable public nearestDraw: DrawInterface = null;
  @observable public selectedDraw: DrawInterface = null;
  @observable public filteredDraws: DrawInterface[] = null;
  @observable public chosenDraws: DrawInterface[] = null;
  @observable public numberOfDraws: DrawsNumberInterface[] = constans.defaultNumberOfDraws;
  @observable public selectedNumberDraws: number = constans.defaultDrawsNumber;
  @observable public countdown: TimerEntity = new TimerEntity();
  @observable public lastDraws: LastDrawsStoreInterface = {
    isLoading: false,
    error: null,
    data: null,
    headers: constans.defaultResultsHeaders,
  };

  sortDraws = (draws: DrawInterface[]): DrawInterface[] => {
    return draws.slice().sort((a: DrawInterface, b: DrawInterface) => {
      return dayjs(a.closeDatetime).valueOf() - dayjs(b.closeDatetime).valueOf();
    });
  };

  @action
  fetchDraws = async (gameId: string): Promise<void> => {
    this.isLoading = true;

    try {
      const draws = await apiConnector.getDraws(gameId);

      runInAction((): void => {
        this.draws = this.sortDraws(draws);
        this.nearestDraw = this.draws[0];
        this.selectedDraw = this.draws[0];
        this.chosenDraws = [this.draws[0]];
        this.filteredDraws = this.sortDraws([...this.draws]);
        this.countdown.setCountdownFrom(this.nearestDraw.closeDatetime);
        this.countdown.onDeductedCallback = this.getNearestDraw;
        this.countdown.setDrawTimer();
        this.isLoading = false;
      });
    } catch (error) {
      runInAction((): void => {
        this.isLoading = false;
        this.error = error.message;
      });
    }
  };

  @action
  getLastDraws = async (gameId: string, date: string, utc: string, page = 1, perPage: number): Promise<void> => {
    this.lastDraws.data = null;
    this.lastDraws.isLoading = true;
    const correctPerPage = perPage.toString() || this.lastDraws.headers.xPerPage;
    const params: ApiParams = {
      perPage: correctPerPage,
      page: page.toString(),
      'search[draws][results][fromDate]': date,
      'search[draws][results][timezone]': utc,
    };

    try {
      const response = await apiConnector.getLastDraws(gameId, params);

      runInAction(() => {
        this.lastDraws.data = response.data;
        this.lastDraws.headers = response.headers;
        this.lastDraws.isLoading = false;
        this.lastDraws.error = null;
      });
    } catch (err) {
      this.lastDraws.error = err;
      this.lastDraws.isLoading = false;
    }
  };

  @action
  resetDrawsData = (): void => {
    this.draws = null;
    this.error = null;
    this.nearestDraw = null;
    this.selectedDraw = null;
    this.filteredDraws = null;
    this.chosenDraws = null;
    this.countdown.clearDrawTimer();
  };

  @action
  getNearestDraw = (): void => {
    const newDraws = this.draws.filter((elem: DrawInterface) => elem.closeDatetime != this.nearestDraw.closeDatetime);
    const sortedNewDraws = this.sortDraws(newDraws);

    this.draws = sortedNewDraws;
    this.nearestDraw = sortedNewDraws[0];
    this.selectedDraw = this.nearestDraw;

    this.countdown.setCountdownFrom(this.nearestDraw.closeDatetime);
    this.countdown.setDrawTimer();
  };

  @action
  addToSelectedDraw = (draw: DrawInterface): void => {
    this.selectedDraw = draw;
    this.filteredDraws = this.draws.filter((elem: DrawInterface) => {
      return dayjs(draw.drawDatetime) <= dayjs(elem.drawDatetime);
    });
  };

  @action
  addToSelectedDraws = (draw: DrawInterface): void => {
    if (!this.chosenDraws) {
      this.chosenDraws = [draw];
      this.selectedNumberDraws = this.chosenDrawsLength;
      return;
    }

    const isSelected = this.chosenDraws.find((elem: DrawInterface) => elem.id === draw.id);

    if (isSelected) {
      this.chosenDraws = this.chosenDraws.filter((elem) => elem.id !== draw.id);
      this.selectedNumberDraws = this.chosenDrawsLength;
      return;
    }

    this.chosenDraws.push(draw);
    this.selectedNumberDraws = this.chosenDrawsLength;
  };

  @action
  changeChosenDrawsCount = (value: number): void => {
    if (value < this.chosenDrawsLength) {
      this.chosenDraws = this.sliceFromArray<DrawInterface>(this.chosenDraws, value);
    }

    this.chosenDraws = this.sliceFromArray<DrawInterface>(this.staticFilteredDraws, value);

    this.selectedNumberDraws = this.chosenDrawsLength;
  };

  sliceFromArray<T>(sliceFrom: T[], value: number): T[] {
    const copy = sliceFrom.slice(0, value);

    return copy;
  }

  @action
  getFilteredDrawsCount = (): number => {
    return this.filteredDraws.length;
  };

  @action
  changeDrawsNumber = (numberDrawsIndex: number): void => {
    this.numberOfDraws.forEach((elem, index) => {
      return (elem.active = numberDrawsIndex === index);
    });
    this.selectedNumberDraws = this.numberOfDraws[numberDrawsIndex].value;
  };

  @action
  disableNumberOfDraws = (numberDraws: number): void => {
    this.numberOfDraws.forEach((elem) => {
      elem.disable = numberDraws < elem.value;
    });
  };

  getDrawDay = (draw: DrawInterface): number => dayjs(draw.drawDatetime).dayOfYear();

  get drawsPerDaySorted(): DrawInterface[][] {
    let currentDay = this.getDrawDay(this.draws[0]);

    return this.draws.reduce(
      (acc: DrawInterface[][], cur: DrawInterface) => {
        const elemDay = this.getDrawDay(cur);

        if (currentDay === elemDay) {
          acc[acc.length - 1].push(cur);
          return acc;
        }

        currentDay = elemDay;
        return [...acc, [cur]];
      },
      [[]]
    );
  }

  /**
   * @inheritdoc
   *
   * Ok, that migh be done better. Somehow slice doesn't work.
   */
  get selectedDraws(): DrawInterface[] {
    let startIndex: number | null = null;
    const newDraws: DrawInterface[] = [];

    if (this.chosenDraws.length > 1) {
      return this.chosenDraws;
    }

    this.filteredDraws.forEach((draw, index) => {
      if (draw.id == this.selectedDraw.id) {
        startIndex = index;
      }
      if (startIndex !== null && index < startIndex + this.selectedNumberDraws) {
        newDraws.push(draw);
      }
    });
    return newDraws;
  }

  get staticFilteredDraws(): DrawInterface[] {
    return this.sortDraws(this.draws);
  }

  get chosenDrawsLength(): number {
    return this.chosenDraws.length;
  }

  toPlain(): string[] {
    return Array.from(this.selectedDraws, (draw) => draw.id);
  }
}

export default DrawsStore;
