import * as React from 'react';

import { classNames } from '../../utils';

import styles from './Modal.scss';

interface ModalProps {
  onClose: CallableFunction | null;
  goMyBets?: CallableFunction | null;
  children: JSX.Element;
  size?: string;
  contentOutside?: boolean | null;
  modalClassName?: string;
  backdropClassName?: string;
}

const Modal: React.FC<ModalProps> = ({
  onClose,
  children,
  size = 'default',
  contentOutside,
  modalClassName = '',
  backdropClassName = '',
}: ModalProps) => {
  const onBackdropClick = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    if (event.target === event.currentTarget && typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <div className={`${styles.modalBackdrop} ${backdropClassName}`} onClick={onBackdropClick}>
      <div
        className={classNames(`${styles.modal} ${modalClassName}`, {
          [styles.modalContentOutside]: contentOutside,
          [styles.modalLg]: size === 'large',
          [styles.modalMd]: size === 'medium',
          [styles.modalSm]: size === 'small',
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
