import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import LabeledValue from '../../generic/LabeledValue';
import Barcode from '../Barcode/Barcode';

import {
  BetDetailsInterface,
  BetDetailsLinesInterface,
  BetDetailsPermsInterface,
  WonBetSelectionInterface,
} from '../../../../shared/interfaces/GamesPicker';

import Money from '../../../../lobby-engine/entities/BetCostEntity';

import { classNames, getDateAsString } from '../../../../shared/utils';

import ownStyles from './Receipt.scss';
import styles from '../../../assets/styles/index.scss';

import { RetailCashierUserEntity } from '../../../../lobby-engine/entities/RetailCashierUserEntity';
import { RootStoreContext } from '../../../../lobby-engine/stores/RootStore';

interface ReceiptProps {
  bet: BetDetailsInterface;
  isPayout: boolean;
  isCopy: boolean;
  canceled?: boolean;
  cashierUser: RetailCashierUserEntity;
}

const Receipt: React.FC<ReceiptProps> = ({ bet, isPayout, canceled, isCopy, cashierUser }: ReceiptProps) => {
  const ticketCost = new Money(bet.realLineCost);
  const totalLineCost = new Money(bet.cachedStats.totalRealCost);
  const totalWinnings = new Money(bet.cachedStats.totalRealWinnings);
  const isWinning = bet.status && bet.status.toLowerCase() == 'won';
  const isPaidOut = bet.paidOutAt != null;
  const { brandStore } = useContext(RootStoreContext);
  const brandData = brandStore.data();

  /**
   * Renders a numbers of a single selection
   * @param line
   * @param elem
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderSelectionNumbers = (line: any, elem: string): JSX.Element[] | JSX.Element => {
    //Render single number selections
    let i = 0;
    if (typeof line[elem] === 'number') {
      return (
        <div key={`line_number_${i++}`} className={ownStyles.txtLg}>
          {line[elem]}
        </div>
      );
    }
    //render array based selections
    return line[elem].map((lineElem: number, index: number) => {
      return (
        <div className={ownStyles.txtLg} key={`${line.id}-${index}-${lineElem}`}>
          {lineElem}
          {index !== line[elem].length - 1 && <>-</>}
        </div>
      );
    });
  };

  /**
   * Renders single selection row (picked numbers with a name of selection)
   * in correct way: With either the name or not, with markings of winnings
   *
   * @param line
   * @returns
   */
  const renderSelection = (line: { [key: string]: number[] | number }): JSX.Element[] => {
    return Object.keys(line)
      .sort((elem) => {
        if (elem === 'main') {
          return -1;
        }
      })
      .map((elem: string) => {
        //render main without a name prefix
        if (elem == 'main')
          return (
            <div key={`selection_${elem}`} className={`${ownStyles.numbers}`}>
              {renderSelectionNumbers(line, elem)}
            </div>
          );
        //render rest of selections with name prefixed
        return (
          <div key={`selection_${elem}`} className={`${ownStyles.numbers}`}>
            <div className={`${ownStyles.txtLg} ${ownStyles['selection-name']}`}>{elem}:&nbsp;</div>
            {renderSelectionNumbers(line, elem)}
          </div>
        );
      });
  };
  /**
   * Renders line block
   * * Name of selection
   * * Numbers
   * * Perms info if applicable
   */
  const renderSelectionBlock = <
    T extends {
      id: string;
      selection?: WonBetSelectionInterface;
      asSimpleNumbersArray?: { [key: string]: number[] | number };
    },
  >(
    lines: T[],
    isPermNext: boolean,
    startIndex = 1
  ): JSX.Element[] => {
    return lines.map((line: T, lineIndex: number) => {
      const isPerm = typeof line.asSimpleNumbersArray === 'object';

      return (
        <React.Fragment key={line.id}>
          <div className={`${ownStyles.line} ${styles['py-1']}`}>
            <p className={`${ownStyles.txtLg} ${styles['m-0']} ${styles['col-4']} `}>
              <span className={styles['mr-1']}>{lineIndex + startIndex})</span>
              <FormattedMessage id={isPerm ? 'receipt.label16' : 'receipt.label13'} />
            </p>
            <div className={styles['col-2']}>
              <p className={`${ownStyles.txtLg} ${styles['m-0']} `}>{ticketCost.toString()}</p>
            </div>
            <div className={`${ownStyles.selections} ${styles['col-6']} `}>
              {renderSelection(line.asSimpleNumbersArray ? line.asSimpleNumbersArray : line.selection.asArray)}
              {isPerm && <span>&#183;P</span>}
            </div>
          </div>
          {lineIndex !== lines.length - 1 && <hr />}
          {lines.length !== lineIndex - 1 && isPermNext && <hr />}
        </React.Fragment>
      );
    });
  };

  /**
   * Render all lines for normal and permutaions lines
   * @returns
   */
  const renderLinesData = (): JSX.Element | JSX.Element[] => {
    // eslint-disable-next-line no-console
    const lines = bet.lines;
    const perms = bet.permutations;

    return (
      <React.Fragment>
        {lines && lines.length > 0 && renderSelectionBlock<BetDetailsLinesInterface>(lines, perms.length !== 0)}
        {perms && perms.length > 0 && renderSelectionBlock<BetDetailsPermsInterface>(perms, false, lines.length + 1)}
      </React.Fragment>
    );
  };

  return (
    <div
      className={classNames(`${ownStyles.container} `, {
        [styles['my-2']]: !isPayout,
      })}
    >
      <div className={ownStyles.wrapper}>
        {canceled && (
          <div className={ownStyles.canceledWrapper}>
            <FormattedMessage id="receipt.p8" />
          </div>
        )}
        <div className={ownStyles.logoWrapper}>
          {brandData.retail.logoUrl && (
            <img className={ownStyles.logo} src={brandData.retail.logoUrl} alt="Ticket Logo" />
          )}
        </div>
        <hr />
        <div className={`${ownStyles.row} ${styles['p-2']} `}>
          <LabeledValue
            labelClassName={`${ownStyles.label} ${ownStyles.txtMd} ${ownStyles.txtBold} `}
            valueClassName={ownStyles.txtMd}
            labelName={<FormattedMessage id="receipt.label1" />}
            value={cashierUser.shopId}
          />
          <LabeledValue
            labelClassName={`${ownStyles.label} ${ownStyles.txtMd} ${ownStyles.txtBold} `}
            valueClassName={ownStyles.txtMd}
            labelName={<FormattedMessage id="receipt.label2" />}
            value={cashierUser.userName}
          />
        </div>
        <hr />
        <hr />
        <div className={ownStyles.row}>
          <LabeledValue
            labelClassName={`${ownStyles.label} ${ownStyles.txtXLg} ${ownStyles.txtBold} `}
            valueClassName={`${ownStyles.txtXLg} ${ownStyles.txtBold} `}
            labelName={<FormattedMessage id="receipt.label3" />}
            value={bet.gameDraw.game.name}
          />
        </div>
        <div className={ownStyles.row}>
          <LabeledValue
            labelClassName={`${ownStyles.label} ${ownStyles.txtLg} `}
            valueClassName={`${ownStyles.txtLg} `}
            labelName={<FormattedMessage id="receipt.label.purchased.at" />}
            value={getDateAsString(bet.createdAt, 'DD/MM/YY HH:mm:ss')}
          />
        </div>
        <div className={ownStyles.row}>
          <div className={`${ownStyles.selection} ${styles['p-2']} `}>
            <div className={ownStyles.selectionHeaders}>
              <p className={`${ownStyles.txtBold} ${ownStyles.txtLg} ${styles['m-0']} ${styles['col-4']} `}>
                <FormattedMessage id="receipt.label14" />
              </p>
              <p className={`${ownStyles.txtBold} ${ownStyles.txtLg} ${styles['m-0']} ${styles['col-2']} `}>
                <FormattedMessage id="receipt.label6" />
              </p>
              <p
                className={`
                ${ownStyles.txtBold}
                ${ownStyles.txtLg}
                ${styles['m-0']}
                ${ownStyles.flexEnd}
                ${styles['col-6']} `}
              >
                <FormattedMessage id="receipt.label15" />
              </p>
            </div>
            <div className={ownStyles.lines}>{renderLinesData()}</div>
          </div>
        </div>
        <div className={`${ownStyles.row} ${ownStyles.rowStart} `}>
          {/* <LabeledValue
            labelClassName={`${ ownStyles.label } ${ ownStyles.txtMd } `}
            valueClassName={`${ ownStyles.txtMd } `}
            labelName={<FormattedMessage id="receipt.label4" />}
            value={bet.lines.data.length}
          />
          <span className={styles['pr-1']}>
            -
          </span> */}
          <LabeledValue
            labelClassName={`${ownStyles.label} ${ownStyles.txtMd} `}
            valueClassName={`${ownStyles.txtMd} `}
            labelName={<FormattedMessage id="receipt.label5" />}
            value={getDateAsString(bet.gameDraw.drawDatetime, 'DD/MM/YY HH:mm')}
          />
          {/* <span className={styles['pr-1']}>
            -
          </span>
          <LabeledValue
            labelClassName={`${ ownStyles.label } ${ ownStyles.txtMd } `}
            valueClassName={`${ ownStyles.txtMd } `}
            labelName={<FormattedMessage id="receipt.label6" />}
            value={ticketCost.toString()}
          /> */}
        </div>
        <hr />
        <hr />
        <div className={ownStyles.row}>
          <div>
            <LabeledValue
              labelClassName={`${ownStyles.label} ${ownStyles.txtLg} `}
              valueClassName={`${ownStyles.txtLg} ${ownStyles.txtBold} `}
              labelName={<FormattedMessage id="receipt.label7" />}
              value={totalLineCost.toString()}
            />
            <LabeledValue
              labelClassName={`${ownStyles.label} ${ownStyles.txtLg} `}
              valueClassName={`${ownStyles.txtLg} ${ownStyles.txtBold} `}
              labelName={<FormattedMessage id="receipt.label9" />}
              value={`#${bet.globalIntId} `}
            />
          </div>
          <div>
            {isWinning && (
              <LabeledValue
                labelClassName={`${ownStyles.label} ${ownStyles.txtLg} `}
                valueClassName={`${ownStyles.txtLg} ${ownStyles.txtBold} `}
                labelName={<FormattedMessage id="receipt.label8" />}
                value={totalWinnings.toString()}
              />
            )}
            {isPaidOut && (
              <LabeledValue
                labelClassName={`${ownStyles.label} ${ownStyles.txtLg} `}
                valueClassName={`${ownStyles.txtLg} ${ownStyles.txtBold} `}
                labelName={<FormattedMessage id="receipt.label.paid.out.at" />}
                value={getDateAsString(bet.paidOutAt, 'DD/MM/YY HH:mm:ss')}
              />
            )}
            {isCopy && (
              <p className={`${ownStyles.txtLg} ${styles['m-0']} `}>
                <FormattedMessage id="receipt.p1" />
              </p>
            )}
          </div>
        </div>
        <hr />
        <div className={ownStyles.txtCenter}>
          <p className={`${ownStyles.txtS} ${styles['m-0']} `}>
            {brandData.retail.claimLine1 && <span dangerouslySetInnerHTML={{ __html: brandData.retail.claimLine1 }} />}
          </p>
          <p className={`${ownStyles.txtS} ${ownStyles.txtBold} ${styles['m-0']} `}>
            {brandData.retail.claimLine2 && <span dangerouslySetInnerHTML={{ __html: brandData.retail.claimLine2 }} />}
          </p>
        </div>
        <Barcode
          key={`barcode_global_id`}
          wrapperClassName={ownStyles.barcodeWrapper}
          barcodeClassName={ownStyles.barcode}
          valueClassName={`${ownStyles.txtXXLg} ${ownStyles.txtBold} `}
          value={`${bet.globalIntId.toString()}#${bet.secretCode.toString()} `}
          displayValue={false}
          displayPassedValue={true}
        />
      </div>
    </div>
  );
};

export default Receipt;
