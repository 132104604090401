import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import GameTopBarContainer from './GameTopBarContainer';
import NumberPickerContainer from './NumberPickerContainer';
import SummaryContainer from './SummaryContainer';

import constans from '../../shared/utils/constans';

import LotteryEntity from '../../lobby-engine/entities/selections/LotteryEntity';
import { GameStoreInterface } from '../../shared/interfaces/Stores';
import Spinner from '../../shared/components/Spinner';

interface LotteryContainerProps {
  lottery: LotteryEntity;
  game: GameStoreInterface;
  onClose(): void;
}

const LotteryContainer: React.FC<LotteryContainerProps> = observer(({ lottery, game, onClose }) => {
  useEffect(() => {
    if (lottery.draws.draws) {
      return;
    }

    lottery.draws.fetchDraws(game.gameId);
  }, [lottery.draws, game.gameId]);

  const renderContent = (): JSX.Element => {
    if (!lottery.draws.draws) {
      return <Spinner />;
    }

    const drawSelectionRender = constans.lotteryNamesDrawSelection.indexOf(game.lotteryStructureName) != -1;
    const fastPicker = constans.lotteryNamesPickX.indexOf(game.lotteryStructureName) != -1;
    const turboPicker = constans.lotteryTurboTypePicker.indexOf(game.lotteryStructureName) != -1;
    const gameId = game.gameId;
    const { lines, draws, stakes, ticket } = lottery;

    return (
      <React.Fragment>
        <GameTopBarContainer {...{ onClose, fastPicker, turboPicker, draws }} />
        <NumberPickerContainer {...{ fastPicker, drawSelectionRender, gameId, draws, lines }} />
        <SummaryContainer {...{ onClose, draws, ticket, lines, stakes }} />
      </React.Fragment>
    );
  };

  return renderContent();
});

export default LotteryContainer;
