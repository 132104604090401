import { action } from 'mobx';

import LotteriesStore from './LotteriesStore';
import LotteryEntity from '../entities/selections/LotteryEntity';
import ApiGamesConnector from '../services/ApiGamesConnector';
import ApiGames from '../services/ApiGames';
import ModalStore from './ModalStore';
import PurchaseStore from './PurchaseStore';
import { GameStoreInterface } from '../../shared/interfaces/Stores';

const api = new ApiGames();
const apiConnector = new ApiGamesConnector(api);

/**
 * Controls purchasing the tickets in retail mode.
 *
 * Works with PurchaseStore and InstantPurchase store that
 * hold the data about already purchased tickets in retail mode.
 */
class RetailStore {
  constructor(
    public purchaseStore: PurchaseStore,
    public gameStore: GameStoreInterface,
    public lotteries: LotteriesStore,
    public modal: ModalStore
  ) {}

  @action
  postNormalBet = async (token: string): Promise<void> => {
    this.purchaseStore.reset();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let submitedBets: string[];
    await Promise.all(
      this.lotteries.lotteriesList.map(async (elem: LotteryEntity) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const singleResponse: any = await apiConnector.postBets(elem.toPlain(), elem.gameId, token);
        submitedBets = singleResponse.map((value: { id: string }) => value.id);
        await this.purchaseStore.fetchBets(submitedBets);
      })
    );
  };
}

export default RetailStore;
