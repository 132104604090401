import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import ownStyles from './RetailDrawResults.scss';

import { RootStoreContext } from '../../../../lobby-engine/stores/RootStore';
import { observer } from 'mobx-react';
import Spinner from '../../../../shared/components/Spinner';
import RetailDrawResultsPrint from './RetailDrawResultsPrint';
import Button from '../../generic/Button/Button';
import { useReactToPrint } from 'react-to-print';
import { getDaysOptions, getMonthOptions, getYearOptions } from '../../../../shared/utils/selectOptions';
import { getDateFromData, getToday, getTodayAsString } from '../../../../shared/utils';
import Select from '../../generic/Select';

interface StateDateInterface {
  day: string;
  month: string;
  year: string;
}

const RetailDrawResults: React.FC<{}> = observer(() => {
  const { lobby, retailDrawResults } = useContext(RootStoreContext);
  const componentRef = useRef();

  const [isInInit, setIsInInit] = useState<boolean>(true);
  const [date, setDate] = useState<StateDateInterface>({ ...getToday() });
  const [dateAsString, setDateAsString] = useState<string>(getTodayAsString());

  /**
   * Date option change handlare
   * @param name - name of group (day/month)
   * @param value - new value for group
   */
  const onDateOptionChange = (name: string, value: string): void => {
    setDate((prevState: StateDateInterface) => {
      const newData = {
        ...prevState,
        [name]: value,
      };
      const dateToString = getDateFromData(newData);
      setDateAsString(dateToString);
      return newData;
    });
  };

  const handleGameClick = (gameId: string) => {
    let games = [...retailDrawResults.enabledGames];
    if (games.includes(gameId)) {
      games = retailDrawResults.enabledGames.filter((game) => game !== gameId);
    } else {
      games.push(gameId);
    }

    retailDrawResults.enabledGames = games;
    setLocalStorage();
  };

  const handleDrawLimitClick = (limit: number) => {
    retailDrawResults.drawsLimit = limit;
    setLocalStorage();
  };

  /** Prining **/
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrintClick = () => {
    handlePrint();
  };

  /**
   * Fetch games meta data (mainly game name)
   */
  const fetchMetadata = useCallback(async () => {
    await retailDrawResults.getGamesMetaData(lobby.games);
    setIsInInit(false);
  }, []);

  /**
   * Fetching games & draws info based on current state
   */
  const fetchResults = useCallback(async () => {
    await retailDrawResults.getDrawsAndResults(retailDrawResults.enabledGames, dateAsString);
  }, [retailDrawResults.enabledGames, retailDrawResults.drawsLimit, dateAsString]);

  /**
   * Fetch games on init
   */
  useEffect(() => {
    getLocalStorage(lobby.games);
    if (lobby.games.length > 0) {
      fetchMetadata();
    }
  }, []);

  /**
   * Fetch on filters change
   */
  useEffect(() => {
    if (lobby.games.length > 0 && !isInInit) {
      fetchResults();
    }
  }, [retailDrawResults.enabledGames, retailDrawResults.drawsLimit, dateAsString, isInInit, lobby.games]);

  /**
   * Save filters config to local storage
   */
  const getLocalStorage = (availableGames: string[]) => {
    try {
      const savedGames = JSON.parse(localStorage.getItem('print.results.filter.games'));
      const filteredGames = savedGames.filter((value: string) => availableGames.includes(value));
      if (Array.isArray(savedGames)) {
        retailDrawResults.enabledGames = filteredGames;
      }

      const savedLimit = localStorage.getItem('print.results.filter.drawLimit');
      if (savedLimit) {
        retailDrawResults.drawsLimit = Number(savedLimit);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Could not restore data from local storage:', error);
    }
  };
  /**
   * Read filters config from local storage
   */
  const setLocalStorage = () => {
    try {
      localStorage.setItem('print.results.filter.games', JSON.stringify(retailDrawResults.enabledGames));
      localStorage.setItem('print.results.filter.drawLimit', retailDrawResults.drawsLimit.toString());
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Could not save data to local storage', error);
    }
  };

  /**
   * Render print container with games data
   * @returns
   */
  const renderGames = () => {
    const games = retailDrawResults.data;
    return (
      <div className={ownStyles.printInContainer} ref={componentRef}>
        {Object.values(games).map((game) => {
          return <RetailDrawResultsPrint game={game} key={game.id} />;
        })}
      </div>
    );
  };

  const renderGamesFilter = () => {
    const games = retailDrawResults.gamesMetaData;
    const enabledGames = retailDrawResults.enabledGames;
    const isLoadingGames = retailDrawResults.isLoadingMeta;
    return (
      <div className={ownStyles.filterContainer}>
        <div className={ownStyles.filterHeader}>
          <FormattedMessage id={'info.game.results.select.games'} />
        </div>

        {!isLoadingGames ? (
          <div className={ownStyles.filterOptionsContainer}>
            {Object.values(games).map((game) => {
              return (
                <Button
                  type={enabledGames.includes(game.id) ? 'default' : 'inactive'}
                  key={`filter_${game.id}`}
                  btnClassName={ownStyles.filterButton}
                  onButtonClick={() => handleGameClick(game.id)}
                >
                  <>{game.displayedName}</>
                </Button>
              );
            })}
          </div>
        ) : (
          <div className={ownStyles.filterOptionsContainerLoader}>
            <Spinner />
          </div>
        )}
      </div>
    );
  };

  const renderDrawsLimitFilter = () => {
    const drawLimit = retailDrawResults.drawsLimit;
    return (
      <div className={ownStyles.filterContainer}>
        <div className={ownStyles.filterHeader}>
          <FormattedMessage id={'info.game.results.select.nrdraws'} />
        </div>
        <div className={ownStyles.filterOptionsContainer}>
          <Button
            onButtonClick={() => handleDrawLimitClick(1)}
            type={drawLimit == 1 ? 'default' : 'inactive'}
            btnClassName={ownStyles.filterButton}
          >
            <>1</>
          </Button>
          <Button
            onButtonClick={() => handleDrawLimitClick(0)}
            type={drawLimit == 0 ? 'default' : 'inactive'}
            btnClassName={ownStyles.filterButton}
          >
            <>All</>
          </Button>
        </div>
      </div>
    );
  };

  const renderDatesFilter = () => {
    return (
      <div className={ownStyles.filterContainer}>
        <p className={ownStyles.filterHeader}>
          <FormattedMessage id={'info.game.results.select.date'} />
        </p>
        <div className={`${ownStyles.filterOptionsContainer}`}>
          <Select
            options={getDaysOptions(date.year, date.month)}
            onChange={(value): void => onDateOptionChange('day', value)}
            defaultSelect={date.day}
          />
          <Select
            options={getMonthOptions()}
            onChange={(value): void => onDateOptionChange('month', value)}
            defaultSelect={date.month}
            isTranslated
            translationId="select.month"
            translationVarName="month"
          />
          <Select
            options={getYearOptions(date.year)}
            onChange={(value): void => onDateOptionChange('year', value)}
            defaultSelect={date.year}
          />
        </div>
      </div>
    );
  };

  /** Render **/
  return (
    <div className={ownStyles.container}>
      <div className={ownStyles.filtersContainerWrapper}>
        {renderDatesFilter()}
        {renderGamesFilter()}
        {renderDrawsLimitFilter()}
      </div>
      <div className={ownStyles.buttonsContainer}>
        <div className={ownStyles.singleButtonWrapper}>
          <Button onButtonClick={handlePrintClick} disable={retailDrawResults.isLoading}>
            <>
              <FormattedMessage id="retail.results.print" />
            </>
          </Button>
        </div>
      </div>
      <div className={ownStyles.printoutContainer}>{retailDrawResults.isLoading ? <Spinner /> : renderGames()}</div>
      {retailDrawResults.error && (
        <div className={ownStyles.errorContainer}>
          <FormattedMessage id="retail.results.error" />
        </div>
      )}
    </div>
  );
});

export default RetailDrawResults;
