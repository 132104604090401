import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import styles from './Countdown.scss';
import themeStyles from '../../assets/styles/lottery-styles.scss';

import DrawTimerEntity from '../../../lobby-engine/entities/DrawTimeEntity';

interface CountdownProps {
  drawDate: string;
  betStatus: string;
  onDrawStatusChange(status: string): void;
  countdown: DrawTimerEntity;
}

// interface CountdownState {
//   days: number;
//   hours: number;
//   minutes: number;
//   seconds: number;
// }

const Countdown: React.FC<CountdownProps> = observer((props: CountdownProps) => {
  const { onDrawStatusChange, betStatus, countdown } = props;
  const { displayTime, timeLeft } = countdown;

  useEffect(() => {
    if (timeLeft <= 0) {
      onDrawStatusChange('closed');
      return;
    }

    if (displayTime.minutes !== 0 && betStatus !== 'open') {
      onDrawStatusChange('open');
      return;
    }

    if (displayTime.minutes === 0 && betStatus !== 'last') {
      onDrawStatusChange('last');
      return;
    }
  }, [onDrawStatusChange, betStatus, displayTime.minutes, timeLeft]);

  // componentWillUnmount
  useEffect(() => {
    return (): void => countdown.clearDrawTimer();
  }, [countdown]);

  const formatClockValues = (value: number): string => {
    if (value < 0) {
      return '00';
    }
    return value < 10 ? `0${value}` : `${value}`;
  };

  return (
    <div className={`${styles.countdownContainer} ${themeStyles.countdownContainer}`}>
      {displayTime.days !== 0 && (
        <div className={`${styles.countdownItem} ${themeStyles.countdownItem}`}>
          <span className={`${styles.countdownItemTxt} ${styles.countdownTime} ${themeStyles.countdownItemTxt}`}>
            {formatClockValues(displayTime.days)}
          </span>
          <span className={`${styles.countdownItemTxt} ${themeStyles.countdownItemTxt}`}>D</span>
        </div>
      )}
      {displayTime.hours !== 0 && (
        <div className={styles.countdownItem}>
          <span className={`${styles.countdownItemTxt} ${styles.countdownTime} ${themeStyles.countdownItemTxt}`}>
            {formatClockValues(displayTime.hours)}
          </span>
          <span className={`${styles.countdownItemTxt} ${themeStyles.countdownItemTxt}`}>HRS</span>
        </div>
      )}
      <div className={styles.countdownItem}>
        <span className={`${styles.countdownItemTxt} ${styles.countdownTime} ${themeStyles.countdownItemTxt}`}>
          {formatClockValues(displayTime.minutes)}
        </span>
        <span className={`${styles.countdownItemTxt} ${themeStyles.countdownItemTxt}`}>MIN</span>
      </div>
      <div className={styles.countdownItem}>
        <span className={`${styles.countdownItemTxt} ${styles.countdownTime} ${themeStyles.countdownItemTxt}`}>
          {formatClockValues(displayTime.seconds)}
        </span>
        <span className={`${styles.countdownItemTxt} ${themeStyles.countdownItemTxt}`}>SEC</span>
      </div>
    </div>
  );
});

export default Countdown;
