interface ErrorMessage {
  id: string;
  slug: string;
}

const getErrorMessage = (errorCode: number, slug: string): ErrorMessage => {
  // TO DO: add more error messages, there was another task for that afair
  switch (errorCode) {
    case 401:
      return {
        id: 'play.modal.error.401',
        slug,
      };
    case 400:
      return {
        id: 'play.modal.error.400',
        slug,
      };
    case 503:
      return {
        id: 'play.modal.error.503',
        slug,
      };
    default:
      return {
        id: 'play.modal.error.p1',
        slug: '',
      };
  }
};

export default getErrorMessage;
