import React from 'react';
import ModalConnector from '../../../shared/components/Modals/ModalConnector';
import { INSTANT_TICKET_RECEIPT_MODAL } from '../../../shared/utils/modalNames';
import InstantTicketReceiptContainer from '../../containers/InstantTicketReceiptContainer';
import { Modal } from '../generic';
import ownStyles from './MultiTicketReceiptModal.scss';

interface ModalProps {
  isOpen: boolean;
  onClose(): void;
}

const InstantTicketReceiptModal: React.FC<{}> = () => {
  return (
    <ModalConnector name={INSTANT_TICKET_RECEIPT_MODAL}>
      {({ onClose }: ModalProps): JSX.Element => {
        return (
          <Modal modalClassName={ownStyles.modal} {...{ onClose }} contentOutside size="medium">
            <InstantTicketReceiptContainer />
          </Modal>
        );
      }}
    </ModalConnector>
  );
};

export default InstantTicketReceiptModal;
