import React from 'react';
import { FormattedMessage } from 'react-intl';

import ModalConnector from '../../../shared/components/Modals/ModalConnector';
import Modal from '../../../shared/components/Modals/Modal';

import { PERMUTATIONS_MODAL } from '../../../shared/utils/modalNames';

import styles from './PermutationsModal.scss';

interface PermutationsModalProps {
  isOpen: boolean;
  data: {
    onConfirmClick(): void;
    onCancelClick(): void;
  };
  onClose(): void;
}

const PermutationsModal: React.FC<{}> = (): JSX.Element => {
  return (
    <ModalConnector name={PERMUTATIONS_MODAL}>
      {({ isOpen, onClose, data }: PermutationsModalProps): JSX.Element => {
        const onConfirmBtnClick = (): void => {
          data.onConfirmClick();
          onClose();
        };

        const onCancelBtnClick = (): void => {
          data.onCancelClick();
          onClose();
        };

        return (
          <Modal {...{ onClose, isOpen }} contentOutside>
            <div className={styles.modalWrapper}>
              <div className={styles.closeIcon} onClick={onCancelBtnClick}>
                
              </div>
              <p className={styles.modalTxt}>
                <FormattedMessage id={'permutation.modal.p1'} />
              </p>
              <div className={styles.btnWrapper}>
                <div className={`${styles.modalBtn} ${styles.cancelBtn}`} onClick={onCancelBtnClick}>
                  <FormattedMessage id={'permutation.modal.cancel.p1'} />
                </div>
                <div className={`${styles.modalBtn} ${styles.confirmBtn}`} onClick={onConfirmBtnClick}>
                  <FormattedMessage id={'permutation.modal.confirm.p1'} />
                </div>
              </div>
            </div>
          </Modal>
        );
      }}
    </ModalConnector>
  );
};

export default PermutationsModal;
