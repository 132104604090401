import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
// import containers
import GameContainer from './GameContainer';
import InfoContainer from './InfoContainer';
import UserBetsContainer from './UserBetsContainer';

import TopBar from '../components/GamesPicker/TopBar';
import GameRules from '../components/GameRules';

import { RootStoreContext } from '../../lobby-engine/stores/RootStore';

import { IS_RETAIL, pageNames } from '../../shared/utils';
import { DEFAULT_LOBBY_TOP_BAR } from '../../shared/utils/constans/TopBarConst';

import styles from '../assets/styles/index.scss';
import lotteryStyles from '../assets/styles/lottery-styles.scss';

interface GamesPickerContainerProps {
  onClose(): void;
}

const GamesPickerContainer: React.FC<GamesPickerContainerProps> = observer((props: GamesPickerContainerProps) => {
  const { game, i18n, navigation } = useContext(RootStoreContext);
  const gameId = useMemo(() => game.gameId, [game]);
  const lotteryStructureName = game.lotteryStructureName;
  const i18nGameRules = i18n.i18nData ? i18n.i18nData.rules : null;

  useEffect(() => {
    return (): void => {
      if (IS_RETAIL) {
        return;
      }

      game.resetGameStructure();
    };
  }, [game]);

  const onPageChange = useCallback(
    (page: string): void => {
      return navigation.changePage(page);
    },
    [navigation]
  );

  const onPageClose = useCallback((): void => {
    return navigation.changePage(pageNames.GAME_PLAY);
  }, [navigation]);

  const renderPage = (): JSX.Element => {
    const { onClose } = props;

    switch (navigation.activePage) {
      case pageNames.GAME_PLAY:
        return <GameContainer {...{ gameId, onClose }} />;
      case pageNames.GAME_INFORMATION:
        return <InfoContainer {...{ gameId, onClose, onPageClose, onPageChange }} />;
      case pageNames.USER_BETS:
      case pageNames.USER_BETS_SINGLE:
        return <UserBetsContainer {...{ onPageClose, gameId }} />;
      case pageNames.GAME_RULES:
        return <GameRules {...{ onPageClose, i18nGameRules }} />;
      default:
        return null;
    }
  };

  return (
    <div className={lotteryStyles[lotteryStructureName]}>
      <TopBar topBarItems={DEFAULT_LOBBY_TOP_BAR} />
      <div className={styles.contentContainer}>{renderPage()}</div>
    </div>
  );
});

export default GamesPickerContainer;
