import { useCallback, useEffect, useState } from 'react';

const useMobileWidth = (): boolean => {
  const [isMobile, setIsMobile] = useState(false);

  const checkForWidth = useCallback((): void => {
    const mobile = window.innerWidth <= 768;

    setIsMobile(mobile);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', checkForWidth);
    checkForWidth();

    return (): void => window.removeEventListener('resize', checkForWidth);
  }, [checkForWidth]);

  return isMobile;
};

export default useMobileWidth;
