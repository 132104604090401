import { ApiGamesInterface } from '../../shared/interfaces/Api';
import { GameBetBodyInterface, GameStructureInterface } from '../../shared/interfaces/GamesPicker';

class ApiGamesConnector {
  public api: ApiGamesInterface;

  public constructor(api: ApiGamesInterface) {
    this.api = api;
  }

  async getToken(gameId: string): Promise<string> {
    const response = await this.api.getToken(gameId);
    if (!response.ok) {
      throw await response.json();
    }

    return await response.json();
  }

  async getGameStructure(gameId: string): Promise<GameStructureInterface> {
    const response = await this.api.getGameStructure(gameId);

    if (!response.ok) {
      throw await response.json();
    }

    return await response.json();
  }

  async postBets(body: GameBetBodyInterface, gameId: string, token: string): Promise<Response> {
    const response = await this.api.postBets(body, gameId, token);

    if (!response.ok) {
      throw await response.json();
    }

    return await response.json();
  }
}

export default ApiGamesConnector;
