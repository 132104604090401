exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1OC7JNDw9LbSf0Ug1vXYhA{font-size:1rem;margin:0}.UAnQeXn_gxCm4IvOpwAVc{color:#fff}.SaP1c4IuvuEnhhLWYw9Xk{color:#000}._2uSOxxUdBWoR3iI4WqBMJ-{color:#fff}._1VixoasFzTKdyLq2BUFWZ3{color:#e6451b}._2p-IjeGGYfRVg98ql3qXR6{color:#ff8f07}._2F6nftu8DKrrerbKbZuTDx{color:#009a44}._2K2ZkI1lj4Y8Hlc8iZ9cc{text-align:left}.aoH3WulKFBVj06bJ2rfyt{text-align:right}._2Mtn5ZMK_hXdawInyAYalT{text-align:center}._3s3A8sBTc3ay8YbraGfdGp{font-size:.75rem}._5Q1R3clsn7p8i5a8lbGvV{font-size:1rem}._16rD6KwRjYll406q5E4BN-{font-size:1.25rem}._3nvREr9ZEX8L0LNLe0EUAU{font-weight:200}._16xgrnCaoG6RIWRFtLMppp{font-weight:normal}._2wvWe88RKsVEgx8XpfOTgs{font-weight:bold}", ""]);

// Exports
exports.locals = {
	"text": "_1OC7JNDw9LbSf0Ug1vXYhA",
	"light": "UAnQeXn_gxCm4IvOpwAVc",
	"default": "SaP1c4IuvuEnhhLWYw9Xk",
	"primary": "_2uSOxxUdBWoR3iI4WqBMJ-",
	"danger": "_1VixoasFzTKdyLq2BUFWZ3",
	"warning": "_2p-IjeGGYfRVg98ql3qXR6",
	"success": "_2F6nftu8DKrrerbKbZuTDx",
	"leftAlign": "_2K2ZkI1lj4Y8Hlc8iZ9cc",
	"rightAlign": "aoH3WulKFBVj06bJ2rfyt",
	"centerAlign": "_2Mtn5ZMK_hXdawInyAYalT",
	"smallSize": "_3s3A8sBTc3ay8YbraGfdGp",
	"mediumSize": "_5Q1R3clsn7p8i5a8lbGvV",
	"largeSize": "_16rD6KwRjYll406q5E4BN-",
	"thin": "_3nvREr9ZEX8L0LNLe0EUAU",
	"medium": "_16xgrnCaoG6RIWRFtLMppp",
	"bold": "_2wvWe88RKsVEgx8XpfOTgs"
};