import { observable } from 'mobx';
import dayjs from 'dayjs';

import { DateFilterType } from '../../shared/interfaces/Stores/LastDrawsInterface';
import { DataFilterPlainType } from '../../shared/interfaces/Entities/DataFilter';

import { getTodayAsString, getDateAsString } from '../../shared/utils';
import { DATE_FORMATS, MONTH, REPORT_TYPE, DATE } from '../utils/filtersConstans';

class DataFilter {
  @observable toDate: string;
  @observable fromDate: string;
  @observable dataFilteringType: DateFilterType;
  private dateFormat: string;

  constructor(dataFilteringType: DateFilterType = DATE) {
    this.dataFilteringType = dataFilteringType;
    this.setDatesDefault();
  }

  private setDateFormat(): void {
    this.dateFormat = DATE_FORMATS[this.dataFilteringType];
    this.setDatesDefault();
  }

  private setDatesDefault(): void {
    if (this.dataFilteringType === MONTH) {
      this.fromDate = dayjs().startOf('month').format('');
      this.toDate = dayjs().endOf('month').format('');

      return;
    }

    this.fromDate = getTodayAsString(this.dateFormat);
    this.toDate = getTodayAsString(this.dateFormat);
  }

  private setApiDate(date: string): string {
    return getDateAsString(date, DATE_FORMATS.api);
  }

  changeFromDate(date: string): void {
    this.fromDate = date;
  }

  changeToDate(date: string): void {
    this.toDate = date;
  }

  changeDataFilteringType(value: DateFilterType): void {
    if (this.dataFilteringType === value) {
      return;
    }

    this.dataFilteringType = value;
    this.setDateFormat();
  }

  reset(): void {
    this.setDatesDefault();
    this.dataFilteringType;
    this.dateFormat;
  }

  toPlain(filteringType: string): DataFilterPlainType {
    let toDate = this.toDate;

    if (this.dataFilteringType === MONTH) {
      toDate = dayjs(this.toDate).date(dayjs(this.toDate).daysInMonth()).format('');
    }

    if (filteringType === REPORT_TYPE) {
      return {
        fromDate: this.fromDate,
        toDate,
      };
    }

    return {
      'search[drawDatetime][datetime-range][after]': this.setApiDate(this.fromDate),
      'search[drawDatetime][datetime-range][before]': this.setApiDate(toDate),
    };
  }
}

export default DataFilter;
