import React from 'react';

import ModalConnector from '../../../shared/components/Modals/ModalConnector';

import { TICKET_MODAL } from '../../../shared/utils/modalNames';

import { Modal } from '../generic';

import ReceiptContainer from '../../containers/ReceiptContainer';

import ownStyles from './TicketModal.scss';
import { classNames } from '../../../shared/utils';

interface ModalProps {
  isOpen: boolean;
  data: {
    isPayout: boolean;
    isCopy: boolean;
    canceled?: boolean;
  };
  onClose(): void;
}

const TicketModal: React.FC<{}> = () => {
  return (
    <ModalConnector name={TICKET_MODAL}>
      {({ data, onClose }: ModalProps): JSX.Element => {
        return (
          <Modal
            modalClassName={classNames(ownStyles.modal, {
              [ownStyles.modalPayout]: data.isPayout,
            })}
            {...{ onClose }}
            contentOutside
            size="medium"
          >
            <ReceiptContainer isPayout={data.isPayout} canceled={data.canceled} isCopy={data.isCopy} />
          </Modal>
        );
      }}
    </ModalConnector>
  );
};

export default TicketModal;
