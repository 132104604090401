import { observable, action, runInAction } from 'mobx';

import ApiInstructionsConnector from '../services/ApiInstructionsConnector';
import ApiInstructions from '../services/ApiInstructions';

import { InstructionPageStoreInterface, LastDrawsStoreInterface } from '../../shared/interfaces/GamesPicker';
import { LastDrawDateInterface } from '../../shared/interfaces/GamesPicker';
import InstructionStoreInterface from '../../shared/interfaces/Stores/InstructionInterface';
import { constans } from '../../shared/utils';

const api = new ApiInstructions();
const apiConnector = new ApiInstructionsConnector(api);

export default class InstructionsStore implements InstructionStoreInterface {
  @observable instructionPage: InstructionPageStoreInterface = {
    isLoading: false,
    error: null,
    data: null,
  };

  @observable lastDraws: LastDrawsStoreInterface = {
    isLoading: false,
    error: null,
    data: null,
    headers: constans.defaultResultsHeaders,
  };

  @observable lastDrawDate: LastDrawDateInterface = {
    data: { date: '' },
    error: null,
  };

  @action
  getLastDraws = async (gameId: string, date: string, utc: string, page = 1, perPage: number): Promise<void> => {
    this.lastDraws.data = null;
    this.lastDraws.isLoading = true;
    const correctPerPage = perPage || this.lastDraws.headers.xPerPage;

    try {
      const response = await apiConnector.getLastDraws(gameId, date, utc, page.toString(), correctPerPage.toString());

      runInAction(() => {
        this.lastDraws.data = response.data;
        this.lastDraws.headers = response.headers;
        this.lastDraws.isLoading = false;
        this.lastDraws.error = null;
      });
    } catch (err) {
      this.lastDraws.error = err;
      this.lastDraws.isLoading = false;
    }
  };

  @action
  getLastDrawDate = async (gameId: string): Promise<void> => {
    try {
      this.lastDrawDate.data = { date: '' };
      const response = await apiConnector.getLastDrawDate(gameId);
      runInAction(() => {
        this.lastDrawDate.data = response.data;
        this.lastDrawDate.error = null;
      });
    } catch (error) {
      this.lastDrawDate.error = error;
    }
  };

  @action
  resetInstructionStore = (): void => {
    this.instructionPage = {
      isLoading: false,
      error: null,
      data: null,
    };

    this.lastDraws = {
      isLoading: false,
      error: null,
      data: null,
      headers: constans.defaultResultsHeaders,
    };

    this.lastDrawDate = {
      data: { date: '' },
      error: null,
    };
  };
}
