import React from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import NumberButton from '../NumberButton';
import NumberBalls from '../NumberBalls/NumberBalls';

import { ImageInterface } from '../../../../../shared/interfaces';
import { selectionType } from '../../../../../shared/interfaces/Entities/SelectionParams';

import LineEntity from '../../../../../lobby-engine/entities/LineEntity';

import { classNames, IS_EDITEC_THEME } from '../../../../../shared/utils';

import close from '../../../../assets/images/close.svg';
import confirm from '../../../../assets/images/check.svg';
import confirmSuccess from '../../../../assets/images/check-success.svg';

import styles from './FastPicker.scss';

import config from '../../../../../../../app.config';

interface FastPickerSingular {
  line: LineEntity;
  logo: ImageInterface;
  onCancel(): void;
  onConfirmClick(line: LineEntity): void;
}

const FastPickerSingular: React.FC<FastPickerSingular> = observer(
  ({ onCancel, onConfirmClick, logo, line }: FastPickerSingular) => {
    /* design state like this, for easier and faster development, anyway, we should
  change it to "virtual" line, as we spoke */

    const onQuickPickClick = (): void => {
      line.randomizeChoice();
    };

    const onNumberClick = (value: number, selectionName: string, numberAtIndex: number | null = null): void => {
      if (numberAtIndex === null) {
        line.addNumber(value, selectionName);
      } else {
        line.setNumberAtIndex(value, numberAtIndex, selectionName);
      }
    };

    /**
     * Renders
     * @param selection
     */
    const renderNumbers = (selection: selectionType, numberAtIndex: number | null = null): JSX.Element[] => {
      const range = selection.range;
      const numbers = numberAtIndex === null ? selection.numbers : [selection.numbers[numberAtIndex]];
      const calculatedRange = range.end - range.start + 1;

      return [...Array(calculatedRange)].map((elem: undefined, index: number) => {
        const value: number = range.start + index;
        const isActive: boolean = numbers.some((elem: number) => elem === value);

        return (
          <NumberButton
            isActive={isActive}
            key={`${index}`}
            btnNumber={value}
            width={7}
            onNumberClick={(): void => onNumberClick(value, selection.name, numberAtIndex)}
            className={classNames(`${styles.fastPickerNumberButton}`, {
              [styles.bonusNumberButton]: selection.name !== 'main',
              [styles.uniqNumbersLotteryButton]: selection.allowNonUniqueNumbers,
            })}
            activeClassName={classNames(`${styles.fastPickerNumberButtonActive}`, {
              [styles.bonusNumberButtonActive]: selection.name !== 'main',
            })}
          />
        );
      });
    };

    const renderSelections = (): (JSX.Element | JSX.Element[])[] => {
      return line.selectionsArray.map((elem: selectionType) => {
        if (elem.isSubType) {
          return null;
        }

        if (elem.allowNonUniqueNumbers) {
          return [...Array(elem.maxNumberCount)].map((number, index) => {
            return (
              <div key={`${elem.name}-${index}`} className={styles.fastPickerRow}>
                <p className={styles.fastPickerPickTxt}>
                  <FormattedMessage
                    id={line.isChosenInRow(elem.name, index) ? 'play.bet.text.info.good' : 'fast.numbers.to.pick'}
                    values={{ number: 1 }}
                  />
                </p>
                <div data-cy={'picker-' + index} className={styles.fastPickerNumbersWrapper}>
                  {renderNumbers(elem, index)}
                </div>
              </div>
            );
          });
        } else {
          return (
            <div key={`${elem.name}`} className={styles.fastPickerNumbersWrapper}>
              {renderNumbers(elem)}
            </div>
          );
        }
      });
    };

    return (
      <div className={styles.fastPickerContainer}>
        <div className={styles.fastPickerHeader}>
          <img
            onClick={onCancel}
            className={`${styles.fastPickerIcon} ${styles.fastPickerIconClose}`}
            src={config.CDN_URL + close}
            alt="close"
          />
          {logo && <img className={styles.fastPickerLogo} src={logo.url} alt="logo" />}
          <img
            onClick={(): void => onConfirmClick(line)}
            className={classNames(styles.fastPickerIcon, {
              [styles.fastPickerIconDisabled]: !line.isValid,
            })}
            src={line.isValid ? config.CDN_URL + confirmSuccess : config.CDN_URL + confirm}
            alt="confirm"
          />
        </div>

        <div className={styles.fastPickerBtnWrapper}>
          <NumberBalls line={line} />
          <div onClick={onQuickPickClick} className={styles.fastPickerQuickBtn}>
            {IS_EDITEC_THEME ? (
              <>
                <span className={styles.fastPickerQuickBtnIcon}></span>
                <FormattedMessage id="fast.btn.add" />
              </>
            ) : (
              <FormattedMessage id="fast.btn.quick.pick" />
            )}
          </div>
        </div>
        {/* <div>
        {IS_LTECH_FAST && (
          <div className={styles.fastPickerHeadTxt}>
            <FormattedMessage id={'play.cta2'} values={{number: line.leftToChooseWholeLine()}} />
          </div>
        )}
      </div> */}
        <div className={styles.fastPickerRows}>{renderSelections()}</div>
        <div className={styles.fastPickerBottom}>
          <div
            data-cy="confirm-button"
            className={classNames(styles.fastPickerConfirmBtn, {
              [styles.fastPickerConfirmBtnDisabled]: !line.isValid,
            })}
            onClick={(): void => onConfirmClick(line)}
          >
            <FormattedMessage id="fast.btn.confirm" />
          </div>
        </div>
      </div>
    );
  }
);

export default FastPickerSingular;
