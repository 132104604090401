exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2QvQoJxR5V-aKb6YgOPs-m{background-color:#c1d030;box-shadow:0 3px 0 #8d8d8d;color:#071a25;border-radius:5px !important;padding:.5em 0;margin:.5em 0;text-transform:uppercase;cursor:pointer;font-weight:bold;text-align:center;width:80%}._19fugBOZyuWpqmhBZkbGWy{background-color:#c1d030;color:#fff;box-shadow:0 3px 0 #8d8d8d}._30yY6XJHYBU6UkDT8K4te2{background-color:#e61621;color:#fff;box-shadow:0 3px 0 #8d8d8d}._3Ztn2bwmHk6pzQ9wlVXkpJ{background-color:#e4e4e4;color:#484848;box-shadow:0 3px 0 #8d8d8d}._3AgCg3uFvlT2DSkySTXXGi{background-color:#e4e4e4;color:#484848;box-shadow:0 3px 0 #8d8d8d;pointer-events:none}", ""]);

// Exports
exports.locals = {
	"defaultButton": "_2QvQoJxR5V-aKb6YgOPs-m",
	"confirmButton": "_19fugBOZyuWpqmhBZkbGWy",
	"dangerButton": "_30yY6XJHYBU6UkDT8K4te2",
	"inactiveButton": "_3Ztn2bwmHk6pzQ9wlVXkpJ",
	"disableButton": "_3AgCg3uFvlT2DSkySTXXGi"
};