import * as React from 'react';
import * as ReactDOM from 'react-dom';

import ConfigInterface from '../shared/interfaces/ConfigInterface';
import ConfigContext from '../shared/context/ConfigContext';
import App from './components/App';

/**
 * For development, load config from app config.
 */
if (process.env.NODE_ENV == 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  window.widgetConfig = require('../../../index.config').default;
  // eslint-disable-next-line no-console
  console.log('Widget running in dev config!');
} else {
  /**
   * For production, config should be supplied by url params.
   * Only basics should be set
   */
  window.widgetConfig = {
    elementName: 'myWidget',
    defaultLanguage: 'en',
    lang: 'en',
  };
}

import './assets/styles/index.scss';
import '../shared/assets/styles/index.scss';

declare global {
  interface Window {
    initWidget: CallableFunction;
    widgetConfig: ConfigInterface;
  }
}

function getURLParams(): ConfigInterface {
  const vars: ConfigInterface = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m: string, key: string, value: string): string {
    if (key === 'hiddenMode') {
      vars[key] = value.toLowerCase() == 'true';
    } else if (key === 'noMoneyMessage') {
      vars[key] = value.toLowerCase() == 'true';
    } else vars[key] = value;

    return '';
  });

  return vars;
}

function overrideKeys(currentConfig: ConfigInterface, newConfig: ConfigInterface): ConfigInterface {
  const defaultConfig: ConfigInterface = { ...currentConfig };

  for (const key in newConfig) {
    if (key === 'elementName') {
      return;
    }

    defaultConfig[key] = newConfig[key];
  }

  return defaultConfig;
}

function getProperConfig(currentConfig: ConfigInterface): ConfigInterface {
  const params: ConfigInterface = getURLParams();

  if (Object.keys(params).length !== 0) {
    return overrideKeys(currentConfig, params);
  }

  return currentConfig;
}

window.initWidget = function (config: ConfigInterface): void {
  const { Provider } = ConfigContext;
  const desiredConfig = getProperConfig(config);
  ReactDOM.render(
    <Provider value={desiredConfig}>
      <App />
    </Provider>,
    document.getElementById(config.elementName)
  );
};
