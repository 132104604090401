exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".WBNLHoEKyuEGwmlNLItdi{width:80%;max-width:22.5rem}._2HUoVhfN-hHb6uh2d8Vfck{display:flex;flex-direction:column;justify-content:center;align-items:center;padding:2em 1em;transition:width 1s}._1Xb4W_tOKiVlRmqGiut9ZS{width:92px;height:92px}._1RMxIQXb-ozfLdpOc_CSJC{font-size:1.5625rem;text-align:center}.LdPWW1_2I5Pf44Ab6j2vR{font-size:1.25rem;text-align:center}._1xxo0b1wrYbgyZvfrqihrj{font-size:1.0625rem;text-align:center}._1YieEMKp_KA_0aAGsUSbwK{width:100%}", ""]);

// Exports
exports.locals = {
	"modalContainer": "WBNLHoEKyuEGwmlNLItdi",
	"pendingModalContainer": "_2HUoVhfN-hHb6uh2d8Vfck",
	"successImg": "_1Xb4W_tOKiVlRmqGiut9ZS",
	"pendingTxt": "_1RMxIQXb-ozfLdpOc_CSJC",
	"successTxt": "LdPWW1_2I5Pf44Ab6j2vR",
	"additionalTxt": "_1xxo0b1wrYbgyZvfrqihrj",
	"hrWidth": "_1YieEMKp_KA_0aAGsUSbwK"
};