export default class AppConfigStore {
  userToken: string;
  gameId: string;
  brandId: string;
  language: string;

  setUserToken(userToken: string): void {
    this.userToken = userToken;
  }

  setGameId(gameId: string): void {
    this.gameId = gameId;
  }

  setLang(language: string): void {
    this.language = language;
  }
}
