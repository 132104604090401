import { HeadersInterface } from '../../shared/interfaces';
import { ApiDrawInterface } from '../../shared/interfaces/Api';
import { ApiParams } from '../../shared/interfaces/Api/ApiUtils';
import { DrawInterface, LastDrawsResponseInterface } from '../../shared/interfaces/GamesPicker';
import { camelCaseChanger } from '../../shared/utils';

class ApiDrawConnector {
  public api: ApiDrawInterface;

  public constructor(api: ApiDrawInterface) {
    this.api = api;
  }

  async getDraws(gameId: string): Promise<DrawInterface[]> {
    const response = await this.api.getDraws(gameId);
    if (!response.ok) {
      throw await response.json();
    }

    return await response.json();
  }

  async getLastDraws(gameId: string, params: ApiParams): Promise<LastDrawsResponseInterface> {
    const response = await this.api.getLastDraws(gameId, params);

    if (!response.ok) {
      throw await response.json();
    }
    const headers: HeadersInterface = {};

    response.headers.forEach((val, key) => {
      const newKey = camelCaseChanger(key);

      headers[newKey] = val;
    });

    return {
      headers,
      data: await response.json(),
    };
  }
}

export default ApiDrawConnector;
