import { observable, action, runInAction } from 'mobx';

import ApiUserBets from '../services/ApiUserBets';
import ApiUserBetsConnector from '../services/ApiUserBetsConnector';

import { ErrorMessage } from '../../shared/interfaces';
import {
  BetDetailsInterface,
  BetDetailsLinesInterface,
  BetDetailsLinesStoreInterface,
  BetDetailsPermStoreInterface,
  BetDetailsPermsInterface,
} from '../../shared/interfaces/GamesPicker';
import BetDetailsStoreInterface from '../../shared/interfaces/Stores/BetDetailsInterface';

import ResultEntity from '../entities/ResultEntity';
import ApiLines from '../services/ApiLines';
import ApiLinesConnector from '../services/ApiLinesConnector';

import AppConfigStore from './AppConfigStore';

//import { IS_RETAIL } from '../../shared/utils';

const api = new ApiUserBets();
const apiConnector = new ApiUserBetsConnector(api);
const apiLines = new ApiLines();
const apiLinesConnector = new ApiLinesConnector(apiLines);

class BetDetailsStore implements BetDetailsStoreInterface {
  @observable public isLoading = false;
  @observable public id: string | null = null;
  @observable public searchableId: string | null = null;
  @observable public globalIntId: number | string = null;
  @observable public secretCode: number | string = null;
  @observable public betDetails: BetDetailsInterface = null;
  @observable public error: ErrorMessage | null = null;
  @observable public lines: BetDetailsLinesStoreInterface = {
    isLoading: false,
    error: null,
    data: null,
  };
  @observable public permutations: BetDetailsPermStoreInterface = {
    isLoading: false,
    error: null,
    data: null,
  };
  @observable betResults: ResultEntity;
  @observable userSelection: ResultEntity[] = [];
  @observable winningsAmount: number;
  appConfig: AppConfigStore;

  constructor(appConfig: AppConfigStore) {
    this.appConfig = appConfig;
  }

  @action
  fetchBet = async (uuid: string): Promise<void> => {
    this.isLoading = true;
    this.error = null;
    this.clearBetDetails();

    /* eslint-disable max-len */
    try {
      const betDetails = await apiConnector.getSingleBet(uuid, this.appConfig.userToken, this.appConfig.gameId);
      this.id = betDetails.id;
      this.betDetails = betDetails;

      await this.fetchBetLines();
      await this.fetchBetPermutations();

      runInAction((): void => {
        this.isLoading = false;

        this.setResults();
      });
    } catch (error) {
      runInAction((): void => {
        this.isLoading = false;
        this.error = error;
      });
    }
  };

  @action
  fetchBetBySearchId = async (uuid: string): Promise<void> => {
    this.isLoading = true;
    this.error = null;
    this.clearBetDetails();
    /* eslint-disable max-len */
    try {
      const betDetails = await apiConnector.getSingleBet(uuid, this.appConfig.userToken, this.appConfig.gameId);
      this.id = betDetails.id;
      this.betDetails = betDetails;

      await this.fetchBetLines();
      await this.fetchBetPermutations();

      runInAction((): void => {
        this.isLoading = false;
        this.setResults();
      });
    } catch (error) {
      runInAction((): void => {
        this.isLoading = false;
        this.error = error;
      });
    }
  };

  @action
  fetchBetByKeySecret = async (globalIntId: string, secret: string): Promise<void> => {
    this.isLoading = true;
    this.error = null;
    this.clearBetDetails();

    /* eslint-disable max-len */
    try {
      const betDetails = await apiConnector.getSingleBetByGintidAndSecret(
        this.appConfig.gameId,
        this.appConfig.userToken,
        globalIntId,
        secret
      );
      this.id = betDetails.id;
      this.betDetails = betDetails;

      await this.fetchBetLines();
      await this.fetchBetPermutations();

      runInAction((): void => {
        this.isLoading = false;
        this.setResults();
      });
    } catch (error) {
      runInAction((): void => {
        this.isLoading = false;
        this.error = error;
        throw error;
      });
    }
  };

  @action
  fetchResults = async (lineId: string): Promise<string[]> => {
    try {
      const results = await apiConnector.getBetResults(
        this.getProperId(),
        this.appConfig.userToken,
        this.appConfig.gameId,
        lineId
      );
      return results;
    } catch (error) {
      return error;
    }
  };

  @action
  fetchPermResults = async (lineId: string): Promise<string[]> => {
    try {
      const results = await apiConnector.getBetResultsPerm(
        this.getProperId(),
        this.appConfig.userToken,
        this.appConfig.gameId,
        lineId
      );
      return results;
    } catch (error) {
      return error;
    }
    /* eslint-enable max-len */
  };

  @action
  fetchBetLines = async (): Promise<void> => {
    this.lines.isLoading = true;

    try {
      const lines = await apiLinesConnector.getBetLines(
        this.appConfig.userToken,
        this.getProperId(),
        this.appConfig.gameId
      );

      runInAction((): void => {
        this.lines.isLoading = false;
        this.lines.data = lines;
        this.betDetails.lines = lines;
        this.setUserSelection();
      });
    } catch (error) {
      runInAction((): void => {
        this.lines.isLoading = false;
        this.lines.error = error;
      });
    }
  };

  @action
  fetchBetPermutations = async (): Promise<void> => {
    try {
      /* eslint-disable max-len */
      const perms = await apiLinesConnector.getBetPermutations(
        this.appConfig.userToken,
        this.getProperId(),
        this.appConfig.gameId
      );
      /* eslint-enable max-len */

      runInAction((): void => {
        this.permutations.isLoading = false;
        this.permutations.data = perms;
        this.betDetails.permutations = perms;
        this.setUserPermsSelection();
      });
    } catch (error) {
      runInAction((): void => {
        this.permutations.isLoading = false;
        this.permutations.error = error;
      });
    }
  };

  getProperId(): string {
    if (this.searchableId) {
      return this.searchableId;
    }
    return this.id;
  }

  @action
  saveSearchableId = (id: string): void => {
    this.searchableId = id;
  };

  @action
  saveGlobalIntId = (globalIntId: number | string): void => {
    this.globalIntId = globalIntId;
  };

  @action
  saveSecretCode = (secretCode: number | string): void => {
    this.secretCode = secretCode;
  };

  @action
  /**
   * This has to be removed, and usersbets functionality should use fetchBet directly
   * @deprecated
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  saveBetId = (betId: string, globalIntId?: number, secretCode?: number): void => {
    this.fetchBet(betId);
  };

  @action
  clearBetDetails = (): void => {
    this.id = null;
    this.globalIntId = null;
    this.secretCode = null;
    this.betDetails = null;
    this.lines = {
      isLoading: false,
      error: null,
      data: null,
    };
    this.permutations = {
      isLoading: false,
      error: null,
      data: null,
    };
    this.error = null;
    this.userSelection = [];
    this.betResults = null;
  };

  setUserSelection = (): void => {
    const { data } = this.lines;

    if (!data || data.length === 0) {
      this.lines.isLoading = false;
      return;
    }

    data.forEach(async (elem: BetDetailsLinesInterface) => {
      let desiredWinningSelections: string[] = [];

      if (elem.won) {
        desiredWinningSelections = await this.fetchResults(elem.id);
      }

      this.userSelection.push(new ResultEntity(elem.selection, desiredWinningSelections));
    });
  };

  setUserPermsSelection = (): void => {
    const { data } = this.permutations;

    if (!data || data.length === 0) {
      return;
    }

    data.forEach(async (elem: BetDetailsPermsInterface) => {
      const { totalRealWinnings } = elem.cachedStats;
      const selection = { id: elem.id, asArray: { ...elem.asSimpleNumbersArray } };
      let winnings: string[] = [];

      if (totalRealWinnings) {
        try {
          winnings = await this.fetchPermResults(elem.id);
        } catch (e) {
          winnings = [];
        }
      }

      this.userSelection.push(new ResultEntity(selection, winnings, true, totalRealWinnings));
    });
  };

  setResults = (): void => {
    if (this.betDetails.gameDraw.wonBetSelection) {
      this.betResults = new ResultEntity(this.betDetails.gameDraw.wonBetSelection, []);
    }
  };

  @action
  payBet = async (): Promise<void> => {
    try {
      this.isLoading = true;
      const results = await apiConnector.payBet(
        this.betDetails.gameDraw.game.id,
        this.appConfig.userToken,
        this.betDetails.id
      );
      this.betDetails = results;
      this.isLoading = false;
      //return results;
    } catch (error) {
      this.isLoading = false;
      throw error;
    }
  };
}

export default BetDetailsStore;
