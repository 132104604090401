import Api from './Api';

import { ApiUserBetsInterface } from '../../shared/interfaces/Api';
import { UserBetsApiParamsInterface, UserBetsParamsInterface } from '../../shared/interfaces/GamesPicker';

import { getProperApiUrl, getUrlWithParams } from '../../shared/utils/apiUtils';

import {
  BETS,
  BETS_BY_GINTID,
  CANCEL,
  LINES,
  PAYOUT,
  PERMUTATIONS,
  WINNING_SELECTION_PARTS,
} from '../../shared/utils/constans/endPoints';

import config from '../../../../app.config.js';

class ApiUserBets extends Api implements ApiUserBetsInterface {
  apiUrl = config.APP_URL;

  cancelBet(gameId: string, token: string, betId: string): Promise<Response> {
    const properParams = {
      gameId,
      token,
    };
    const endPoint = `${BETS}/${betId}${CANCEL}`;
    const properApiUrl = getProperApiUrl(endPoint, properParams);

    return this.put(properApiUrl);
  }

  payoutBet(gameId: string, userToken: string, betId: string): Promise<Response> {
    const properParams = {
      gameId,
      token: userToken,
      betId,
    };
    const endPoint = `${BETS}/${betId}${PAYOUT}`;
    const properApiUrl = getProperApiUrl(endPoint, properParams);

    return this.put(properApiUrl);
  }

  getSingleBetByGintidAndSecret(gameId: string, token: string, globalIntId: number, secret: number): Promise<Response> {
    const properParams = {
      gameId,
      token,
    };
    const url = this.apiUrl + `/api/public/retail-widget/v1/bets-by-gintid-and-secret/${globalIntId}/${secret}`;
    const properUrl = getUrlWithParams(url, properParams);

    return this.get(properUrl);
  }

  getSingleBetByGintid(gameId: string, token: string, globalIntId: number): Promise<Response> {
    const properParams = {
      gameId,
      token,
    };
    const endPoint = `${BETS_BY_GINTID}/${globalIntId}`;
    const properUrl = getProperApiUrl(endPoint, properParams);

    return this.get(properUrl);
  }

  getUserBets({ userToken, page, perPage, showActiveBets, gameId }: UserBetsParamsInterface): Promise<Response> {
    const properParams = {
      page,
      perPage,
      gameId,
      token: userToken,
      'search[isActive][isActive]': showActiveBets,
    };
    const properApiUrl = getProperApiUrl<UserBetsApiParamsInterface>(BETS, properParams);

    return this.get(properApiUrl);
  }

  getSingleBet(id: string, userToken: string, gameId: string): Promise<Response> {
    const properParams = {
      gameId,
      token: userToken,
    };
    const endPoint = `${BETS}/${id}`;
    const properApiUrl = getProperApiUrl(endPoint, properParams);

    return this.get(properApiUrl);
  }

  getBetResults(id: string, userToken: string, gameId: string, lineId: string): Promise<Response> {
    const properParams = {
      gameId,
      token: userToken,
    };
    const endPoint = `${BETS}/${id}${LINES}/${lineId}${WINNING_SELECTION_PARTS}`;
    const properApiUrl = getProperApiUrl(endPoint, properParams);

    return this.get(properApiUrl);
  }

  getBetResultsPerm(id: string, userToken: string, gameId: string, permutationId: string): Promise<Response> {
    const properParams = {
      gameId,
      token: userToken,
    };
    const endPoint = `${BETS}/${id}${PERMUTATIONS}/${permutationId}${WINNING_SELECTION_PARTS}`;
    const properApiUrl = getProperApiUrl(endPoint, properParams);

    return this.get(properApiUrl);
  }
}

export default ApiUserBets;
