import React, { useCallback, useContext, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';

import FastPickerGameContainer from './FastPickerGameContainer';
import NumberPickerLines from '../components/GamesPicker/Game/NumberPickerLines';
import DrawTimeSelection from '../components/GamesPicker/Game/DrawTimeSelection';
import MobileOrderSummary from '../components/GamesPicker/Game/MobileOrderSummary';

import { RootStoreContext } from '../../lobby-engine/stores/RootStore';
import { DrawsStore } from '../../lobby-engine/stores';
import LinesStore from '../../lobby-engine/stores/LinesStore';

import useMobileWidth from '../../shared/hooks/useMobileWidth';

import { constans, IS_LTECH_FAST } from '../../shared/utils';

import { DrawInterface, GameChancesDropdownInterface } from '../../shared/interfaces/GamesPicker';

import styles from '../components/GamesPicker/Game/NumberPicker/NumberPicker.scss';

interface NumberPickerContainerProps {
  fastPicker: boolean;
  drawSelectionRender: boolean;
  gameId: string;
  draws: DrawsStore;
  lines: LinesStore;
}

const NumberPickerContainer: React.FC<NumberPickerContainerProps> = observer((props: NumberPickerContainerProps) => {
  const { fastPicker, drawSelectionRender, gameId, lines, draws } = props;
  const { game } = useContext(RootStoreContext);
  const isMobile: boolean = useMobileWidth();
  const lotteryStructure = game.gameStructure.data.lottery.structure;
  const gameCreationData = game.getGameDataCreator();
  const dropdownRender: boolean = constans.lotteryDropdown.indexOf(lotteryStructure.name) != -1;
  const additionalParameter = useMemo(
    () =>
      gameCreationData.integerRange
        ? { value: gameCreationData.integerRange.end, name: gameCreationData.additionalParamName }
        : { value: null, name: null },
    [gameCreationData.additionalParamName, gameCreationData.integerRange]
  );
  const ltechFast: boolean = IS_LTECH_FAST && isMobile;
  const isFastPicker: boolean = fastPicker || ltechFast;

  useEffect(() => {
    if (!isFastPicker && lines.linesList.length === 0) {
      lines.addLine();
    }

    // return ((): void => lines.clearLines());
  }, [lines, isFastPicker]);

  const onDrawSelect = useCallback(
    (draw: DrawInterface): void => {
      draws.addToSelectedDraw(draw);
      draws.disableNumberOfDraws(draws.getFilteredDrawsCount());
      draws.changeDrawsNumber(0);
    },
    [draws]
  );

  const getDropdownOptions = useCallback((): GameChancesDropdownInterface[] | null => {
    if (!gameCreationData.integerRange) {
      return null;
    }

    const length = gameCreationData.integerRange.end - gameCreationData.integerRange.start + 1;
    const dropdownArray: GameChancesDropdownInterface[] = [...Array(length)].map((elem: undefined, index: number) => {
      const value = gameCreationData.integerRange.start + index;

      return {
        value,
        label: `${gameCreationData.additionalParamName} ${value}`,
        name: gameCreationData.additionalParamName,
      };
    });

    return dropdownArray.sort((a: GameChancesDropdownInterface, b: GameChancesDropdownInterface) => b.value - a.value);
  }, [gameCreationData.integerRange, gameCreationData.additionalParamName]);

  const renderOrderSummary = (): JSX.Element => {
    if (isMobile && !isFastPicker) {
      return <MobileOrderSummary pickedData={lines.linesList} />;
    }

    return null;
  };

  const renderFastPicker = (): JSX.Element => {
    if (isFastPicker) {
      return (
        <FastPickerGameContainer
          {...{ lines, gameId, isMobile, fastPicker, additionalParameter }}
          logo={game.logo}
          isInstant={game.isInstant}
        />
      );
    }

    return null;
  };

  const renderPicker = (): JSX.Element => {
    if (!isFastPicker) {
      return (
        <NumberPickerLines
          {...{
            dropdownRender,
            lines,
          }}
          dropdownOptions={getDropdownOptions()}
        />
      );
    }

    return null;
  };

  return (
    <div className={styles.numberPickerContainer}>
      {drawSelectionRender && (
        <DrawTimeSelection draws={draws.drawsPerDaySorted} selectedDraw={draws.selectedDraw} {...{ onDrawSelect }} />
      )}
      {renderFastPicker()}
      {renderPicker()}
      {renderOrderSummary()}
    </div>
  );
});

export default NumberPickerContainer;
