import React, { useCallback, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { format } from 'date-fns';
import Instructions from '../components/GamesPicker/Information/Instructions';
import GameResults from '../components/GamesPicker/Information/GameResults';

import { getTimezone } from '../../shared/utils';

import { ConfigContext } from '../../shared/context';
import { RootStoreContext } from '../../lobby-engine/stores/RootStore';
interface InfoContainerProps {
  onClose: CallableFunction;
  onPageClose: CallableFunction;
  onPageChange(page: string): void;
  gameId: string;
}

const InfoContainer: React.FC<InfoContainerProps> = observer((props: InfoContainerProps) => {
  const { onClose, onPageClose, gameId, onPageChange } = props;
  const { game, instruction, i18n } = useContext(RootStoreContext);
  const config = useContext(ConfigContext);
  const [date, setDate] = useState<string>('');
  const [timezone, setTimezone] = useState<string>(getTimezone(''));
  const img = game.logo;
  const error = instruction.instructionPage.error;
  const isLoading = instruction.instructionPage.isLoading;
  const newInstructionPage = i18n.i18nData ? i18n.i18nData.info : '';
  const lastDrawDate = instruction.lastDrawDate.data;
  const isErrorLastDrawDate = instruction.lastDrawDate.error; // ERROR IN REQUEST OF LAST DRAW DATE
  const isErrorLastDraw = instruction.lastDraws.error; // ERROR IN REQUEST OF LAST DRAW RESULTS
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [isError, setIsError] = useState<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [lastDraws, setLastDraws] = useState<any>(null);
  const [isLoadingResults, setLoadingResults] = useState<boolean>(true);

  useEffect(() => {
    i18n.getI18n(gameId, config.lang);
  }, [gameId, config, i18n]);

  useEffect(() => {
    if (lastDrawDate.date === '') {
      instruction.getLastDrawDate(gameId).then(() => {
        if (lastDrawDate.date === '') {
          //last draw date is empty, so show not results message
          setLoadingResults(false); //set loading to false to show message
          setLastDraws([]);
        }
      });
    }

    return (): void => instruction.resetInstructionStore();
  }, [instruction, gameId, timezone]);

  const loadLastResults = (pDate: string, PAGE = 1): void => {
    const PER_PAGE = 10;
    setLoadingResults(true);
    setLastDraws(null);
    instruction.getLastDraws(gameId, pDate, timezone, PAGE, PER_PAGE).then(() => {
      setLastDraws(instruction.lastDraws.data);
      setLoadingResults(instruction.lastDraws.isLoading);
    });
  };

  useEffect(() => {
    if (lastDrawDate.date !== '') {
      const dateFormat = format(new Date(lastDrawDate.date), 'yyyy-MM-dd');
      loadLastResults(dateFormat);
    }
  }, [lastDrawDate.date]);

  useEffect(() => {
    if (date !== '') {
      loadLastResults(date);
    }
  }, [date]);

  useEffect(() => {
    const error = isErrorLastDrawDate ? isErrorLastDrawDate : isErrorLastDraw ? isErrorLastDraw : null;
    setIsError(error);
  }, [isErrorLastDraw, isErrorLastDrawDate]);

  const fetchLatestResults = useCallback((newDate: string): void => {
    const newTimezone = getTimezone(newDate);
    setDate(newDate);
    setTimezone(newTimezone);
  }, []);

  const onPaginationPageChange = (page: number): void => {
    const dResult = date == '' ? lastDrawDate.date : date; // comparing component date from selects
    loadLastResults(dResult, page);
  };

  return (
    <React.Fragment>
      <Instructions
        instructionPage={newInstructionPage}
        {...{ onClose, onPageClose, onPageChange, isLoading, error, img }}
      />
      <GameResults
        lastDraws={lastDraws}
        drawsHeaders={instruction.lastDraws.headers}
        onDateChange={fetchLatestResults}
        isLoading={isLoadingResults}
        isError={isError}
        onPageChange={onPaginationPageChange}
      />
    </React.Fragment>
  );
});

export default InfoContainer;
