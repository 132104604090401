import { TopBarItemInterface } from '../../interfaces/GamesPicker/TopBarInterface';

import pageNames from '../pageNames';
import { isBetsPageActive } from '../topBar';

export const USER_BETS_PAGES = [
  pageNames.USER_BETS,
  pageNames.USER_BETS_ACTIVE,
  pageNames.USER_BETS_HISTORY,
  pageNames.USER_BETS_SINGLE,
];

export const DEFAULT_LOBBY_TOP_BAR: TopBarItemInterface[] = [
  {
    icon: 'lottery',
    displayId: 'navbar.play',
    value: pageNames.GAME_PLAY,
  },
  {
    icon: 'info',
    displayId: 'navbar.learn',
    value: pageNames.GAME_INFORMATION,
  },
  {
    icon: 'history',
    displayId: 'navbar.bets',
    value: pageNames.USER_BETS,
  },
];

export const RETAIL_TOP_BAR: TopBarItemInterface[] = [
  {
    displayId: 'navbar.search.bet',
    value: pageNames.USER_BETS,
    isActive: (pageName: string): boolean => isBetsPageActive(pageName),
  },
  {
    displayId: 'navbar.scan.slip',
    value: pageNames.SCAN_SLIP,
  },
  {
    displayId: 'navbar.draw_results',
    value: pageNames.RETAIL_DRAW_RESULTS,
  },
];
