import { observable, action } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import LinesStore from '../../stores/LinesStore';
import StakeStore from '../../stores/StakeStore';

import { ImageInterface } from '../../../shared/interfaces';
import LineCostStore from '../../stores/LineCostStore';
import { DrawsStore } from '../../stores';
import TicketStore from '../../stores/TicketStore';
import LineEntity from '../LineEntity';
import { TicketPlainInterface } from '../../../shared/interfaces/Stores';
import { LotteryDataInterface } from '../../../shared/interfaces/Stores/LotteriesStoreInterface';

export default class LotteryEntity {
  id: string;
  gameId: string;
  name: string;
  image: ImageInterface;
  lines: LinesStore;
  draws: DrawsStore;
  lineCost: LineCostStore;
  stakes: StakeStore;
  ticket: TicketStore;
  @observable isConfirmed = false;

  constructor(lotteryData: LotteryDataInterface) {
    this.id = uuidv4();
    this.gameId = lotteryData.id;
    this.name = lotteryData.name;
    this.image = lotteryData.image;

    this.lines = new LinesStore();
    this.lines.setLinesCountToAdd(lotteryData.lineCountToAdd);
    this.lines.setMaxLinesValue(lotteryData.maxLinesPerBet);
    this.lines.setLotteryName(lotteryData.lotteryStructureName);
    this.lines.setSelectionParts(lotteryData.selectionParts);
    this.lines.setPermutationsEnabled(lotteryData.permutationsEnabled);

    this.lineCost = new LineCostStore();
    this.lineCost.configure(lotteryData.betLineBasePrice, lotteryData.betLineAdditionalPrices);

    this.draws = new DrawsStore();

    this.stakes = new StakeStore(this.lineCost);
    this.stakes.setRawStakes(lotteryData.stakes);
    this.stakes.setStakesValues();
    this.stakes.getDefaultStake();

    this.ticket = new TicketStore(this.stakes, this.lines, this.draws, this.lineCost);
    this.ticket.setTicketSelection(lotteryData.ticketSelection);
  }

  get isValid(): boolean {
    return this.lines.linesList.some((elem: LineEntity) => elem.isValid);
  }

  @action
  confirmLottery(): void {
    this.isConfirmed = true;
  }

  toPlain(): TicketPlainInterface {
    return this.ticket.toPlain();
  }
}
