import React from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';

import FastPickerLine from '../FastPickerLine';

import { LastDrawsStoreInterface } from '../../../../../shared/interfaces/GamesPicker/InstructionsInterface';
import { DrawInterface } from '../../../../../shared/interfaces/GamesPicker';

import { classNames, IS_EDITEC_THEME } from '../../../../../shared/utils';

import styles from './FastPickerLines.scss';
import lineStyles from '../FastPickerLine/FastPickerLine.scss';
import LinesStore from '../../../../../lobby-engine/stores/LinesStore';

interface FastPickerLinesProps {
  lines: LinesStore;
  isMobile: boolean | null;
  lastResults: LastDrawsStoreInterface;
  isInstant?: boolean;
  onLineClick(lineId: string): void;
  onRefreshBetClick(lineId: string): void;
  onNewEmptyLine(): void;
  onAddNewLine(): void;
  onRemoveLine(id: string): void;
}

const FastPickerLines: React.FC<FastPickerLinesProps> = observer((props: FastPickerLinesProps) => {
  const {
    onLineClick,
    onRefreshBetClick,
    onNewEmptyLine,
    onAddNewLine,
    onRemoveLine,
    lastResults,
    isMobile,
    lines,
    isInstant,
  } = props;

  const renderLines = (): JSX.Element[] => {
    return lines.linesList.map((line, index: number) => {
      if (!line.isValid) {
        return null;
      }

      return (
        <FastPickerLine
          key={`fast-line-${index}`}
          {...{ onLineClick, onRefreshBetClick, onRemoveLine, line, isInstant }}
        />
      );
    });
  };

  const renderResults = (): JSX.Element => {
    if (!lastResults.data) {
      return null;
    }

    return (
      <div className={styles.lastResults}>
        <div className={styles.lastResultsHead}>
          <p className={styles.lastResultsHeadTxt}>
            <FormattedMessage id={'fast.picker.results.p1'} />
          </p>
        </div>
        <div className={styles.lastResultsWrapper}>
          {lastResults.data.slice(0, 3).map((elem: DrawInterface, index: number) => {
            return (
              <div
                key={elem.id}
                className={classNames(styles.lastResultsSingleResult, {
                  [styles.lastResultsSingleResultMiddle]: index === 1,
                })}
              >
                <p className={styles.lastResultsDate}>{dayjs(elem.drawDatetime).format('DD MMM YYYY HH:mm')}</p>
                <div className={styles.lastResultsNumbers}>
                  {elem.wonBetSelection.asArray.main.map((elem: number, index: number) => {
                    return (
                      <div key={index} className={`${lineStyles.fastLineNumber} ${styles.lastResultsNumber}`}>
                        {elem}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderManualBtnContent = (): JSX.Element => {
    if (IS_EDITEC_THEME) {
      return (
        <>
          <span className={styles.fastLinesManualIcon}></span>
          <FormattedMessage id="fast.btn.manual.pick" />
        </>
      );
    }

    return <span className={styles.fastLinesAddIcon}></span>;
  };

  return (
    <div className={styles.fastLines}>
      <div className={styles.fastLinesWrapper}>
        {renderLines()}
        <div
          className={classNames(styles.fastLinesAddWrapper, {
            [styles.fastLinesAddWrapperDisabled]: lines.areLinesMax,
          })}
        >
          <div data-testid="button-quickpick" className={styles.fastLinesAddTxt} onClick={onAddNewLine}>
            <span className={styles.fastLinesAddPlus}>
              <span className={styles.fastLinesAddPlusIcon}></span>
            </span>
            <FormattedMessage id={'fast.btn.add'} />
          </div>
          {IS_EDITEC_THEME ? (
            <div className={styles.fastLinesAddOr}>
              <FormattedMessage id="fast.line.editec.or" />
            </div>
          ) : null}
          <div data-cy="manualpick-button" onClick={onNewEmptyLine} className={styles.fastLinesAdd}>
            {renderManualBtnContent()}
          </div>
        </div>
      </div>
      {!isMobile && renderResults()}
    </div>
  );
});

export default FastPickerLines;
