import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import ModalConnector from '../../../shared/components/Modals/ModalConnector';
import Modal from '../../../shared/components/Modals/Modal';

import { SUCCESS_MODAL } from '../../../shared/utils/modalNames';

import config from '../../../../../app.config';

import success from '../../assets/images/success.png';

import styles from './Modals.scss';

interface SuccessModalProps {
  isOpen: boolean;
  data: null;
  onClose(): void;
}

const SuccessModal: React.FC<{}> = (): JSX.Element => {
  return (
    <ModalConnector name={SUCCESS_MODAL}>
      {({ isOpen, onClose }: SuccessModalProps): JSX.Element => {
        return (
          <Modal {...{ onClose, isOpen }} contentOutside>
            <div className={styles.gameSummaryModal}>
              <img src={config.CDN_URL + success} className={styles.gameSummaryModalIcon} alt="icon" />
              <p className={styles.gameSummaryModalMainText}>
                <FormattedHTMLMessage id={'play.modal.success.p1'} />
              </p>
              <hr />
              <div>
                <p className={styles.gameSummaryModalSecondaryText}>
                  <a className={styles.gameSummaryModalCallToAction} onClick={onClose}>
                    <FormattedMessage id={'play.modal.btn'} />
                  </a>
                </p>
              </div>
            </div>
          </Modal>
        );
      }}
    </ModalConnector>
  );
};

export default SuccessModal;
