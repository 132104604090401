import React from 'react';
import { classNames } from '../../../../../shared/utils';

import styles from './NumberButton.scss';

interface NumberButtonProps {
  isActive: boolean;
  btnNumber: number;
  width: number;
  className?: string;
  activeClassName?: string;
  onNumberClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
}

const NumberButton: React.FC<NumberButtonProps> = (props: NumberButtonProps) => {
  const { btnNumber, isActive, onNumberClick, width, className, activeClassName } = props;

  return (
    <div
      className={classNames(`${styles.number} ${className} ${styles[`numberW${width}`]}`, {
        [activeClassName]: isActive,
      })}
      onClick={onNumberClick}
    >
      {btnNumber}
    </div>
  );
};

NumberButton.defaultProps = {
  className: '',
  activeClassName: styles.numberActive,
};

export default NumberButton;
