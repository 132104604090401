import { observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import { IntegerSelection } from './selections/IntegerSelection';
import { RangeSelection } from './selections/RangeSelection';

import { ClassSerializer } from '../../shared/interfaces/ClassSerializer';
import {
  LinePlainInterface,
  SelectionPlainInterface,
  PermSelectionPlainInterface,
} from '../../shared/interfaces/Stores';
import { calculateCombination } from '../../shared/utils/combinations';

/**
 * Handles all global logic of single line lifecycle.
 * Contains mutiple possible selections of line
 *
 * Supports games with subtypes, although in nasty way.
 */
export default class LineEntity implements ClassSerializer {
  // Map of selections available in line
  @observable selections: Map<string, IntegerSelection | RangeSelection>;
  // Unique ID of line
  id: string;
  // Name of lottery for which line was create, coz why not.
  lotteryName: string;
  // Name of selection that should be treated as special sub-type. Null if no subtype is availble.
  subType: string | null = null;
  permutationsEnabled: boolean;
  isSingleLine = false;
  /**
   *
   * @param lotteryName - Name of lottery that created the line
   * @param selectionParts - Array of selection parts aavailabe for lottery as given by API
   */
  constructor(
    lotteryName: string,
    selectionParts: Array<IntegerSelection | RangeSelection>,
    permutationsEnabled: boolean
  ) {
    this.id = uuidv4();
    this.lotteryName = lotteryName;
    this.selections = new Map<string, IntegerSelection | RangeSelection>();
    this.permutationsEnabled = permutationsEnabled;

    selectionParts.forEach((sel) => {
      if (sel.type.includes('range')) {
        this.selections.set(sel.name, new RangeSelection(sel));
      }
      if (sel.type === 'integer') {
        this.selections.set(sel.name, new IntegerSelection(sel));
      }
      // Chance/Turbo are wierd subtypes
      if (sel.name == 'turbo') {
        this.subType = sel.name;
        this.getSelection('turbo').isSubType = true;
        this.setSubType(sel.range.end);
      }
      if (sel.name == 'chance') {
        this.subType = sel.name;
        this.getSelection('chance').isSubType = true;
        this.setSubType(sel.range.end);
      }
      if (sel.name === 'nap') {
        this.subType = sel.name;
        this.getSelection('nap').isSubType = true;
        this.setSubType(sel.range.end);
      }
      if (sel.name === 'xtra') {
        this.subType = sel.name;
        this.getSelection('xtra').isSubType = true;
        this.setSubType(sel.range.start);
      }
    });
  }

  /**
   * Makes this line exactly the same as existing entity
   * @param existing
   */
  copyFromExisting(existing: LineEntity): void {
    this.id = existing.id;
    this.lotteryName = existing.lotteryName;
    this.subType = existing.subType;

    existing.selections.forEach((sel) => {
      this.selections.set(sel.name, sel.copy());
    });
  }

  /**
   * Gets selection object by name of it.
   * @param selection
   */
  private getSelection(selection: string): IntegerSelection | RangeSelection {
    const sel = this.selections.get(selection);
    if (!sel) {
      throw new Error(`Selection ${selection} does not exist!`);
    }
    return sel;
  }

  get selectionsArray(): Array<IntegerSelection | RangeSelection> {
    return Array.from(this.selections.keys()).map((elem: string) => this.selections.get(elem));
  }

  get realLinesCount(): number {
    if (this.isValid) {
      if (!this.isPermutationOn) {
        return 1;
      }

      const selection = this.getSelection('main');

      return calculateCombination(selection.numbers.length, selection.maxNumberCount, selection.allowNonUniqueNumbers);
    }

    return 0;
  }

  /**
   * Returns if line supports subtypes.
   */
  get hasSubType(): boolean {
    return this.subType != null;
  }

  /**
   * Checks if number is already picked in given selection
   *
   * @param value
   * @param selection
   */
  isPicked(value: number, selection = 'main'): boolean {
    return this.getSelection(selection).isPicked(value);
  }

  /**
   * @deprecated - should be changed to getter by selection name
   */
  maxPickCount(selection = 'main'): number {
    return this.getSelection(selection).maxNumberCount;
  }

  /**
   * @deprecated - should be changed to getter by selection name
   */
  minPickCount(selection = 'main'): number {
    return this.getSelection(selection).minNumberCount;
  }

  /**
   * Gets range of main selection
   * @deprecated - should be changed to range by selection name
   */
  range(selection = 'main'): { start: number; end: number } {
    return this.getSelection(selection).range;
  }

  /**
   * Returns numbers array for given selection
   * @param selection
   */
  numbers(selection = 'main'): number[] {
    return this.getSelection(selection).numbers;
  }

  /**
   * @deprecated - should be changed to getter by selection name
   */
  main(selection = 'main'): number[] {
    return this.getSelection(selection).numbers;
  }

  /**
   * Adds single number to given selection
   * @param pickedNumber
   * @param selection
   */
  addNumber(pickedNumber: number, selection = 'main'): void {
    if (this.isPicked(pickedNumber, selection) && this.isSingleLine) {
      this.isSingleLine = false;
    }

    this.getSelection(selection).addNumber(pickedNumber);
  }

  setNumberAtIndex(pickedNumber: number, index: number, selection = 'main'): void {
    this.getSelection(selection).setNumberAtindex(pickedNumber, index);
  }

  turnPermutation = (): void => {
    this.selections.forEach((sel) => {
      sel.turnPermutationOn();
    });
  };

  setSingleLine(): void {
    this.isSingleLine = true;
  }

  /**
   * Clears given selection numbers
   * @param selection
   */
  clearSelection(selection = 'main'): void {
    this.getSelection(selection).clearSelection();
  }

  /**
   * Adds mutiple numbers to selection
   * @param pickedNumbers
   * @param selection
   */
  addNumbers(pickedNumbers: number[], selection = 'main'): void {
    this.getSelection(selection).addNumbers(pickedNumbers);
  }

  /**
   * Returns true if line is valid (all selections are valid)
   */
  get isValid(): boolean {
    let isValid = true;
    this.selections.forEach((sel) => {
      return (isValid = isValid && sel.isValid);
    });
    return isValid;
  }

  get isPermutationOn(): boolean {
    let isPermOn = true;
    this.selections.forEach((sel) => {
      isPermOn = isPermOn && sel.isPermutationOn;
    });

    return isPermOn;
  }

  /**
   * Returns number of left numbers to be selected yet
   * @param selection
   */
  public leftToChoose(selection = 'main'): number {
    return this.getSelection(selection).leftToChoose;
  }

  public leftToChooseWholeLine(): number {
    let ltc = 0;
    this.selections.forEach((sel) => {
      if (sel.name != this.subType) {
        ltc = ltc + sel.leftToChoose;
      }
    });
    return ltc;
  }

  public isChosenInRow(selection = 'main', row: number): boolean {
    return Boolean(this.getSelection(selection).numbers[row] !== undefined);
  }

  /**
   * Clears all selections.
   * Except subtype selection.
   */
  public clearLine(): void {
    this.isSingleLine = false;

    this.selections.forEach((sel) => {
      if (sel.name != this.subType) {
        sel.clearSelection();
      }

      sel.turnPermutationOff();
    });
  }

  /**
   * Randomises numbers in all selections
   * Except subtype selction.
   */
  public randomizeChoice(): void {
    this.isSingleLine = true;

    this.selections.forEach((sel) => {
      if (sel.name != this.subType) {
        sel.randomizeChoice();
      }
    });
  }

  //TODO: Validation ?
  public toPlain(): LinePlainInterface | PermSelectionPlainInterface {
    if (this.isPermutationOn) {
      return this.toPlainPerm();
    }
    // eslint-disable-next-line no-console
    return this.toPlainNormal();
  }

  public toPlainNormal(): LinePlainInterface {
    let numbers: { [k: string]: number } = {};

    this.selections.forEach((element) => {
      numbers = { ...numbers, ...(element.toPlain() as SelectionPlainInterface) };
    });
    return { selection: numbers };
  }

  public toPlainPerm(): PermSelectionPlainInterface {
    let permutations: PermSelectionPlainInterface = {};
    this.selections.forEach((element) => {
      if (!element.isPermutationOn) {
        permutations = null;
        return;
      }
      permutations[element.name] = element.toPlainPerm();
    });
    return permutations;
  }

  /**
   * Fuckin walk of shame of mine.
   * This should be move to external handlers or something.
   */

  /**
   * Sets subtype (special selection) to desired value.
   * @param value
   */
  public setSubType(value: number): void {
    if (!this.hasSubType) {
      throw new Error('This has no subtypes, check it with `hasSubtype` before!');
    }
    // Love those ifs!
    if (this.subType == 'chance' || this.subType === 'nap') {
      this.getSelection('main').maxNumberCount = value;
      this.getSelection('main').minNumberCount = value;
      this.getSelection('main').numbers = new Array<number>(value);
    }
    return this.getSelection(this.subType).addNumbers([value]);
  }
  /**
   * Returns selection value that was set as subtype.
   */
  public getSubType(): number {
    if (!this.hasSubType) {
      throw new Error('This has no subtypes, check it with `hasSubtype` before!');
    }
    return this.getSelection(this.subType).numbers[0];
  }
}
