import { action, observable, runInAction } from 'mobx';

import DataApi from '../entities/DataApi';
import ApiLobby from '../services/ApiLobby';
import ApiLobbyConnector from '../services/ApiLobbyConnector';

import { VariantInterface } from '../../shared/interfaces/Lobby/VersionInterface';
import { LobbyDataInterface } from '../../shared/interfaces/Lobby/LobbyInterface';

const api = new ApiLobby();
const apiConnector = new ApiLobbyConnector(api);

class LobbyStore extends DataApi<LobbyDataInterface> {
  private lang = 'en';
  @observable defaultGameId: string | null = null;
  @observable variant: VariantInterface = null;
  @observable games: string[] = [];

  @action
  getLobbies = async (lobbyId: string): Promise<void> => {
    this.isLoading = true;

    try {
      const lobbies = await apiConnector.getLobby(lobbyId);

      runInAction(() => {
        this.data = lobbies.data;
        this.isLoading = false;
        this.variant = this.data.variants.find((elem: VariantInterface) => elem.language === this.lang.toUpperCase());
        this.updateGames();
      });
    } catch (err) {
      this.error = err;
      this.isLoading = false;
    }
  };

  /**
   * Returns ID's of all games avaibale in current lobby
   */
  updateGames(): void {
    const ret = [];
    if (this.variant) {
      for (const section of this.variant.lobbySections) {
        ret.push(section.button.link);
        // eslint-disable-next-line no-console
      }
    }
    this.defaultGameId = ret[0];
    this.games = ret;
  }
  getGames(): string[] {
    return this.games;
  }

  /**
   * Returns default (first) game available in lobby.
   * Used mostly in retail to get gameId before any specific game is selected.
   * @returns
   */
  getDefaultGame(): string {
    return this.defaultGameId;
  }

  setLanguage(lang: string): void {
    this.lang = lang;
  }
}

export default LobbyStore;
