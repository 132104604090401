import React from 'react';

import ModalConnector from '../../../shared/components/Modals/ModalConnector';
import { Modal } from '../generic';

import { GAME_MODAL } from '../../../shared/utils/modalNames';
import { ModalInterface } from '../../../shared/interfaces/Lobby/ModalsInterface';

import GamesPickerContainer from '../../containers/GamesPickerContainer';

interface GamePickerModalInterface extends ModalInterface {
  data: {
    onClose: () => void;
  };
}

const GamePickerModal: React.FC<{}> = () => {
  return (
    <ModalConnector name={GAME_MODAL}>
      {({ isOpen, onClose, data }: GamePickerModalInterface): JSX.Element => {
        const properClose = (): void => {
          data.onClose();
          onClose();
        };

        return (
          <Modal onClose={properClose} {...{ isOpen }} contentOutside size="large">
            <GamesPickerContainer onClose={properClose} />
          </Modal>
        );
      }}
    </ModalConnector>
  );
};

export default GamePickerModal;
