import React from 'react';
import { ErrorMessage } from '../../interfaces';
import ownStyles from './InlineError.scss';
import error from '../../assets/images/error.png';
import { FormattedMessage } from 'react-intl';
import { classNames } from '../../utils';
import config from '../../../../../app.config.js';

interface InlineErrorProps {
  error: ErrorMessage;
  theme?: 'default' | 'print';
  customMessageTranslationId?: string;
}

const InlineError: React.FC<InlineErrorProps> = (props: InlineErrorProps) => {
  const theme = props.theme ? props.theme : 'default';
  return (
    <div
      className={classNames(`${ownStyles.root}`, {
        [ownStyles.print]: theme == 'print',
      })}
    >
      <div className={ownStyles.icon}>
        <img src={config.CDN_URL + error} alt="error Icon" />
      </div>
      <div className={ownStyles.messageContainer}>
        <div className={ownStyles.errorCode}>
          {props.error.statusCode}: {props.error.code}
        </div>
        <div className={ownStyles.message}>
          {' '}
          {props.customMessageTranslationId ? (
            <FormattedMessage id={props.customMessageTranslationId} />
          ) : (
            <>{props.error.message}</>
          )}
        </div>
      </div>
    </div>
  );
};

export default InlineError;
