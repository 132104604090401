import Api from './Api';

import config from '../../../../app.config.js';

import { getProperApiUrl } from '../../shared/utils/apiUtils';

class ApiPlayerBalance extends Api {
  apiUrl = config.APP_URL;

  getPlayerBalance(gameId: string, userToken: string): Promise<Response> {
    const params = {
      token: userToken,
      gameId,
    };
    const url = getProperApiUrl(`/balance-for-game/${gameId}`, params);

    return this.get(url);
  }

  getBalanceWallet(playerId: string): Promise<Response> {
    return this.get(`${config.IMPREXIS_GAMING_WALLET_API}/api/wallet?pid=${playerId}`);
  }
}

export default ApiPlayerBalance;
