import Api from './Api';

import ApiInstantGameInterface from '../../shared/interfaces/Api/ApiInstantTickets';

import { GameBetBodyInterface } from '../../shared/interfaces/GamesPicker';

import config from '../../../../app.config.js';

class ApiInstantGame extends Api implements ApiInstantGameInterface {
  apiUrl = config.APP_URL;

  postInstantTickets(body: GameBetBodyInterface, gameId: string, token: string): Promise<Response> {
    /* eslint-disable max-len */
    return this.post(`${this.apiUrl}/api/public/widget/v1/instant-games/${gameId}/instant-bet?token=${token}`, body);
    /* eslint-enable max-len */
  }
}

export default ApiInstantGame;
