import React, { useContext } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import ModalConnector from '../../../shared/components/Modals/ModalConnector';
import { Modal } from '../generic';

import { ERROR_MODAL } from '../../../shared/utils/modalNames';
import getErrorCallback from '../../../shared/utils/getErrorCallback';

import { ConfigContext } from '../../../shared/context';

import { ErrorModalInterface } from '../../../shared/interfaces/Lobby/ModalsInterface';

import config from '../../../../../app.config';

import error from '../../assets/images/error.png';

import styles from './Modals.scss';

interface ErrorModalProps {
  isOpen: boolean;
  data: ErrorModalInterface;
  onClose(): void;
}

const ErrorModal: React.FC<{}> = () => {
  const configContext = useContext(ConfigContext);
  return (
    <ModalConnector name={ERROR_MODAL}>
      {({ isOpen, data, onClose }: ErrorModalProps): JSX.Element => {
        const { errorData, closePicker, code } = data;
        const errorCb = getErrorCallback(code, errorData.slug, {
          loginCb: configContext.loginCb,
          noMoneyCb: configContext.noMoneyCb,
        });

        return (
          <Modal {...{ onClose, isOpen }} contentOutside>
            <div className={styles.gameSummaryModal}>
              <img src={config.CDN_URL + error} className={styles.gameSummaryModalIcon} alt="icon" />
              <p className={`${styles.gameSummaryModalMainText} ${styles.gameSummaryModalMainTextError}`}>
                <FormattedHTMLMessage id={errorData.id} values={{ slug: errorData.slug, page: 'bet' }} />
                {errorData.email && <p>{errorData.email}</p>}
              </p>
              <hr />
              <div className={styles.gameSummaryModalBtnWrapper}>
                {errorCb.cb && typeof errorCb.cb === 'function' && (
                  <p className={styles.gameSummaryModalTextCb} onClick={(): void => errorCb.cb(closePicker)}>
                    <FormattedMessage id={errorCb.txt} />
                  </p>
                )}
                <p className={styles.gameSummaryModalSecondaryText}>
                  <a className={styles.gameSummaryModalCallToAction} onClick={onClose}>
                    <FormattedMessage id={'play.modal.error.btn'} />
                  </a>
                </p>
              </div>
            </div>
          </Modal>
        );
      }}
    </ModalConnector>
  );
};

export default ErrorModal;
