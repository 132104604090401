import React from 'react';
import { FormattedMessage } from 'react-intl';

import LabeledValue from '../../generic/LabeledValue';
import Button from '../../generic/Button/Button';

import { classNames } from '../../../../shared/utils';
import { TicketSummaryInterface } from '../../../../shared/interfaces/GamesPicker/TicketSummaryInterface';

import ownStyles from './TicketSummary.scss';
import styles from '../../../assets/styles/index.scss';

interface TicketSummaryProps {
  onBackClick: () => void;
  onCancelClick: () => void;
  onSubmitClick: () => void;
  summaryData?: TicketSummaryInterface;
  cancellation?: boolean;
}

const TicketSummary: React.FC<TicketSummaryProps> = ({
  onBackClick,
  summaryData,
  cancellation,
  onCancelClick,
  onSubmitClick,
}: TicketSummaryProps) => {
  const handleBtnClick = (): void => {
    if (cancellation) {
      onCancelClick();
      return;
    }
    onSubmitClick();
    return;
  };

  const renderActionButton = (): JSX.Element => {
    return (
      <Button
        onButtonClick={handleBtnClick}
        btnClassName={classNames(`${ownStyles.retailBtn}`, {
          [ownStyles.btnSuccess]: !cancellation,
        })}
        type={cancellation ? 'danger' : 'default'}
      >
        <FormattedMessage id={cancellation ? 'retail.ticket.modal.btn.p2' : 'retail.ticket.modal.btn.p1'} />
      </Button>
    );
  };

  const renderCloseButton = (): JSX.Element => {
    return (
      <Button btnClassName={`${ownStyles.retailBtn} ${styles.print} ${ownStyles.print}`} onButtonClick={onBackClick}>
        <span className={`${styles.icon} ${styles.close}`} />
      </Button>
    );
  };

  const renderButtons = (): JSX.Element => {
    return (
      <div className={ownStyles.retailBtnContainer}>
        {renderActionButton()}

        {renderCloseButton()}
      </div>
    );
  };

  return (
    <div className={`${ownStyles.ticket}`}>
      <div className={`${ownStyles.ticketDataContainer}`}>
        {summaryData.image && (
          <div className={`${ownStyles.imgContainer}`}>
            <img className={ownStyles.img} src={summaryData.image.url} alt="lottery logo" />
          </div>
        )}
        <div className={`${ownStyles['row']} ${styles['px-3']}`}>
          <LabeledValue
            labelName={<FormattedMessage id="retail.ticket.modal.product" />}
            value={summaryData.name}
            labelClassName={ownStyles.label}
            valueClassName={ownStyles.value}
          />
          <LabeledValue
            labelName={<FormattedMessage id="retail.ticket.modal.lines" />}
            value={summaryData.lines}
            labelClassName={ownStyles.label}
            valueClassName={ownStyles.value}
          />
          <LabeledValue
            labelName={<FormattedMessage id="retail.ticket.modal.draws" />}
            value={`${summaryData.drawsCount} from ${summaryData.drawsRange}`}
            labelClassName={ownStyles.label}
            valueClassName={ownStyles.value}
          />
          <LabeledValue
            labelName={<FormattedMessage id="retail.ticket.modal.stake" />}
            value={summaryData.stakes}
            labelClassName={ownStyles.label}
            valueClassName={ownStyles.value}
          />
        </div>
      </div>
      <div className={`${ownStyles.infoContainer}`}>
        <div className={`${ownStyles.retailBtn}`}>
          <FormattedMessage id={`retail.ticket.modal.should_collect`} />
          <br />
          <strong>{summaryData.betCost}</strong>
        </div>
      </div>
      <div className={ownStyles.retailBtnContainer}>{renderButtons()}</div>
    </div>
  );
};

export default TicketSummary;
