import React, { useCallback, useEffect } from 'react';

const useOuterClick = (ref: React.RefObject<HTMLDivElement>, cb: Function, isOpen: boolean): void => {
  const onClickOutside: EventListener = useCallback(
    (e: MouseEvent) => {
      if (!ref.current.contains(e.target as Node)) {
        cb();
      }
    },
    [ref, cb]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', onClickOutside);
    }

    return (): void => document.removeEventListener('click', onClickOutside);
  }, [onClickOutside, isOpen]);
};

export default useOuterClick;
