import { observable, action, runInAction } from 'mobx';

import ApiI18n from '../services/ApiI18n';

import { I18nStoreInterface, i18nData } from '../../shared/interfaces/Stores/I18nStoreInterface';

const api = new ApiI18n();

export default class I18nStore implements I18nStoreInterface {
  @observable i18nData: null;
  @observable error: null;
  @observable isLoading: boolean;

  @action getI18n = async (gameId: string, language: string): Promise<i18nData> => {
    this.isLoading = true;
    try {
      const response = await api.getActiveI18n(gameId, language);
      if (!response.ok) {
        throw await response.json();
      }

      this.i18nData = await response.json();
      this.isLoading = false;
      this.error = null;

      return await response.json();
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.error = err;
      });
    }
  };
}
