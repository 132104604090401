import React, { useEffect, useState } from 'react';

import { classNames } from '../../../../../shared/utils';

import styles from './FastPickerLine.scss';

interface FastPickerLineButtonProps {
  number: number;
  selectionName: string;
  delayedTimeForWonAmination?: number;
  isInstant?: boolean;
  isNumberWon?: boolean;
}

export const FastPickerLineButton: React.FC<FastPickerLineButtonProps> = ({
  number,
  selectionName,
  isInstant,
  isNumberWon,
  delayedTimeForWonAmination = 500,
}) => {
  const [isWon, setIsWon] = useState<boolean>(false);

  useEffect(() => {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    let delayed: any = null;

    if (isInstant) {
      delayed = setTimeout(() => {
        setIsWon(isNumberWon);
      }, delayedTimeForWonAmination);
    } else {
      setIsWon(isNumberWon);
    }

    return (): void => clearTimeout(delayed);
  }, [isInstant, isNumberWon, delayedTimeForWonAmination]);

  return (
    <div
      className={classNames(styles.fastLineNumber, {
        [styles.fastLineBonusNumber]: selectionName !== 'main',
        [styles.fastLineNumberWon]: isWon,
      })}
    >
      {number}
    </div>
  );
};
