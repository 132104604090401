import { CurrencyInterface, WalletInterface } from '../../shared/interfaces';

import ApiPlayerBalance from './ApiPlayerBalance';

class ApiPlayerBalanceConnector {
  public api: ApiPlayerBalance;

  public constructor(api: ApiPlayerBalance) {
    this.api = api;
  }

  async getPlayerBalance(gameId: string, userToken: string): Promise<CurrencyInterface> {
    const response = await this.api.getPlayerBalance(gameId, userToken);

    if (!response.ok) {
      throw await response.json();
    }

    return await response.json();
  }

  async getBalanceWallet(playerId: string): Promise<WalletInterface> {
    const response = await this.api.getBalanceWallet(playerId);

    if (!response.ok) {
      throw await response.json();
    }

    return await response.json();
  }
}

export default ApiPlayerBalanceConnector;
