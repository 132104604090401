import { action, observable } from 'mobx';
import { ErrorMessage } from '../../shared/interfaces';
import { RetailCashierUserEntity } from '../entities/RetailCashierUserEntity';
import ApiCashier from '../services/ApiCashier';
import ApiCashierConnector from '../services/ApiCashierConnector';
import AppConfigStore from './AppConfigStore';

/**
 * Maintains basic informations about cashier
 */
class RetailCashierStore {
  @observable isLoading = false;
  @observable error: ErrorMessage | null = null;
  @observable isValidCashier = false;
  @observable cashierData: RetailCashierUserEntity = undefined;
  private api = new ApiCashier();
  private apiConnector = new ApiCashierConnector(this.api);
  //value of last gameId used to fetch user data. If not changed, no data will be refetched.
  lastGameId: string;
  appConfig: AppConfigStore;

  constructor(appConfig: AppConfigStore) {
    this.appConfig = appConfig;
  }

  @action
  async fetchCashierDetails(gameId: string): Promise<void> {
    //Refetch cashier data if gameID changed
    if (gameId != this.lastGameId) {
      try {
        this.isLoading = true;
        const cashierData = await this.apiConnector.getCashierDetails(gameId, this.appConfig.userToken);
        this.cashierData = cashierData;
      } catch (error) {
        this.error = error;
      }
      this.isLoading = false;
    }

    this.lastGameId = gameId;
  }
}

export default RetailCashierStore;
