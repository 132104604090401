import React from 'react';

import styles from './LabeledValue.scss';

interface LabeledValueProps {
  labelName: string | JSX.Element;
  value: string | number | JSX.Element;
  labelClassName?: string;
  valueClassName?: string;
}

const LabeledValue: React.FC<LabeledValueProps> = ({ labelName, value, labelClassName = '', valueClassName = '' }) => {
  return (
    <div className={styles.container}>
      <p className={`${styles.label} ${labelClassName}`}>{labelName}:</p>
      <p className={`${styles.value} ${valueClassName}`}>{value}</p>
    </div>
  );
};

export default LabeledValue;
