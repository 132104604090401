import Api from './Api';

import { ApiGamesInterface } from '../../shared/interfaces/Api';
import { GameBetBodyInterface } from '../../shared/interfaces/GamesPicker';

import config from '../../../../app.config.js';

class ApiGames extends Api implements ApiGamesInterface {
  apiUrl = config.APP_URL;

  getToken(gameId: string): Promise<Response> {
    return this.get(`${this.apiUrl}/api/v1/games/${gameId}/token`);
  }

  getGameStructure(gameId: string): Promise<Response> {
    return this.get(`${this.apiUrl}/api/public/widget/v1/games/${gameId}`);
  }

  postBets(body: GameBetBodyInterface, gameId: string, token: string): Promise<Response> {
    return this.post(`${this.apiUrl}/api/public/widget/v1/games/${gameId}/future-bets?token=${token}`, body);
  }
}

export default ApiGames;
