const numberFormatter = (value: number): string => {
  //jackpot already divided by 100
  const valueString = value.toString();
  const valueLength = valueString.length;
  const mil = 1000000;

  if (valueLength <= 6) {
    return valueString;
  }

  return (value / mil).toString();
};

export default numberFormatter;
