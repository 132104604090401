import React, { useCallback, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';

import { FormattedMessage } from 'react-intl';
import NumberButton from '../NumberButton';
import Dropdown from '../Dropdown';

import { RootStoreContext } from '../../../../../lobby-engine/stores/RootStore';
import LineEntity from '../../../../../lobby-engine/entities/LineEntity';

import { GameChancesDropdownInterface } from '../../../../../shared/interfaces/GamesPicker';
import { selectionType } from '../../../../../shared/interfaces/Entities/SelectionParams';

import { classNames } from '../../../../../shared/utils';
import { PERMUTATIONS_MODAL } from '../../../../../shared/utils/modalNames';

import success from '../../../../assets/images/allgood.png';

import styles from './NumberPicker.scss';

import config from '../../../../../../../app.config.js';

interface NumberPickerProps {
  line: LineEntity;
  isBetPermutated: boolean;
  dropdownOptions: GameChancesDropdownInterface[];
}

const NumberPicker: React.FC<NumberPickerProps> = observer((props: NumberPickerProps) => {
  const { dropdownOptions, line, isBetPermutated } = props;
  const selections = line.selectionsArray;
  const areNumbersPicked = line.isValid && !line.isPermutationOn;
  const showDropdown =
    (line.hasSubType && line.leftToChooseWholeLine() !== 0) || (line.hasSubType && line.isPermutationOn);
  const { modal } = useContext(RootStoreContext);

  useEffect((): void => {
    if (isBetPermutated) {
      line.setSingleLine();
    }
  }, [areNumbersPicked, line, isBetPermutated]);

  useEffect((): void => {
    if (line.permutationsEnabled && areNumbersPicked && !isBetPermutated && !line.isSingleLine) {
      modal.showModal(PERMUTATIONS_MODAL, { onConfirmClick: line.turnPermutation, onCancelClick: line.setSingleLine });
    }
  }, [
    areNumbersPicked,
    line.permutationsEnabled,
    line.turnPermutation,
    line.isSingleLine,
    line.setSingleLine,
    modal,
    isBetPermutated,
  ]);

  const getDropdownParameter = (): number => {
    if (line.hasSubType) {
      return line.getSubType();
    }
    return null;
  };

  const onParamChoose = useCallback(
    (additionalParam: number): void => {
      if (line.hasSubType) {
        return line.setSubType(additionalParam);
      }
      return null;
    },
    [line]
  );

  const onNumberClick = useCallback(
    (pickedNumber: number, selection: selectionType): void => {
      line.addNumber(pickedNumber, selection.name);
    },
    [line]
  );

  const onPickClick = (): void => {
    line.randomizeChoice();
  };

  const onClearClick = useCallback((): void => {
    line.clearLine();
  }, [line]);

  const renderMessage = (): JSX.Element => {
    const isPermOn = line.isPermutationOn;

    if (line.leftToChooseWholeLine() == 0 && !isPermOn) {
      return <FormattedMessage id="play.bet.text.info.good" />;
    }

    return (
      <FormattedMessage
        id={isPermOn ? 'play.cta1.perm' : 'play.cta1'}
        values={{ number: isPermOn ? line.realLinesCount : line.leftToChooseWholeLine() }}
      />
    );
  };

  const renderNumbers = (selection: selectionType): JSX.Element[] => {
    const range = selection.range;
    const calculatedRange = range.end - range.start + 1;

    return [...Array(calculatedRange)].fill(0).map((elem: number, index: number) => {
      const value = index + range.start;
      const isActive = selection.numbers.includes(value);

      return (
        <NumberButton
          key={`${selection.name}-number-${value}`}
          btnNumber={value}
          width={6}
          isActive={isActive}
          onNumberClick={(): void => onNumberClick(value, selection)}
        />
      );
    });
  };

  const renderSelections = (): JSX.Element[] => {
    return selections.map((elem: selectionType) => {
      if (elem.isSubType === true) {
        return null;
      }

      return (
        <div key={`${elem.name}`} className={styles.numbersWrapper}>
          {renderNumbers(elem)}
        </div>
      );
    });
  };

  return (
    <div
      className={classNames(styles.numberPicker, {
        [styles.numberPickerChosen]: areNumbersPicked,
      })}
    >
      {areNumbersPicked && (
        <div className={styles.successImgWrapper}>
          <img className={styles.successImg} src={config.CDN_URL + success} alt="" />
        </div>
      )}
      {showDropdown ? (
        <Dropdown
          options={dropdownOptions}
          onOptionSelect={onParamChoose}
          selectedParameter={getDropdownParameter()}
          line={line}
        />
      ) : (
        <div className={styles.numberPickerHeader}>
          <p className={styles.headerText}>{renderMessage()}</p>
        </div>
      )}
      <div className={styles.numberPickerChooser}>
        <div className={styles.btnWrapper}>
          <div data-testid="button-quickpick" className={styles.btnPick} onClick={onPickClick}>
            <FormattedMessage id="play.cta" />
          </div>
          <div className={styles.btnReverse} onClick={onClearClick}>
            <div className={styles.trashIconWrapper}>
              <span className={styles.trashIcon}></span>
            </div>
          </div>
        </div>
        <div>{renderSelections()}</div>
      </div>
    </div>
  );
});

export default NumberPicker;
