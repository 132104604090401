import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import { RootStoreContext } from '../../../../lobby-engine/stores/RootStore';

import { classNames } from '../../../../shared/utils';

import { TopBarItemInterface } from '../../../../shared/interfaces/GamesPicker/TopBarInterface';

import ownStyles from './TopBar.scss';
import styles from '../../../assets/styles/index.scss';
import ConfigContext from '../../../../shared/context/ConfigContext';

interface TopBarProps {
  topBarItems: TopBarItemInterface[];
  showFullscreenToggleButton?: boolean;
}

const TopBar: React.FC<TopBarProps> = observer(({ topBarItems, showFullscreenToggleButton = false }: TopBarProps) => {
  const storeContext = useContext(RootStoreContext);
  const configContext = useContext(ConfigContext);

  const isFastPicker = storeContext.game.isFastPicker;
  const navigation = storeContext.navigation;

  function isActive(elem: TopBarItemInterface): boolean {
    if (typeof elem.isActive === 'function') {
      return elem.isActive(navigation.activePage);
    }

    return navigation.isPageActive(elem.value);
  }

  /**Decided if fullscreen toggle element should be rendered */
  function fullscreenToggleElement() {
    if (configContext.fullscreenCb && showFullscreenToggleButton) {
      return (
        <li
          key={`fullscreen-1`}
          className={`${ownStyles.navItem} ${ownStyles.navItemFullScreen}`}
          onClick={(): void => configContext.fullscreenCb()}
        >
          <span className={`${ownStyles.topBarIcon} ${styles.icon} ${styles['menu']}`} />
        </li>
      );
    }
  }

  return (
    <div className={ownStyles.topBar}>
      <ul
        className={classNames(ownStyles.nav, {
          [ownStyles.navFast]: isFastPicker,
        })}
      >
        {fullscreenToggleElement()}
        {topBarItems.map((elem: TopBarItemInterface, index: number) => {
          return (
            <li
              key={`${elem.displayId}-${index}`}
              className={classNames(ownStyles.navItem, {
                [ownStyles.navItemActive]: isActive(elem),
              })}
              onClick={(): void => navigation.changePage(elem.value)}
            >
              {elem.icon && <span className={`${ownStyles.topBarIcon} ${styles.icon} ${styles[elem.icon]}`} />}
              {elem.displayId && <FormattedMessage id={elem.displayId} />}
            </li>
          );
        })}
      </ul>
    </div>
  );
});

export default TopBar;
