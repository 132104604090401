import Api from './Api';

import config from '../../../../app.config.js';

class ApiI18n extends Api {
  apiUrl = config.APP_URL;

  getActiveI18n(gameId: string, language: string): Promise<Response> {
    return this.get(`${this.apiUrl}/api/public/widget/v1/games/${gameId}/i18n/${language}`);
  }
}

export default ApiI18n;
