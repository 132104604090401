import React from 'react';

import ModalConnector from '../../../shared/components/Modals/ModalConnector';
import { Modal } from '../generic';
import { FastPickerSingular } from '../GamesPicker/Game/FastPicker';

import { ModalInterface } from '../../../shared/interfaces/Lobby/ModalsInterface';
import { ImageInterface } from '../../../shared/interfaces';
import LineEntity from '../../../lobby-engine/entities/LineEntity';

import { PICKER_MODAL } from '../../../shared/utils/modalNames';

import styles from './PickerModal.scss';

interface PickerModalPropsInterface extends ModalInterface {
  data: {
    line: LineEntity;
    logo: ImageInterface;
    onConfirm(): void;
    size: 'small' | 'medium' | 'large';
  };
}

const PickerModal: React.FC<{}> = () => {
  return (
    <ModalConnector name={PICKER_MODAL}>
      {({ isOpen, onClose, data }: PickerModalPropsInterface): JSX.Element => {
        return (
          <Modal {...{ onClose, isOpen }} contentOutside size={data.size}>
            <div className={styles.pickerContainer}>
              <FastPickerSingular
                line={data.line}
                logo={data.logo}
                onCancel={onClose}
                onConfirmClick={data.onConfirm}
              />
            </div>
          </Modal>
        );
      }}
    </ModalConnector>
  );
};

export default PickerModal;
