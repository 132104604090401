exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2p-RExppSx_KcK_Crdl9ln{font-size:2rem}.ZrAu37WMQVwpWpjgnO_Z7{height:4.375rem}@media(min-width: 769px){.ZrAu37WMQVwpWpjgnO_Z7{height:auto;min-height:4.75rem;width:50%}}._3P3qxIqGlP8oTQfOIg9uIK{border:2px solid #ff8f07;color:#ff8f07;background:#fff}._C54M-rfUC7lL6tG0GlI4{border:2px solid #006b15;color:#006b15;background:#fff}._3TRkFgc4WzQVReKW9nVUFU{border:2px solid #b43210;color:#b43210;background:#fff}", ""]);

// Exports
exports.locals = {
	"barHeader": "_2p-RExppSx_KcK_Crdl9ln",
	"bar": "ZrAu37WMQVwpWpjgnO_Z7",
	"number": "_3P3qxIqGlP8oTQfOIg9uIK",
	"wonNumber": "_C54M-rfUC7lL6tG0GlI4",
	"lostNumber": "_3TRkFgc4WzQVReKW9nVUFU"
};