import React from 'react';
import { FormattedMessage } from 'react-intl';

import StakeEntity from '../../../../../lobby-engine/entities/StakeEntity';

import { classNames } from '../../../../../shared/utils';

import styles from './Stake.scss';

interface StakeProps {
  data: StakeEntity[];
  activeOption: StakeEntity;
  onOptionClick(elem: StakeEntity): void;
  currency?: string;
  fastPicker?: boolean;
}

const Stake: React.FC<StakeProps> = (props: StakeProps) => {
  const { onOptionClick, activeOption, data, fastPicker } = props;

  const renderContent = (): JSX.Element[] => {
    return data.map((elem: StakeEntity, index: number) => {
      return (
        <div
          key={`${elem.value}-${index}`}
          className={classNames(styles.stakePickBtn, {
            [styles.stakePickBtn_active]: activeOption.value === elem.value,
          })}
          onClick={(): void => onOptionClick(elem)}
          data-cy={'stake-' + index}
        >
          {elem.cost.getHumanAmount()}
        </div>
      );
    });
  };

  return (
    <React.Fragment>
      <div className={styles.stakeContainer}>
        <p className={styles.stakePickHead}>
          <FormattedMessage id="sidebar.stake.title" />
        </p>
        <div
          className={classNames(`${styles.stakePicker}`, {
            [styles.stakePickerFast]: fastPicker,
          })}
        >
          {renderContent()}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Stake;
