import React from 'react';
import { observer } from 'mobx-react';

import Icon from '../../../generic/Icon/Icon';

import LineEntity from '../../../../../lobby-engine/entities/LineEntity';
import { IntegerSelection } from '../../../../../lobby-engine/entities/selections/IntegerSelection';
import { RangeSelection } from '../../../../../lobby-engine/entities/selections/RangeSelection';

import styles from './MobileOrderSummary.scss';

interface MobileOrderSummaryProps {
  pickedData: LineEntity[];
}

const MobileOrderSummary: React.FC<MobileOrderSummaryProps> = observer((props: MobileOrderSummaryProps) => {
  const { pickedData } = props;

  const onBetCancelClick = (line: LineEntity): void => {
    line.clearLine();
  };

  const renderNumbers = (selections: Array<IntegerSelection | RangeSelection>): JSX.Element[] => {
    return selections.map((selection: IntegerSelection | RangeSelection, index: number) => {
      if (selection.isSubType) {
        return null;
      }

      return (
        <div className={styles.mobileSummarySelection} key={`${selection.name}-index`}>
          {selection.numbers.map((elem: number) => {
            return (
              <div key={`${index}-${elem}`} className={styles.mobileSummaryNumber}>
                {elem}
              </div>
            );
          })}
        </div>
      );
    });
  };

  const renderOrderSummaryList = (): JSX.Element[] => {
    return pickedData.map((elem: LineEntity, index: number) => {
      if (!elem.isValid) {
        return null;
      }

      return (
        <div key={elem.id} className={styles.mobileSummaryLine}>
          <p className={styles.mobileSummaryTxt}>Line {index + 1}</p>
          <div className={styles.mobileSummaryNumbersContainer}>{renderNumbers(elem.selectionsArray)}</div>
          <div className={styles.mobileSummaryReset} onClick={(): void => onBetCancelClick(elem)}>
            <Icon iconClassName={styles.icon}></Icon>
          </div>
        </div>
      );
    });
  };

  return <div className={styles.mobileSummaryContainer}>{renderOrderSummaryList()}</div>;
});

export default MobileOrderSummary;
