import dayjs from 'dayjs';
import { DrawInterface } from '../interfaces/GamesPicker';

interface DateInterface {
  day: string;
  month: string;
  year: string;
}

export const getProperValue = (data: string): string => {
  return parseInt(data) > 9 ? data : `0${data}`;
};

export const getDateFromData = (data: DateInterface): string => {
  return `${data.year}-${getProperValue(data.month)}-${getProperValue(data.day)}`;
};

export const getToday = (): DateInterface => {
  return {
    day: dayjs().date().toString(),
    month: `${dayjs().month() + 1}`,
    year: dayjs().year().toString(),
  };
};

export const getTodayAsString = (format = 'YYYY-MM-DD'): string => {
  return dayjs().format(format);
};

export const getDateAsString = (date: string, format = 'YYYY-MM-DD'): string => {
  return dayjs(date).format(format);
};

export const getFullMonthDate = (date: string): string => {
  return dayjs(date).format('DD MMMM');
};

export const getTimezone = (date?: string): string => {
  return dayjs(date ? date : getTodayAsString())
    .format('ZZ')
    .replace('+', '%2B');
};

export const getProperTimezone = (date?: string): string => {
  return dayjs(date ? date : getTodayAsString()).format('ZZ');
};

export const getDatesRangeFromDraws = (draws: DrawInterface[]): string => {
  const length = draws.length;
  const format = 'YYYY/MM/DD';
  const firstDate = getDateAsString(draws[0].drawDatetime, format);
  const lastDate = getDateAsString(draws[length - 1].drawDatetime, format);

  if (length <= 1) {
    return firstDate;
  }

  return `${firstDate} till ${lastDate}`;
};
