import React, { useCallback, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { format } from 'date-fns';

import FastPickerLines from '../components/GamesPicker/Game/FastPickerLines';
import Modal from '../../shared/components/Modals/Modal';
import { FastPickerEditecInfo, FastPickerSingular } from '../components/GamesPicker/Game/FastPicker';

import LinesStore from '../../lobby-engine/stores/LinesStore';
import LineEntity from '../../lobby-engine/entities/LineEntity';
import { RootStoreContext } from '../../lobby-engine/stores/RootStore';

import { ImageInterface } from '../../shared/interfaces';

import { getTimezone } from '../../shared/utils';

import ownStyles from './styles.scss';
interface FastPickerGameContainerProps {
  lines: LinesStore;
  gameId: string;
  isMobile: boolean;
  fastPicker: boolean;
  additionalParameter: { value: number; name: string };
  logo: ImageInterface;
  isInstant?: boolean;
}

const FastPickerGameContainer: React.FC<FastPickerGameContainerProps> = observer(
  (props: FastPickerGameContainerProps) => {
    const { lines, gameId, isMobile, logo, isInstant } = props;
    const { instruction } = useContext(RootStoreContext);
    const getResults = instruction.getLastDraws;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [lastDrawDate, setLastDrawDate] = useState<any>({ date: '' });
    const lastResults = instruction.lastDraws;
    const [showPicker, setShowPicker] = useState<boolean>(false);
    // Current line used by manual picker to edit/add
    const [currentLine, setCurrentLine] = useState<LineEntity>(null);

    useEffect(() => {
      if (isInstant) {
        return;
      }

      if (!isMobile && typeof isMobile === 'boolean') {
        instruction.getLastDrawDate(gameId).then(() => {
          setLastDrawDate(instruction.lastDrawDate.data);
        });
      }
    }, [isMobile, gameId, isInstant]);

    const loadDates = (): void => {
      if (lastDrawDate.date !== '') {
        const dateFormat = format(new Date(lastDrawDate.date), 'yyyy-MM-dd');
        getResults(gameId, dateFormat, getTimezone(''), 1, 3);
      }
    };

    useEffect(() => {
      loadDates();
    }, [lastDrawDate.date]);

    useEffect(() => {
      const defaultFilledLinesCount = 2;

      [...Array(defaultFilledLinesCount)].forEach(() => {
        lines.addLine(true);
      });
    }, [lines]);

    const getLine = useCallback(
      (id = ''): LineEntity => {
        const properId = id || currentLine.id;
        return lines.linesList.find((elem: LineEntity) => elem.id === properId);
      },
      [lines, currentLine]
    );

    const onClose = useCallback((): void => {
      setCurrentLine(null);
      setShowPicker(false);
    }, []);

    /**
     * Edites current line as given by LineId
     * @param lineId
     */
    const onLineClick = useCallback(
      (lineId: string): void => {
        const origLine = getLine(lineId);
        const lineCopy = lines.createLine();
        lineCopy.copyFromExisting(origLine);
        setCurrentLine(lineCopy);
        setShowPicker(true);
      },
      [getLine, lines]
    );

    const onNewEmptyLine = useCallback((): void => {
      const emptyLine = lines.createLine();
      setCurrentLine(emptyLine);
      setShowPicker(true);
    }, [lines]);

    const onRemoveLine = useCallback(
      (id: string): void => {
        lines.removeLine(id);
      },
      [lines]
    );

    const onRefreshBetClick = useCallback(
      (lineId: string): void => {
        const line = getLine(lineId);
        line.randomizeChoice();
      },
      [getLine]
    );

    const onAddNewLine = useCallback((): void => {
      lines.addLine(true);
    }, [lines]);

    const onConfirmClick = (): void => {
      lines.upsertLine(currentLine);
      setShowPicker(false);
    };

    const renderPicker = (): JSX.Element => {
      const line = currentLine;

      return <FastPickerSingular {...{ onConfirmClick, logo, line }} onCancel={onClose} />;
    };

    const renderInfo = (): JSX.Element => {
      return <FastPickerEditecInfo />;
    };

    const renderModal = (): JSX.Element => {
      return (
        <Modal size="large" {...{ onClose }}>
          {renderPicker()}
        </Modal>
      );
    };

    const renderPickerOption = (): JSX.Element => {
      if (isMobile && showPicker) {
        return renderModal();
      }

      if (!isMobile && showPicker) {
        return renderPicker();
      }

      if (!isMobile) {
        return renderInfo();
      }
    };

    return (
      <div className={ownStyles.fastPickerContentContainer}>
        {renderPickerOption()}
        <FastPickerLines
          {...{
            isMobile,
            onRemoveLine,
            onLineClick,
            onAddNewLine,
            onNewEmptyLine,
            onRefreshBetClick,
            lastResults,
            lines,
            isInstant,
          }}
        />
      </div>
    );
  }
);

export default FastPickerGameContainer;
