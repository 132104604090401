import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';

import { DrawInterface } from '../../../../../shared/interfaces/GamesPicker';

import useMobileWidth from '../../../../../shared/hooks/useMobileWidth';

import { classNames, constans } from '../../../../../shared/utils';

import right from '../../../../assets/images/chevron-right.svg';
import left from '../../../../assets/images/chevron-left.svg';

import styles from './DrawTimeSelection.module.scss';

import config from '../../../../../../../app.config.js';

interface DrawTimeSelectionProps {
  draws: DrawInterface[][];
  selectedDraw: DrawInterface;
  onDrawSelect(draw: DrawInterface): void;
}

const DrawTimeSelection: React.FC<DrawTimeSelectionProps> = observer((props: DrawTimeSelectionProps) => {
  const { draws, selectedDraw, onDrawSelect } = props;
  const countDrawsDisplayMobile = 6;
  const [page, setPage] = useState<number>(0);
  const [sameDayPage, setSameDayPage] = useState<number>(1);
  const [drawCount, setDrawCount] = useState<number>(countDrawsDisplayMobile);
  const isMobile = useMobileWidth();

  useEffect((): void => {
    const drawCountCalculate = isMobile ? constans.mobileDrawCount : constans.desktopDrawCount;

    if (draws[page].length === 0) {
      setPage(page + 1);
    }

    setDrawCount(drawCountCalculate);
  }, [isMobile, draws, page]);

  const onPaginationElemClick = (page: number): void => {
    setPage(page);
    setSameDayPage(1);
  };

  const renderDrawTimes = (): JSX.Element[] => {
    return draws[page].slice(drawCount * (sameDayPage - 1), drawCount * sameDayPage).map((elem: DrawInterface) => {
      return (
        <div
          key={`${elem.id}`}
          className={classNames(`${styles.TimeSelectionElem}`, {
            [styles.TimeSelectionElemActive]: selectedDraw.id === elem.id,
          })}
          onClick={(): void => onDrawSelect(elem)}
        >
          {dayjs(elem.drawDatetime).format('HH:mm')}
        </div>
      );
    });
  };

  const renderPagination = (): JSX.Element[] => {
    return draws.map((elem: DrawInterface[], index: number) => {
      if (elem.length === 0) {
        return null;
      }

      return (
        <div
          key={`${index}-page`}
          className={classNames(`${styles.TimeSelectionPaginationElem}`, {
            [styles.TimeSelectionPaginationElemActive]: index === page,
          })}
          onClick={(): void => onPaginationElemClick(index)}
        />
      );
    });
  };

  return (
    <div className={styles.TimeSelectionContainer}>
      <div className={styles.TimeSelectionDateWrapper}>
        <p className={styles.TimeSelectionDate}>
          {draws[page].length != 0 && (
            <FormattedMessage
              id="draws.time.selection.head"
              values={{ date: dayjs(draws[page][0].drawDatetime).format('DD/MM/YYYY') }}
            />
          )}
        </p>
        {isMobile &&
          draws[page].length > countDrawsDisplayMobile &&
          (sameDayPage === 1 ? (
            <img
              className={`${styles.TimeSelectionIcon} ${styles.TimeSelectionIconRight}`}
              onClick={(): void => setSameDayPage(sameDayPage + 1)}
              src={config.CDN_URL + right}
              alt="next"
            />
          ) : (
            <img
              className={`${styles.TimeSelectionIcon} ${styles.TimeSelectionIconLeft}`}
              onClick={(): void => setSameDayPage(sameDayPage - 1)}
              src={config.CDN_URL + left}
              alt="previous"
            />
          ))}
      </div>
      <div className={styles.TimeSelectionWrapper}>{renderDrawTimes()}</div>
      <div className={styles.TimeSelectionPagination}>{renderPagination()}</div>
    </div>
  );
});

export default DrawTimeSelection;
