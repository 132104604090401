import { action, computed, observable } from 'mobx';

import { randomGenerator } from '../../../shared/utils';

import { SelectionPlainInterface } from '../../../shared/interfaces/Stores';

export class AbstractSelection {
  @observable public name: string;
  @observable public type: 'range' | 'integer';
  @observable public maxNumberCount: number;
  @observable public minNumberCount: number;
  @observable public maxAdditionalPermutationsNumberCount: number;
  @observable public allowNonUniqueNumbers: boolean;
  @observable public isSubType: boolean;
  @observable public range: {
    start: number;
    end: number;
  };
  @observable public numbers: number[];
  @observable public isPermutationOn = false;
  @observable public wonSelection: number[] = [];

  constructor() {
    this.numbers = [];
    this.allowNonUniqueNumbers = false;
    this.isSubType = false;
    this.maxAdditionalPermutationsNumberCount = 0;
  }

  /**
   * Creates new Selection Instance that is identical to current.
   */
  copy(): AbstractSelection {
    const copy = new AbstractSelection();
    copy.name = this.name;
    copy.type = this.type;
    copy.maxNumberCount = this.maxNumberCount;
    copy.minNumberCount = this.minNumberCount;
    copy.allowNonUniqueNumbers = this.allowNonUniqueNumbers;
    copy.isSubType = this.isSubType;
    copy.range = {
      start: this.range.start,
      end: this.range.end,
    };
    copy.numbers = [];
    this.numbers.forEach((number, index) => {
      copy.numbers[index] = number;
    });
    return copy;
  }

  @action
  addNumber(pickedNumber: number): void {
    const isPicked = this.numbers.includes(pickedNumber);

    // Remove selected number
    if (isPicked) {
      this.numbers = this.numbers.filter((elem: number) => elem !== pickedNumber);
      return;
    }

    // Don't add more than allowed
    if (this.nrOfSelected >= this.maxSelectableNumbers) {
      return;
    }

    this.numbers[this.nrOfSelected] = pickedNumber;
  }

  setNumberAtindex(number: number, index: number): void {
    this.numbers[index] = number;
  }

  @action
  clearSelection(): void {
    this.numbers = [];
  }

  @action
  addNumbers(pickedNumbers: number[]): void {
    this.numbers = pickedNumbers;
  }

  @action
  turnPermutationOn(): void {
    this.isPermutationOn = true;
  }

  turnPermutationOff(): void {
    this.isPermutationOn = false;
  }

  get nrOfSelected(): number {
    return this.numbers.filter((value) => value !== undefined).length;
  }

  @computed
  get isValid(): boolean {
    return this.nrOfSelected <= this.maxSelectableNumbers && this.nrOfSelected >= this.minNumberCount;
  }

  @computed
  get leftToChoose(): number {
    return this.maxNumberCount - this.nrOfSelected;
  }

  /**
   * Returns true maximal number of balls that can be selected by user
   * taking into account permutations
   */
  get maxSelectableNumbers(): number {
    if (this.isPermutationOn) {
      /**
       * TODO: Might need refactor as idea was to block more than ~120 lines.
       * Desired solution: Count number of permutations based on
       * maxNumberCount and maxAdditionalPermutationNumberCount
       * and allow for those that do not
       * create more than ~120 lines.
       *
       * This does not change maxAdditionalPermsNrCount so it works with chance games
       */
      if (this.maxNumberCount >= 5 && this.maxAdditionalPermutationsNumberCount >= 5) {
        return this.maxNumberCount + 4;
      }
      return this.maxNumberCount + this.maxAdditionalPermutationsNumberCount;
    }
    return this.maxNumberCount;
  }

  @action
  randomizeChoice(): void {
    this.numbers = randomGenerator(this.range.start, this.range.end, this.maxNumberCount, !this.allowNonUniqueNumbers);
  }

  isPicked(value: number): boolean {
    return this.numbers.indexOf(value) !== -1;
  }

  @action
  checkIsNumberWon(selectedNumber: number): boolean {
    return this.wonSelection.includes(selectedNumber);
  }

  //TODO: Validation ?
  toPlain(): SelectionPlainInterface | number | number[] {
    if (this.isPermutationOn) {
      return this.toPlainPerm();
    }
    return this.toPlainNormal();
  }

  toPlainNormal(): SelectionPlainInterface {
    const numbers: { [k: string]: number } = {};
    let mainNumber = 1;

    this.numbers.forEach((element) => {
      if (typeof element !== 'number') {
        return;
      }
      numbers[`${this.name}${mainNumber}`] = element;
      mainNumber += 1;
    });

    return numbers;
  }

  toPlainPerm(): number[] | number {
    return this.numbers;
  }
}
