import React, { useState } from 'react';
import dayjs from 'dayjs';

import Image from '../../generic/Image';
import LabeledValue from '../../generic/LabeledValue';
import FastPickerLine from '../Game/FastPickerLine';

import LotteryEntity from '../../../../lobby-engine/entities/selections/LotteryEntity';
import LineEntity from '../../../../lobby-engine/entities/LineEntity';

import { currencyFormatter } from '../../../../shared/utils';

import styles from './Lottery.scss';

interface LotteryProps {
  lottery: LotteryEntity;
  onRemove(): void;
  onLineClick(lottery: LotteryEntity, line: LineEntity): void;
}

const Lottery: React.FC<LotteryProps> = ({ lottery, onRemove, onLineClick }: LotteryProps) => {
  const { lines, image, name, draws, ticket } = lottery;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleExpanderClick = (): void => {
    setIsExpanded((prevState: boolean): boolean => {
      return !prevState;
    });
  };

  const renderLines = (): JSX.Element[] => {
    return lines.linesList.map((elem: LineEntity) => {
      if (!elem.isValid) {
        return null;
      }

      return (
        <FastPickerLine
          key={elem.id}
          line={elem}
          onRemoveLine={(): void => elem.clearLine()}
          onRefreshBetClick={(): void => elem.randomizeChoice()}
          onLineClick={(): void => onLineClick(lottery, elem)}
          type="cart"
        />
      );
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.infoContainer}>
        <div className={styles.lotteryInfoContainer}>
          <Image src={image.url} imgClassName={styles.logo} />
          <div className={styles.infoWrapper}>
            <div className={styles.titleWrapper}>
              <p className={`${styles.label} ${styles.title}`}>{name}</p>
              <span onClick={onRemove} className={styles.iconContainer}>
                
              </span>
            </div>
            <div className={styles.subInfoWrapper}>
              <LabeledValue
                labelName="Lines"
                value={lines.validLinesCount}
                labelClassName={styles.label}
                valueClassName={styles.label}
              />
              <LabeledValue
                labelName="Draws"
                value={draws.selectedNumberDraws}
                labelClassName={styles.label}
                valueClassName={styles.label}
              />
            </div>
            <div>
              <LabeledValue
                labelName="Date"
                value={dayjs(lottery.draws.selectedDraw.drawDatetime).format('DD/MM/YYYY')}
                labelClassName={styles.label}
                valueClassName={styles.label}
              />
            </div>
          </div>
        </div>
        <div className={styles.priceContainer}>
          <p className={styles.price}>{currencyFormatter(ticket.betCost.currency, ticket.betCost.getHumanAmount())}</p>
        </div>
        <div className={styles.iconContainer} onClick={handleExpanderClick}>
          
        </div>
      </div>
      {isExpanded && <div className={styles.linesContainer}>{renderLines()}</div>}
    </div>
  );
};

export default Lottery;
