exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2DvaOu3KTdUVv9HrMYm_X8{width:100%;padding:.6em;margin:.3em 0;border:1px solid #e2e2e2;border-radius:5px !important}._2DvaOu3KTdUVv9HrMYm_X8 ._2XucDQiw6taHsIkZbGNJFV{display:flex;justify-content:space-between;flex-wrap:nowrap}._2DvaOu3KTdUVv9HrMYm_X8 ._2XucDQiw6taHsIkZbGNJFV .drntZY1jVqj0cRe0obYIq{max-width:50px;max-height:50px}@media(min-width: 769px){._2DvaOu3KTdUVv9HrMYm_X8 ._2XucDQiw6taHsIkZbGNJFV .drntZY1jVqj0cRe0obYIq{max-width:70px;max-height:70px}}._2DvaOu3KTdUVv9HrMYm_X8 ._2XucDQiw6taHsIkZbGNJFV ._1EB4B7q7IcRGWjUe9ZaPY4{padding:0 .6em}._2DvaOu3KTdUVv9HrMYm_X8 ._2XucDQiw6taHsIkZbGNJFV ._1EB4B7q7IcRGWjUe9ZaPY4 ._3ZrfHt750_xZETHLm0h7hF{font-weight:bold;color:#656565}._2DvaOu3KTdUVv9HrMYm_X8 ._2XucDQiw6taHsIkZbGNJFV ._1EB4B7q7IcRGWjUe9ZaPY4 ._3xETwlaXLjylyY8U6aP2KX{font-size:.875em;color:#656565}@media(min-width: 769px){._2DvaOu3KTdUVv9HrMYm_X8 ._2XucDQiw6taHsIkZbGNJFV ._1EB4B7q7IcRGWjUe9ZaPY4 ._3xETwlaXLjylyY8U6aP2KX{font-size:1em}}._2DvaOu3KTdUVv9HrMYm_X8 ._2XucDQiw6taHsIkZbGNJFV ._1F10m7VUF5H26RFWfch2Pg{display:flex}._2DvaOu3KTdUVv9HrMYm_X8 ._76xY8HRi7K7snKV1PmVvc{display:flex}._2DvaOu3KTdUVv9HrMYm_X8 ._3QnWfJcdBKWrx_uvXfhFRY{display:flex;align-items:center}._2DvaOu3KTdUVv9HrMYm_X8 ._1XMN-DUVeGOXH4o_UDAIiN{display:flex;align-items:center}._2DvaOu3KTdUVv9HrMYm_X8 ._1XMN-DUVeGOXH4o_UDAIiN ._3zphgSxMc--LuAl9DH09Gk{color:#656565;font-weight:600;font-size:.875em}@media(min-width: 769px){._2DvaOu3KTdUVv9HrMYm_X8 ._1XMN-DUVeGOXH4o_UDAIiN ._3zphgSxMc--LuAl9DH09Gk{font-size:1em}}._2DvaOu3KTdUVv9HrMYm_X8 ._1BIq7aNZG3Ik0cT7r30RjW{font-family:\"Icons\";display:flex;align-items:center;cursor:pointer;margin:0 .5em;font-family:\"Icons\"}._2DvaOu3KTdUVv9HrMYm_X8 ._3i4TJOj2yvFdQkKzjmAPpl{display:flex;flex-wrap:wrap;flex:1 1 auto}", ""]);

// Exports
exports.locals = {
	"container": "_2DvaOu3KTdUVv9HrMYm_X8",
	"infoContainer": "_2XucDQiw6taHsIkZbGNJFV",
	"logo": "drntZY1jVqj0cRe0obYIq",
	"infoWrapper": "_1EB4B7q7IcRGWjUe9ZaPY4",
	"title": "_3ZrfHt750_xZETHLm0h7hF",
	"label": "_3xETwlaXLjylyY8U6aP2KX",
	"subInfoWrapper": "_1F10m7VUF5H26RFWfch2Pg",
	"titleWrapper": "_76xY8HRi7K7snKV1PmVvc",
	"lotteryInfoContainer": "_3QnWfJcdBKWrx_uvXfhFRY",
	"priceContainer": "_1XMN-DUVeGOXH4o_UDAIiN",
	"price": "_3zphgSxMc--LuAl9DH09Gk",
	"iconContainer": "_1BIq7aNZG3Ik0cT7r30RjW",
	"linesContainer": "_3i4TJOj2yvFdQkKzjmAPpl"
};