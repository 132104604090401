import { action, observable } from 'mobx';

import { pageNames } from '../../shared/utils';

import { NavigationStoreInterface } from '../../shared/interfaces/Stores/NavigationStoreInterface';

class NavigationStore implements NavigationStoreInterface {
  @observable private _activePage: string = pageNames.LOBBY;

  get activePage(): string {
    return this._activePage;
  }

  set activePage(value: string) {
    this._activePage = value;
  }

  @action
  changePage(pageName: string): void {
    this.activePage = pageName;
  }

  isPageActive(pageName: string): boolean {
    return pageName === this._activePage;
  }
}

export default NavigationStore;
