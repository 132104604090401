import React, { useState, useEffect } from 'react';

interface DelayedProps {
  children: JSX.Element;
  timeBeforeShow?: number;
}

const Delayed: React.FC<DelayedProps> = ({ children, timeBeforeShow = 1500 }: DelayedProps) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timeout = setTimeout((): void => {
      setIsShown(true);
    }, timeBeforeShow);

    return (): void => clearTimeout(timeout);
  }, [timeBeforeShow]);

  return isShown ? children : null;
};

export default Delayed;
