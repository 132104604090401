export const DATE_FORMATS = {
  month: 'YYYY-MM',
  date: 'YYYY-MM-DD',
  api: 'YYYY-MM-DDTHH:mm:ssP',
};

export const DATE = 'date';
export const MONTH = 'month';
export const DAY = 'day';

export const SEARCH_CRITERIA = 'searchCriteria';
export const REPORT_TYPE = 'reportType';
