import React from 'react';

import styles from './Image.scss';

interface ImageProps {
  src: string;
  containerClassName?: string;
  imgClassName?: string;
  alt?: string;
}

const Image: React.FC<ImageProps> = ({ src, alt = '', containerClassName = '', imgClassName = '' }) => {
  return (
    <div className={`${styles.defaultContainer} ${containerClassName}`}>
      <img className={`${styles.defaultImage} ${imgClassName}`} src={src} alt={alt} />
    </div>
  );
};

export default Image;
