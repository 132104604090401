import 'whatwg-fetch';

abstract class Api {
  abstract apiUrl: string;

  protected get(url: string, body?: object): Promise<Response> {
    return this.apiCall(url, 'GET', body);
  }

  protected post(url: string, body?: object): Promise<Response> {
    return this.apiCall(url, 'POST', body);
  }

  protected put(url: string, body?: object): Promise<Response> {
    return this.apiCall(url, 'PUT', body);
  }

  protected delete(url: string, body?: object): Promise<Response> {
    return this.apiCall(url, 'DELETE', body);
  }

  private apiCall(url: string, method: string, body?: object): Promise<Response> {
    return fetch(url, {
      method: method ? method : 'GET',
      body: JSON.stringify(body),
    });
  }
}

export default Api;
