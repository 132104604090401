import ApiUserBetsInterface from '../../shared/interfaces/Api/ApiUserBetsInterface';
import {
  BetDetailsInterface,
  UserBetsParamsInterface,
  UserBetsResponseInterface,
} from '../../shared/interfaces/GamesPicker';
import { HeadersInterface } from '../../shared/interfaces';

import { camelCaseChanger } from '../../shared/utils';

class ApiUserBetsConnector {
  public api: ApiUserBetsInterface;

  public constructor(api: ApiUserBetsInterface) {
    this.api = api;
  }

  public async getUserBets(params: UserBetsParamsInterface): Promise<UserBetsResponseInterface> {
    const response = await this.api.getUserBets(params);
    if (!response.ok) {
      throw await response.json();
    }
    const headers: HeadersInterface = {};

    response.headers.forEach((val, key) => {
      const newKey = camelCaseChanger(key);

      headers[newKey] = val;
    });

    return {
      headers,
      data: await response.json(),
    };
  }

  public async getSingleBet(id: string, userToken: string, gameId: string): Promise<BetDetailsInterface> {
    const response = await this.api.getSingleBet(id, userToken, gameId);
    if (!response.ok) {
      throw await response.json();
    }

    return await response.json();
  }

  public async getBetResults(id: string, userToken: string, gameId: string, lineId: string): Promise<string[]> {
    const response = await this.api.getBetResults(id, userToken, gameId, lineId);

    if (!response.ok) {
      throw await response.json();
    }

    return await response.json();
  }

  public async getBetResultsPerm(id: string, userToken: string, gameId: string, lineId: string): Promise<string[]> {
    const response = await this.api.getBetResultsPerm(id, userToken, gameId, lineId);

    if (!response.ok) {
      throw await response.json();
    }

    return await response.json();
  }
  /* eslint-disable max-len */
  public async getSingleBetByGintidAndSecret(
    gameId: string,
    token: string,
    globalIntId: number | string,
    secret: number | string
  ): Promise<BetDetailsInterface> {
    const response = await this.api.getSingleBetByGintidAndSecret(gameId, token, globalIntId, secret);

    if (!response.ok) {
      throw await response.json();
    }

    return await response.json();
  }

  public async getSingleBetByGintid(
    gameId: string,
    token: string,
    globalIntId: number | string
  ): Promise<BetDetailsInterface> {
    const response = await this.api.getSingleBetByGintid(gameId, token, globalIntId);

    if (!response.ok) {
      throw await response.json();
    }

    return await response.json();
  }
  /* eslint-enable max-len */

  public async payBet(gameId: string, userToken: string, betId: string): Promise<BetDetailsInterface> {
    const response = await this.api.payoutBet(gameId, userToken, betId);

    if (!response.ok) {
      throw await response.json();
    }

    return await response.json();
  }
}

export default ApiUserBetsConnector;
