import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import { getErrorMessage } from '../../../shared/utils';

import styles from './Error.scss';

import error from '../../assets/images/error.png';
import config from '../../../../../app.config.js';

interface ErrorProps {
  errorCode: number;
  slug?: string;
  page?: string;
}

const Error: React.FC<ErrorProps> = ({ errorCode, slug = '', page = '' }: ErrorProps) => {
  const errorData = getErrorMessage(errorCode, slug);

  return (
    <div className={styles.errorContainer}>
      <img className={styles.errorImg} src={config.CDN_URL + error} alt="error" />
      <div className={styles.errorMessage}>
        <p className={styles.errorMessageTxt}>
          <FormattedHTMLMessage id={errorData.id} values={{ slug: errorData.slug, page }} />
        </p>
      </div>
    </div>
  );
};

export default Error;
