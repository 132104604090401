import * as React from 'react';

import { ColorInterface, SectionInterface, LabelInterface } from '../../../shared/interfaces/Lobby/SectionInterface';

import { classNames } from '../../../shared/utils';

import styles from './Variant.scss';

const Variant: React.FC<SectionInterface> = ({
  type,
  background,
  button,
  labels,
  id,
  logo,
  onButtonClick,
  linkOnWholeSection,
}: SectionInterface) => {
  const deserializeColor = (color: ColorInterface): string => {
    return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
  };

  const checkForType = (sectionType: string): boolean => {
    return type === sectionType;
  };

  const setVariantContainerStyles = (): object => {
    return {
      border: `${background.borderWidth}px solid ${deserializeColor(background.borderColor)}`,
      backgroundColor: deserializeColor(background.fillColor),
      backgroundImage: background.image && `url(${background.image.url})`,
    };
  };

  const handleVariantClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();

    try {
      const url = new URL(button.link).href;
      window.location.replace(url);
    } catch (e) {
      onButtonClick(button.link);
    }
  };

  const renderText = (): JSX.Element => {
    if (labels.length === 0) {
      return null;
    }

    return (
      <div className={styles.variantLabels}>
        {labels
          .slice()
          .sort((elemA, elemB) => elemA.position - elemB.position)
          .map((elem: LabelInterface, index: number) => {
            if (!elem.text) {
              return null;
            }

            return (
              <p
                key={`${id}-label-${index}`}
                style={{
                  fontWeight: elem.fontWeight,
                  fontSize: `${elem.fontSize}px`,
                  border: elem.borderWidth ? `${elem.borderWidth}px solid ${deserializeColor(elem.borderColor)}` : null,
                  color: deserializeColor(elem.color),
                }}
                className={styles.variantText}
              >
                {elem.text}
              </p>
            );
          })}
      </div>
    );
  };

  const renderLogo = (): JSX.Element => {
    return (
      logo.image && (
        <div
          className={styles.logoWrapper}
          style={{
            border: `${logo.borderWidth}px solid ${logo.borderColor}`,
            background: `${logo.fillColor}`,
          }}
        >
          <img
            className={type === 'BIG' ? styles.variantBigLogo : styles.variantLogo}
            src={logo.image.url}
            alt="logo"
          />
        </div>
      )
    );
  };

  const renderButton = (): JSX.Element => {
    /* eslint-disable max-len */
    const [lotteryName] = labels;
    const { text, shadowProps } = button;
    return (
      <div
        data-testid={'button-play-' + lotteryName.text.toLowerCase().split(' ').join('-')}
        className={styles.variantBtn}
        onClick={handleVariantClick}
        style={{
          background: deserializeColor(button.buttonColor),
          fontWeight: text.fontWeight,
          fontSize: `${text.fontSize}px`,
          color: deserializeColor(text.color),
          border: `${text.borderWidth} solid ${text.borderColor}`,
          boxShadow: `${button.shadowColor} ${shadowProps.x}px ${shadowProps.y}px ${shadowProps.b}px ${
            text.text.length ? '2' : '0'
          }px`,
          padding: `${text.text.length ? null : '0px'}`,
        }}
      >
        {text.text}
      </div>
    );
    /*eslint-enable max-len */
  };

  const variantClassName = 'variant' + (type.charAt(0).toUpperCase() + type.slice(1).toLowerCase());
  const clickWholeSection = linkOnWholeSection ? handleVariantClick : undefined;

  return (
    <div
      className={classNames(`${styles.variant} ${styles[variantClassName]}`, {
        [styles.variantClickable]: linkOnWholeSection,
      })}
      style={setVariantContainerStyles()}
      onClick={clickWholeSection}
    >
      <div className={styles.informationWrapper}>
        <div
          className={classNames('', {
            [styles.variantMediumSectionLogo]: checkForType('MEDIUM'),
            [styles.variantSmallSectionLogo]: checkForType('SMALL'),
          })}
        >
          {renderLogo()}
        </div>
        <div
          className={classNames('', {
            [styles.variantMediumSectionTxt]: checkForType('MEDIUM'),
            [styles.variantSmallSectionTxt]: checkForType('SMALL'),
          })}
        >
          {renderText()}
        </div>
        <div
          className={classNames('', {
            [styles.variantMediumSectionBtn]: checkForType('MEDIUM'),
            [styles.variantSmallSectionBtn]: checkForType('SMALL'),
          })}
        >
          {renderButton()}
        </div>
      </div>
    </div>
  );
};

export default Variant;
