import Api from './Api';

import config from '../../../../app.config.js';
import { getUrlWithParams } from '../../shared/utils/apiUtils';

class ApiCashier extends Api {
  apiUrl = config.APP_URL;

  getCashierDetails(gameId: string, token: string): Promise<Response> {
    const properParams = {
      gameId,
      token,
    };
    const url = `${this.apiUrl}/api/public/retail-widget/v1/me-shop/me`;
    const properUrl = getUrlWithParams(url, properParams);
    return this.get(properUrl);
  }
}

export default ApiCashier;
