import * as React from 'react';

import styles from '../assets/styles/error.scss';

import error from '../assets/images/error.png';

import config from '../../../../app.config.js';

function ErrorPage(err: Error | string): JSX.Element {
  // eslint-disable-next-line no-console
  console.error('Error appeared:', err);

  return (
    <div className={styles.error}>
      <img className={styles.errorImg} src={config.CDN_URL + error} alt="error" />
      <p>We&apos;re sorry, something went wrong. Please refresh the page and try again.</p>
      <p>
        <small>Error code. {err.toString()}</small>
      </p>
    </div>
  );
}

export default ErrorPage;
