import Api from './Api';

import { ApiInstructionsInterface } from '../../shared/interfaces/Api';

import config from '../../../../app.config.js';

class ApiInstructions extends Api implements ApiInstructionsInterface {
  apiUrl = config.APP_URL;

  getLastDraws(gameId: string, date: string, utc: string, page: string, perPage: string): Promise<Response> {
    const urlDateAddition = date ? `?search[draws][results][fromDate]=${date}` : '';
    const utcFormat = `&search[draws][results][timezone]=${utc}&perPage=${perPage}&page=${page}`;
    return this.get(`${this.apiUrl}/api/public/widget/v1/games/${gameId}/draws-results${urlDateAddition}${utcFormat}`);
  }

  //REQUEST TO LOAD DATE WITH RESULTS
  getLastDrawDate(gameId: string): Promise<Response> {
    return this.get(`${this.apiUrl}/api/public/widget/v1/games/${gameId}/last-draw-date`);
  }
}

export default ApiInstructions;
