import Api from './Api';

import { ApiDrawInterface } from '../../shared/interfaces/Api';

import config from '../../../../app.config.js';
import { ApiParams } from '../../shared/interfaces/Api/ApiUtils';

class ApiDraw extends Api implements ApiDrawInterface {
  apiUrl = config.APP_URL;

  getDraws(gameId: string): Promise<Response> {
    return this.get(`${this.apiUrl}/api/public/widget/v1/games/${gameId}/draws`);
  }

  getLastDraws(gameId: string, params: ApiParams): Promise<Response> {
    const correctParams = new URLSearchParams({ ...params });
    const url = correctParams.toString();

    return this.get(`${this.apiUrl}/api/public/widget/v1/games/${gameId}/draws-results?${url}`);
  }
}

export default ApiDraw;
