import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import NumberButton from '../NumberButton';

import { classNames, IS_EDITEC_THEME, randomGenerator } from '../../../../../shared/utils';

import LineEntity from '../../../../../lobby-engine/entities/LineEntity';

import close from '../../../../assets/images/close.svg';
import confirm from '../../../../assets/images/check.svg';
import confirmSuccess from '../../../../assets/images/check-success.svg';

import styles from './FastPicker.scss';

import config from '../../../../../../../app.config';

interface FastPickerProps {
  line: LineEntity;
  onCancel(): void;
  onConfirmClick(lineId: string, picked: number[], bonus?: number[]): void;
}

const FastPicker: React.FC<FastPickerProps> = observer((props: FastPickerProps) => {
  const { onCancel, onConfirmClick, line } = props;
  const [pickedNumbers, setPickedNumbers] = useState<number[]>([]);
  const isStateValid = pickedNumbers.length === line.maxPickCount();

  useEffect(() => {
    setPickedNumbers(line.main());
  }, [line.main, line]);

  const checkIsChosenRow = (row: number): boolean => {
    return pickedNumbers[row] !== undefined;
  };

  const onNumberClick = (row: number, value: number): void => {
    const pickedDataCopy = [...pickedNumbers];
    pickedDataCopy[row] = value;
    setPickedNumbers(pickedDataCopy);
  };

  const onQuickPickClick = (): void => {
    const range = line.range();
    const randomizeData = randomGenerator(range.start, range.end, line.maxPickCount(), false);
    setPickedNumbers(randomizeData);
  };

  const onConfirmBtnClick = (): void => {
    onConfirmClick(line.id, pickedNumbers);
  };

  const renderNumbers = (row: number): JSX.Element[] => {
    const range = line.range();
    const calculatedRange = range.end - range.start + 1;

    return [...Array(calculatedRange)].map((elem: undefined, index: number) => {
      const value: number = index + range.start;
      const isActive: boolean = pickedNumbers[row] === value;

      return (
        <React.Fragment key={`${index}`}>
          <NumberButton
            isActive={isActive}
            btnNumber={value}
            width={5}
            onNumberClick={(): void => onNumberClick(row, value)}
            className={styles.fastPickerNumberButton}
            activeClassName={styles.fastPickerNumberButtonActive}
          />
        </React.Fragment>
      );
    });
  };

  const renderRows = (): JSX.Element[] => {
    return [...Array(line.maxPickCount)].map((elem: undefined, index) => {
      const row = index;
      const isChosenInRow = checkIsChosenRow(row);

      return (
        <div key={index} className={styles.fastPickerRow}>
          <p className={styles.fastPickerPickTxt}>
            <FormattedMessage
              id={isChosenInRow ? 'play.bet.text.info.good' : 'fast.numbers.to.pick'}
              values={{ number: 1 }}
            />
          </p>
          <div className={styles.fastPickerNumbersWrapper}>{renderNumbers(row)}</div>
        </div>
      );
    });
  };

  return (
    <div className={styles.fastPickerContainer}>
      <div className={styles.fastPickerHeader}>
        <img
          onClick={(): void => onCancel()}
          className={`${styles.fastPickerIcon} ${styles.fastPickerIconClose}`}
          src={config.CDN_URL + close}
          alt="close"
        />
        <div className={styles.fastPickerHeadTxt}>
          {line.isValid ? (
            <FormattedMessage id={'play.bet.text.info.good'} />
          ) : (
            <FormattedMessage id={'play.cta1'} values={{ number: line.leftToChooseWholeLine() }} />
          )}
        </div>
        <img
          onClick={(): void => onConfirmBtnClick()}
          className={classNames(styles.fastPickerIcon, {
            [styles.fastPickerIconDisabled]: !line.isValid,
          })}
          src={line.isValid ? config.CDN_URL + confirmSuccess : config.CDN_URL + confirm}
          alt="confirm"
        />
      </div>

      <div className={styles.fastPickerBtnWrapper}>
        {IS_EDITEC_THEME && (
          <div className={styles.fastPickerBtnWrapperAdditionalTxt}>
            <FormattedMessage id="fast.line.editec.or" />
          </div>
        )}
        <div onClick={onQuickPickClick} className={styles.fastPickerQuickBtn}>
          {IS_EDITEC_THEME ? (
            <>
              <span className={styles.fastPickerQuickBtnIcon}></span>
              <FormattedMessage id="fast.btn.add" />
            </>
          ) : (
            <FormattedMessage id="fast.btn.quick.pick" />
          )}
        </div>
      </div>
      <div className={styles.fastPickerRows}>{renderRows()}</div>
      <div className={styles.fastPickerBottom}>
        <div
          className={classNames(styles.fastPickerConfirmBtn, {
            [styles.fastPickerConfirmBtnDisabled]: !isStateValid,
          })}
          onClick={onConfirmBtnClick}
        >
          <FormattedMessage id="fast.btn.confirm" />
        </div>
      </div>
    </div>
  );
});

export default FastPicker;
