import { observable } from 'mobx';

abstract class AbstractTimer {
  private timer: NodeJS.Timeout;
  protected intervalTime = 1000;
  @observable timeLeft: number;

  protected clearTimer(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  /* Don't have better idea how to type this callback... */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected setTimer(timerCallback: (...args: any[]) => void): void {
    if (typeof timerCallback === 'function') {
      this.timer = global.setInterval(timerCallback, this.intervalTime);
      return;
    }

    throw new Error('You have to pass callback function!');
  }
}

export default AbstractTimer;
