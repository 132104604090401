import { observable } from 'mobx';
import InstantGameStore from '../stores/InstantGameStore';

import { TICKET_STATUS_DEFAULT, TICKET_STATUS_LOST, TICKET_STATUS_WON } from '../utils/ticketsConstans';

export default class InstantGameStage {
  private readonly DEFAULT_TIMEOUT_TIME = 2000;
  public readonly SINGLE_BALL_TIMEOUT = 1500;
  private instantGameStore: InstantGameStore;
  private stageTimeout: NodeJS.Timeout = null;
  public onTimeoutClear: () => void = null;

  @observable type: 'info' | 'success' | 'danger' = 'info';
  @observable displaySelection = false;
  @observable displayInfo = true;
  @observable displayError = false;
  @observable defaultStage = true;
  @observable isLogicPending = false;
  @observable status = TICKET_STATUS_DEFAULT;

  constructor(instantGameStore: InstantGameStore) {
    this.instantGameStore = instantGameStore;
  }

  private setDefaultStage(): void {
    this.defaultStage = true;
    this.displayError = false;
    this.type = 'info';
    this.status = TICKET_STATUS_DEFAULT;
    this.isLogicPending = false;
    this.setDisplayInfo();
  }

  private setSuccessStage(): void {
    this.defaultStage = false;
    this.type = 'success';
    this.status = TICKET_STATUS_WON;
  }

  private setLostStage(): void {
    this.defaultStage = false;
    this.type = 'danger';
    this.status = TICKET_STATUS_LOST;
  }

  private setDisplayInfo(): void {
    this.displayInfo = true;
    this.displaySelection = false;
  }

  private setAnimationStageTimer(): void {
    //Single ball aniamates for 1500 which has to be synchronised with frontend.
    const timeout =
      this.instantGameStore.ticket.numberOfDrawnBalls * this.SINGLE_BALL_TIMEOUT + 2 * this.SINGLE_BALL_TIMEOUT;
    this.setTimer((): void => {
      this.setDisplayInfo();
      this.startTimeout();
    }, timeout);
  }

  private setPosResultStageTimer(): void {
    this.setTimer((): void => {
      this.setDefaultStage();
      this.startTimeout();
    }, this.DEFAULT_TIMEOUT_TIME);
  }
  private setErrorStageTimer(): void {
    this.setTimer((): void => {
      this.setDefaultStage();
      this.startTimeout();
    }, this.DEFAULT_TIMEOUT_TIME * 2);
  }

  private setTimer(callback: () => void, timeout?: number): void {
    this.clear();
    this.stageTimeout = global.setTimeout(callback, timeout || this.DEFAULT_TIMEOUT_TIME);
  }

  public clear(): void {
    if (typeof this.onTimeoutClear === 'function' && this.defaultStage) {
      this.onTimeoutClear();
      this.onTimeoutClear = null;
    }

    clearTimeout(this.stageTimeout);
  }

  public setErrorStage(): void {
    this.displayError = true;
    this.displayInfo = false;
    this.defaultStage = false;
    this.type = 'danger';
  }

  public setDisplaySelection(): void {
    this.displaySelection = true;
    this.displayInfo = false;
  }

  public setProperStage(status: string): void {
    this.isLogicPending = true;

    if (status === TICKET_STATUS_WON) {
      this.setSuccessStage();

      return;
    } else if (status === TICKET_STATUS_LOST) {
      this.setLostStage();

      return;
    }

    return;
  }

  public startTimeout(): void {
    if (this.defaultStage) {
      return this.clear();
    }

    if (this.displayError) {
      this.setErrorStageTimer();
      return;
    }

    if (this.displaySelection && !this.displayInfo) {
      this.setAnimationStageTimer();
    } else {
      this.setPosResultStageTimer();
    }
  }
}
