import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import { RootStoreContext } from '../../../../lobby-engine/stores/RootStore';
import LotteryEntity from '../../../../lobby-engine/entities/selections/LotteryEntity';
import Lottery from '../Lottery';
import Button from '../../generic/Button/Button';

import { currencyFormatter, getErrorMessage } from '../../../../shared/utils';
import { ERROR_MODAL, SUCCESS_MODAL, PICKER_MODAL } from '../../../../shared/utils/modalNames';

import styles from './BetSlip.scss';
import LineEntity from '../../../../lobby-engine/entities/LineEntity';
/**
 * Used to display a summary of current bet slip in cart enabled themes
 */
const BetSlip: React.FC<{}> = observer(() => {
  const {
    lotteries,
    game: { baseCurrency, postBet, isFastPicker },
    appConfig,
    modal: { showModal, hideModal },
  } = useContext(RootStoreContext);

  const onPayNowButton = async (): Promise<void> => {
    const betInfo = await postBet(appConfig.userToken);

    if (betInfo.isError) {
      const errorData = getErrorMessage(betInfo.statusCode, betInfo.slugCode);
      showModal(ERROR_MODAL, { errorData, code: betInfo.statusCode });
    } else {
      showModal(SUCCESS_MODAL, null);
      lotteries.clearLotteriesList();
    }
  };

  const onConfirmLine = (lottery: LotteryEntity, line: LineEntity): void => {
    lottery.lines.upsertLine(line);
    hideModal();
  };

  const onLineClick = (lottery: LotteryEntity, line: LineEntity): void => {
    const copyLine = lottery.lines.createLine();
    copyLine.copyFromExisting(line);

    showModal(PICKER_MODAL, {
      line: copyLine,
      logo: lottery.image,
      onConfirm: (): void => onConfirmLine(lottery, copyLine),
      size: isFastPicker ? 'small' : 'medium',
    });
  };

  const getTotalPrice = (): string => {
    const amount = lotteries.getTotalPrice();
    const currency = lotteries.getCurrency() || baseCurrency;

    return currencyFormatter(currency, amount, '0.00');
  };

  const renderLotteries = (): JSX.Element[] => {
    return lotteries.lotteriesList.map((lottery: LotteryEntity) => {
      if (lottery.isConfirmed && lottery.isValid) {
        return (
          <Lottery
            {...{ lottery, onLineClick }}
            key={lottery.id}
            onRemove={(): void => lotteries.removeLottery(lottery.id)}
          />
        );
      }

      return null;
    });
  };

  const renderSummary = (): JSX.Element => {
    return (
      <React.Fragment>
        <div className={styles.balanceWrapper}>
          <p className={`${styles.priceTxt} ${styles.priceTxtLighter}`}>
            <FormattedMessage id="betslip.balance" />
          </p>
          <p className={`${styles.priceTxt} ${styles.priceTxtLighter}`}>
            {currencyFormatter(lotteries.getCurrency() || baseCurrency, '', '0.00')}
          </p>
        </div>
        <div className={styles.balanceWrapper}>
          <p className={styles.priceTxt}>
            <FormattedMessage id="betslip.subtotal" />
          </p>
          <p className={styles.priceTxt}>{getTotalPrice()}</p>
        </div>
        <div className={`${styles.balanceWrapper} ${styles.totalWrapper}`}>
          <p className={`${styles.priceTxt} ${styles.priceTxtBigger}`}>
            <FormattedMessage id="betslip.total" />
          </p>
          <p className={`${styles.priceTxt} ${styles.priceTxtBigger}`}>{getTotalPrice()}</p>
        </div>
        <Button onButtonClick={onPayNowButton}>
          <FormattedMessage id="betslip.pay" />
        </Button>
      </React.Fragment>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.lotteriesContainer}>{renderLotteries()}</div>
      <div className={styles.summaryContainer}>{renderSummary()}</div>
    </div>
  );
});

export default BetSlip;
