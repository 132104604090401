import React, { useState } from 'react';

import { classNames } from '../../utils';

import ownStyles from './Input.scss';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  type: 'text' | 'number';
  value?: string | number;
  label?: string | JSX.Element;
  inputClassName?: string;
  containerClassName?: string;
  labelClassName?: string;
  onValueChange?: (value: string) => void;
}

const Input: React.FC<InputProps> = ({
  name,
  label,
  inputClassName,
  labelClassName,
  containerClassName,
  type,
  value,
  onValueChange,
  ...restProps
}: InputProps) => {
  const [stateValue, setStateValue] = useState<string>('');

  const onInputValueChange = (value: string): void => {
    if (typeof onValueChange === 'function') {
      onValueChange(value);
    }

    setStateValue(value);
  };

  return (
    <div
      className={classNames(ownStyles.container, {
        [containerClassName]: typeof containerClassName === 'string',
      })}
    >
      {label && (
        <label
          className={classNames(ownStyles.label, {
            [labelClassName]: typeof labelClassName === 'string',
          })}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <input
        className={classNames(ownStyles.input, {
          [inputClassName]: typeof inputClassName === 'string',
        })}
        onChange={(e): void => onInputValueChange(e.target.value)}
        value={value || stateValue}
        {...{ name, type }}
        {...restProps}
      />
    </div>
  );
};

export default Input;
