import React, { useCallback } from 'react';
import dayjs from 'dayjs';

import { FormattedMessage } from 'react-intl';

import { UserBetDataInterface } from '../../../../../shared/interfaces/GamesPicker';
import { currencyFormatter } from '../../../../../shared/utils';

import styles from './SingleBet.scss';
import betsStyles from '../UserBets.scss';
import Money from '../../../../../lobby-engine/entities/BetCostEntity';

interface SingleBetProps {
  bet: UserBetDataInterface;
  onBetDetailsOpen(id: string, globalIntId?: number, secretCode?: number): void;
}

const SingleBet: React.FC<SingleBetProps> = (props: SingleBetProps) => {
  const { bet, onBetDetailsOpen } = props;

  const onBtnClick = useCallback((): void => {
    onBetDetailsOpen(bet.id, bet.globalIntId, bet.secretCode);
  }, [onBetDetailsOpen, bet.id, bet.globalIntId, bet.secretCode]);

  const getStatusFormatedMessage = (status: string): JSX.Element => {
    if (status) {
      return <FormattedMessage id={`bets.history.details.status.${status}`} />;
    }

    return <FormattedMessage id="bets.history.details.status.default" />;
  };

  const renderBetStatus = (status: string): JSX.Element => {
    const {
      cachedStats: { totalRealWinnings },
    } = bet;
    const winnings = new Money(totalRealWinnings);

    switch (status) {
      case 'won':
        return (
          <p className={styles.informationText}>
            <FormattedMessage id="bets.player.winnings" />
            <span className={betsStyles.statusWon}>
              {currencyFormatter(winnings.currency, winnings.getHumanAmount())}
            </span>
          </p>
        );
      case 'pending':
        return (
          <p className={styles.informationText}>
            <FormattedMessage id="bets.player.ticket.p6" />
            <span className={betsStyles.statusWarning}>{getStatusFormatedMessage(bet.status)}</span>
          </p>
        );
      case 'placed':
        return (
          <p className={styles.informationText}>
            <FormattedMessage id="bets.player.ticket.p6" />
            <span className={betsStyles.statusPlaced}>{getStatusFormatedMessage(bet.status)}</span>
          </p>
        );
      default:
        return (
          <p className={styles.informationText}>
            <FormattedMessage id="bets.player.ticket.p6" />
            <span className={betsStyles.statusAlert}>{getStatusFormatedMessage(bet.status)}</span>
          </p>
        );
    }
  };

  return (
    <div className={styles.betContainer}>
      <div className={styles.betInformation}>
        <div className={styles.informationColumn}>
          <p className={styles.informationText}>
            <FormattedMessage
              id="bets.history.details.status6"
              values={{ date: dayjs(bet.createdAt).format('DD/MM/YY HH:mm') }}
            />
          </p>
          <p className={styles.informationText}>
            <span className={styles.informationTextBold}>{bet.gameDraw.game.displayedName}</span>
          </p>
        </div>
        <div className={styles.informationColumn}>
          <p className={styles.informationText}>
            <FormattedMessage
              id="bets.history.details.status7"
              values={{ date: dayjs(bet.gameDraw.drawDatetime).format('DD/MM/YY HH:mm') }}
            />
          </p>
          {renderBetStatus(bet.status)}
        </div>
      </div>
      <div onClick={onBtnClick}>
        <div className={`${styles.commonBtn} ${styles.betBtn} ${betsStyles.betsBtn}`}>
          <FormattedMessage id="bets.history.btn" />
        </div>
        <div className={styles.stepNext}>
          <span className={styles.stepNextIcon}></span>
        </div>
      </div>
    </div>
  );
};

export default SingleBet;
