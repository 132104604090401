import { ErrorMessage } from '../interfaces';

export class ILSError extends Error implements ErrorMessage {
  code: string;
  statusCode: number;
  message: string;
  slugCode?: string;

  constructor(message: ErrorMessage) {
    super(message.message);
    this.code = message.code;
    this.statusCode = message.statusCode;
    this.slugCode = message.slugCode;
  }
}
