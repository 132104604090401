import React from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import NumberPicker from '../NumberPicker';

import { GameChancesDropdownInterface } from '../../../../../shared/interfaces/GamesPicker';

import styles from '../NumberPicker/NumberPicker.scss';
import gameStyles from '../../Game/Game.scss';
import LinesStore from '../../../../../lobby-engine/stores/LinesStore';
import LineEntity from '../../../../../lobby-engine/entities/LineEntity';

interface NumberPickerLinesProps {
  dropdownOptions: GameChancesDropdownInterface[];
  lines: LinesStore;
  dropdownRender: boolean;
}

const NumberPickerLines: React.FC<NumberPickerLinesProps> = observer((props: NumberPickerLinesProps) => {
  const { lines, dropdownRender, dropdownOptions } = props;

  const renderLines = (): JSX.Element => {
    return (
      <div className={styles.numberLineContainer}>
        {lines.linesList.map((item: LineEntity) => {
          return (
            <NumberPicker
              isBetPermutated={lines.isBetPermutated()}
              key={item.id}
              line={item}
              {...{
                dropdownOptions,
                dropdownRender,
              }}
            />
          );
        })}
      </div>
    );
  };

  return (
    <React.Fragment>
      {renderLines()}
      <div className={gameStyles.btnLinesWrapper}>
        {lines.linesList.length > 1 && (
          <div className={`${gameStyles.btnLines} ${gameStyles.btnRemove}`} onClick={(): void => lines.removeLine()}>
            <FormattedMessage id="play.btn.remove.lines" />
          </div>
        )}
        {lines.linesCount !== lines.maxLinesPerBet && (
          <div className={`${gameStyles.btnLines} ${gameStyles.btnAdd}`} onClick={(): void => lines.addLine()}>
            <FormattedMessage id="play.btn.add.lines" />
          </div>
        )}
      </div>
    </React.Fragment>
  );
});

export default NumberPickerLines;
