import { observable, action, computed } from 'mobx';

import {
  LinesStoreInterface,
  LinePlainInterface,
  PermsPlainInterface,
} from '../../shared/interfaces/Stores/LinesInterface';

import lineFactory from '../services/LineFactory';
import LineEntity from '../entities/LineEntity';
import { ClassSerializer } from '../../shared/interfaces/ClassSerializer';
import { IntegerSelection } from '../entities/selections/IntegerSelection';
import { RangeSelection } from '../entities/selections/RangeSelection';

export default class LinesStore implements LinesStoreInterface, ClassSerializer {
  @observable linesList: LineEntity[] = [];
  @observable linesCountToAdd = 1;
  @observable selectionParts: Array<IntegerSelection | RangeSelection>;
  permutationsEnabled: boolean;
  maxLinesPerBet: number;
  lotteryName: string;

  @action
  addLine = (isFilled = false, linesCount = 0): void => {
    if (this.maxLinesPerBet <= this.linesList.length) {
      return;
    }

    // eslint-disable-next-line max-len
    const linesToAdd =
      this.maxLinesPerBet - this.linesCount > this.linesCountToAdd
        ? this.linesCountToAdd
        : this.maxLinesPerBet - this.linesCount;
    const desiredLinesToAdd = linesCount || linesToAdd;

    const newLines = [...Array(desiredLinesToAdd)].map(() => {
      return this.createLine(isFilled);
    });

    this.linesList = this.linesList.concat(newLines);
  };

  /**
   * Returns number of lines
   */
  get linesCount(): number {
    return this.linesList.length;
  }
  /**
   * Returns true if max limit of lines is reached
   */
  get areLinesMax(): boolean {
    return this.maxLinesPerBet <= this.linesCount;
  }

  /**
   * some lotteries required some filled lines on start of application
   * also for instant bets we need action for reset lines.
   * Default linesCount set to 2.
   * @param linesCount
   */
  setNewLines = (linesCount = 2): void => {
    this.clearLines();
    this.addLine(true, linesCount);
  };

  /**
   * Searches current lines for a line with matching line.id
   * If found, replaces the line.
   * If not, adds as new line.
   * @param line
   */
  upsertLine(newLine: LineEntity): void {
    let lineExits = false;
    this.linesList.forEach((line, index) => {
      if (line.id == newLine.id) {
        this.linesList[index] = newLine;
        lineExits = true;
      }
    });
    if (!lineExits) {
      this.linesList.push(newLine);
    }
  }

  /**
   * Creates new line.
   * @param isFilled Defines if new line should be randomized on creation
   */
  createLine = (isFilled = false): LineEntity => {
    const line = lineFactory(this.lotteryName, this.selectionParts, this.permutationsEnabled);

    if (isFilled) {
      line.randomizeChoice();
    }

    return line;
  };

  @action
  removeLine = (id = ''): void => {
    if (id) {
      this.linesList = this.linesList.filter((elem: LineEntity) => elem.id !== id);
    } else {
      const startingIndex = this.linesCount - this.linesCountToAdd;
      this.linesList.splice(startingIndex, this.linesCount);
    }
  };

  @action
  overrideLine = (lineId: string, cpLineId: string, deletion = false): void => {
    this.linesList.forEach((elem: LineEntity) => {
      if (elem.id === lineId) {
        elem.selections = this.getLine(cpLineId).selections;
      }
    });

    if (deletion) {
      this.removeLine(cpLineId);
    }
  };

  @action
  clearLines = (): void => {
    this.linesList = [];
    this.linesCountToAdd = 1;
  };

  @computed
  get validLinesCount(): number {
    return this.linesList.reduce((acc, cur) => (cur.isValid ? ++acc : acc), 0);
  }

  get validLinesList(): LineEntity[] {
    return this.linesList.filter((line) => line.isValid);
  }

  get realValidLinesCount(): number {
    return this.linesList.reduce((acc, cur) => {
      return acc + cur.realLinesCount;
    }, 0);
  }

  getLine(lineId: string): LineEntity {
    return this.linesList.find((elem) => elem.id === lineId);
  }

  setMaxLinesValue(maxLines: number): void {
    this.maxLinesPerBet = maxLines;
  }

  setSelectionParts(selectionParts: Array<IntegerSelection | RangeSelection>): void {
    this.selectionParts = selectionParts;
  }

  setLotteryName(lotteryName: string): void {
    this.lotteryName = lotteryName;
  }

  setLinesCountToAdd(linesCountToAdd: number): void {
    this.linesCountToAdd = linesCountToAdd;
  }

  setPermutationsEnabled(permutationsEnabled: boolean): void {
    this.permutationsEnabled = permutationsEnabled;
  }

  isBetPermutated(): boolean {
    return this.linesList.some((elem: LineEntity) => elem.isPermutationOn);
  }

  toPlain(): { lines: LinePlainInterface[]; permutations?: PermsPlainInterface[] } {
    const lines: LinePlainInterface[] = [];
    const permutations: PermsPlainInterface[] = [];
    this.validLinesList.forEach((line) => {
      if (line.isPermutationOn) {
        permutations.push(line.toPlain());
      } else {
        lines.push(line.toPlain() as LinePlainInterface);
      }
    });
    return { lines, permutations };
  }
}
