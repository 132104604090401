import { IS_LUCKYBALLS_CART } from './themes';
import { pageNames } from '.';

import { UserBetTabInterface } from '../interfaces';

export const tabs: UserBetTabInterface[] = [
  {
    name: pageNames.BET_SLIP,
    messageId: 'bets.slip.title',
    display: IS_LUCKYBALLS_CART,
  },
  {
    name: pageNames.USER_BETS_ACTIVE,
    messageId: 'bets.active.title',
    display: !IS_LUCKYBALLS_CART,
  },
  {
    name: pageNames.USER_BETS_HISTORY,
    messageId: 'bets.history.title',
    display: true,
  },
];
