import React from 'react';

import styles from './Icon.scss';

interface IconProps {
  children: string;
  iconClassName?: string;
}

const Icon: React.FC<IconProps> = ({ children, iconClassName = '' }: IconProps) => {
  return <span className={`${styles.icon} ${iconClassName}`}>{children}</span>;
};

export default Icon;
