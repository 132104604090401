import { ApiLinesInterface } from '../../shared/interfaces/Api';
import { BetDetailsLinesInterface, BetDetailsPermsInterface } from '../../shared/interfaces/GamesPicker';

class ApiLinesConnector {
  public api: ApiLinesInterface;

  public constructor(api: ApiLinesInterface) {
    this.api = api;
  }

  async getBetLines(userToken: string, betId: string, gameId: string): Promise<BetDetailsLinesInterface[]> {
    const response = await this.api.getBetLines(userToken, betId, gameId);

    if (!response.ok) {
      throw await response.json();
    }

    return await response.json();
  }

  async getBetPermutations(userToken: string, betId: string, gameId: string): Promise<BetDetailsPermsInterface[]> {
    const response = await this.api.getBetPermutations(userToken, betId, gameId);

    if (!response.ok) {
      throw await response.json();
    }

    return await response.json();
  }
}

export default ApiLinesConnector;
