exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1gHoH-z9fpbtWEGiI0OPQc{display:flex}._1A3TFfR2NjGFHAbZYCCzb{display:flex}._1E62zU4Hd4nvNX8C_TX4TC{border-radius:50% !important;width:35px;height:35px;font-weight:600;font-size:1rem;display:flex;justify-content:center;align-items:center;margin:0 2px}._2HWaw9ggVqzl8LZIdnWaHp{animation:_2p92MPdXgfnriBzTpIkuix 1.5s ease}@keyframes _2p92MPdXgfnriBzTpIkuix{0%{transform:rotate(-3000deg);opacity:0}35%{opacity:1}100%{transform:rotate(0)}}", ""]);

// Exports
exports.locals = {
	"selection": "_1gHoH-z9fpbtWEGiI0OPQc",
	"numbers": "_1A3TFfR2NjGFHAbZYCCzb",
	"number": "_1E62zU4Hd4nvNX8C_TX4TC",
	"numberAnimation": "_2HWaw9ggVqzl8LZIdnWaHp",
	"balls-animation": "_2p92MPdXgfnriBzTpIkuix"
};