import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import Button from '../../../generic/Button/Button';

import { currencyFormatter, classNames, firstLetterFormatter, IS_RETAIL } from '../../../../../shared/utils';
import { TICKET_MODAL } from '../../../../../shared/utils/modalNames';
import { BET_STATUS_WON } from '../../../../../shared/utils/constans/bet';

import { BetDetailsInterface } from '../../../../../shared/interfaces/GamesPicker';

import ResultEntity from '../../../../../lobby-engine/entities/ResultEntity';
import ResultSelection from '../../../../../lobby-engine/entities/selections/ResultSelection';
import Money from '../../../../../lobby-engine/entities/BetCostEntity';
import { RootStoreContext } from '../../../../../lobby-engine/stores/RootStore';

import styles from './SingleBetDetails.scss';
import numbersStyles from '../../../../assets/styles/numbers.scss';

interface PropsInterface {
  betDetails: BetDetailsInterface;
  userSelection: ResultEntity[];
  betResults: ResultEntity;
  onBetDetailsClose(): void;
}

const SingleBetDetails: React.FC<PropsInterface> = observer((props: PropsInterface) => {
  const { modal } = useContext(RootStoreContext);
  const { betDetails, onBetDetailsClose, userSelection, betResults } = props;
  const { totalRealCost, totalRealWinnings, linesCount, totalRealWithholdingTax } = betDetails.cachedStats;
  const totalWinnings = new Money(totalRealWinnings);
  const totalCost = new Money(totalRealCost);
  const tax = new Money(totalRealWithholdingTax);

  const renderNumbers = (selection: ResultSelection, line: number, isPerm: boolean): JSX.Element[] => {
    return selection.numbers.map((selectedNumber: number, index: number) => {
      const isWonNumber = isPerm ? selection.winnings.includes(selectedNumber) : selection.winnings.includes(index + 1);

      if (!selectedNumber && typeof selectedNumber !== 'number') {
        return null;
      }

      if (selection.isSubType) {
        return (
          <div className={styles.commonText} key={`subType-${index}-${selectedNumber}`}>
            {selectedNumber}
          </div>
        );
      }

      return (
        <div
          key={`user-numbers-${index}-${selectedNumber}`}
          className={classNames(numbersStyles.betNumber, {
            [numbersStyles.betNumberWon]: isWonNumber,
          })}
        >
          {selectedNumber}
        </div>
      );
    });
  };

  const isPaid = (): boolean => {
    return betDetails.paidOutAt != null;
  };

  const renderPermutationWinnings = (selection: ResultEntity): JSX.Element => {
    if (selection.isPerm && selection.lineWinnings) {
      const winnings = new Money(selection.lineWinnings);

      return (
        <div className={styles.permutationWinningWrapper}>
          <p className={styles.commonText}>
            <FormattedMessage id="bets.player.perm.winnings" />
            {currencyFormatter(winnings.currency, winnings.getHumanAmount())}
          </p>
        </div>
      );
    }

    return null;
  };

  const renderLines = (): JSX.Element[] => {
    return userSelection.map((selection: ResultEntity, index: number) => {
      const line = selection.selectionsArray;

      return (
        <div className={styles.numbersWrapper} key={selection.id}>
          <div className={styles.lineWrapper}>
            {line.map((selectionElem: ResultSelection) => {
              return (
                <div
                  key={`${selection.id}-${selectionElem.name}`}
                  className={classNames(numbersStyles.betNumbers, {
                    [styles.gameTypeWrapper]: selectionElem.name !== 'main',
                  })}
                >
                  {renderNumbers(selectionElem, index, selection.isPerm)}
                </div>
              );
            })}
          </div>
          {renderPermutationWinnings(selection)}
        </div>
      );
    });
  };

  const renderResults = (): JSX.Element => {
    if (!betResults) {
      return null;
    }

    return (
      <div className={styles.numbersWrapper}>
        {betResults.selectionsArray.map((selection: ResultSelection, index: number) => {
          return (
            <div
              key={`${betResults.id}-${selection.name}`}
              className={classNames(numbersStyles.betNumbers, {
                [styles.gameTypeWrapper]: selection.name !== 'main',
              })}
            >
              {selection.numbers.map((elem: number, numberIndex: number) => {
                return (
                  <div key={`results-${index}-${elem}-${numberIndex}`} className={numbersStyles.betNumber}>
                    {elem}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const renderSelectionHeader = (): JSX.Element[] | JSX.Element => {
    if (userSelection.length === 0) {
      return <p className={`${styles.commonText} ${styles.sectionNameText}`}>Main</p>;
    }

    return userSelection[0].selectionsArray.map((elem: ResultSelection) => {
      return (
        <p key={`${userSelection[0].id}-${elem.name}`} className={`${styles.commonText} ${styles.sectionNameText}`}>
          {firstLetterFormatter(elem.name)}
        </p>
      );
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderPrintButton = (): JSX.Element[] | JSX.Element => {
    return (
      <Button
        btnClassName={`${styles.retailBtn} ${styles.print}`}
        onButtonClick={(): void => modal.showModal(TICKET_MODAL, { isPayout: false, isCopy: true })}
      >
        <FormattedMessage id="retail.bet.btn.print" />
      </Button>
    );
  };

  const renderPayOutButton = (): JSX.Element[] | JSX.Element => {
    return (
      <Button
        btnClassName={`${styles.retailBtn}`}
        onButtonClick={(): void => modal.showModal(TICKET_MODAL, { isPayout: true })}
        type={isPaid() ? 'inactive' : 'default'}
      >
        <FormattedMessage id="retail.bet.btn.payout" />
      </Button>
    );
  };

  /**
   * Commented as not used now, and as we might use it in future once we will know how.
   */
  // const renderCancelButton = (): JSX.Element[] | JSX.Element => {
  //   return (
  //     <Button
  //       btnClassName={`${styles.retailBtn} ${styles.cancel}`}
  //       onButtonClick={(): void => modal.showModal(TICKET_SUMMARY, { cancellation: true })}
  //     >
  //       <FormattedMessage id="retail.bet.btn.cancel" />
  //     </Button>
  //   )
  // }

  const renderRetailButtons = (): JSX.Element => {
    //Don't render buttons if not in retail mode
    if (!IS_RETAIL) return;

    //Render buttons othervise
    if (IS_RETAIL) {
      return (
        <div className={styles.retailBtnContainer}>
          {
            //renderPrintButton()
          }
          {betDetails.status === BET_STATUS_WON && renderPayOutButton()}
        </div>
      );
    }
  };

  const getStatusFormatedMessage = (status: string): JSX.Element => {
    if (status) {
      return <FormattedMessage id={`bets.history.details.status.${status}`} />;
    }

    return <FormattedMessage id="bets.history.details.status.default" />;
  };

  return (
    <div>
      <div className={styles.betDetailsContainer}>
        <div className={styles.betDetailsHeader}>
          <div className={styles.stepBack} onClick={onBetDetailsClose}>
            <span className={styles.stepBackIcon}></span>
          </div>
          <p className={`${styles.commonText} ${styles.headerText}`}>
            <FormattedMessage id="bets.history.ticket" />
          </p>
        </div>
        <div className={styles.betDetailsContent}>
          <div
            className={classNames(styles.betDetailsSection, {
              [styles.betDetailsSectionW100]: !betDetails.gameDraw.wonBetSelection,
            })}
          >
            <div className={`${styles.sectionDetailsWrapper} ${styles.sectionGameDrawTitle}`}>
              <img
                className={styles.gameLogo}
                src={betDetails.gameDraw.game.image ? betDetails.gameDraw.game.image.url : ''}
                alt="logo"
              />
              <div className={styles.gameInformation}>
                <p className={`${styles.commonText} ${styles.gameName}`}>{betDetails.gameDraw.game.displayedName}</p>
                <p className={`${styles.commonText} ${styles.gameDate}`}>
                  <FormattedMessage id="bets.draw.date" />
                  <span>&nbsp;{dayjs(betDetails.gameDraw.drawDatetime).format('DD/MM/YY HH:mm')}</span>
                </p>
              </div>
            </div>
          </div>
          {betDetails.gameDraw.wonBetSelection && (
            <div className={styles.betDetailsSection}>
              <div className={styles.sectionName}>
                <p className={`${styles.commonText} ${styles.sectionNameText}`}>
                  <FormattedMessage id="bets.results" />
                </p>
              </div>
              <div className={styles.sectionDetailsWrapper}>{renderResults()}</div>
            </div>
          )}
          <div className={styles.betDetailsSection}>
            <div className={`${styles.sectionName} ${styles.sectionNameFlex}`}>{renderSelectionHeader()}</div>
            <div className={styles.sectionDetailsWrapper}>
              {renderLines()}
              {totalRealWinnings && (
                <p className={`${styles.commonText} ${styles.commonTextMY}`}>
                  <FormattedMessage id="bets.player.winnings" />
                  <span className={styles.statusWon}>
                    {currencyFormatter(totalWinnings.currency, totalWinnings.getHumanAmount())}
                  </span>
                </p>
              )}
            </div>
          </div>
          <div className={styles.betDetailsSection}>
            <div className={styles.sectionName}>
              <p className={`${styles.commonText} ${styles.sectionNameText}`}>
                <FormattedMessage id="bets.player.ticket.title" />
              </p>
            </div>
            <div className={styles.sectionDetailsWrapper}>
              <div className={styles.ticketDetailsWrapper}>
                <p className={`${styles.commonText} ${styles.ticketDetailsText} ${styles.ticketDetailsTextWidth}`}>
                  <FormattedMessage id="bets.player.ticket.draw" />
                </p>
                <p className={`${styles.commonText} ${styles.ticketDetailsText}`}>{betDetails.gameDraw.drawNumber}</p>
              </div>
              <div className={styles.ticketDetailsWrapper}>
                <p className={`${styles.commonText} ${styles.ticketDetailsText} ${styles.ticketDetailsTextWidth}`}>
                  <FormattedMessage id="bets.player.ticket.p1" />
                </p>

                <p className={`${styles.commonText} ${styles.ticketDetailsText} ${styles.ticketDetailsLongText}`}>
                  {betDetails.gameDraw.game.displayedName}
                </p>
              </div>
              <div className={styles.ticketDetailsWrapper}>
                <p className={`${styles.commonText} ${styles.ticketDetailsText} ${styles.ticketDetailsTextWidth}`}>
                  <FormattedMessage id="bets.player.ticket.p2" />
                </p>
                <p className={`${styles.commonText} ${styles.ticketDetailsText} ${styles.ticketDetailsLongText}`}>
                  {betDetails.id}
                </p>
              </div>
              <div className={styles.ticketDetailsWrapper}>
                <p className={`${styles.commonText} ${styles.ticketDetailsText} ${styles.ticketDetailsTextWidth}`}>
                  <FormattedMessage id="bets.player.ticket.p3" />
                </p>
                <p className={`${styles.commonText} ${styles.ticketDetailsText} ${styles.ticketDetailsLongText}`}>
                  {linesCount}
                </p>
              </div>
              <div className={styles.ticketDetailsWrapper}>
                <p className={`${styles.commonText} ${styles.ticketDetailsText} ${styles.ticketDetailsTextWidth}`}>
                  <FormattedMessage id="bets.player.info.p1" />
                </p>
                <p className={`${styles.commonText} ${styles.ticketDetailsText}`}>
                  {betDetails.idInTransaction} of {betDetails.betsCountInTransaction}
                </p>
              </div>
              <div className={styles.ticketDetailsWrapper}>
                <p className={`${styles.commonText} ${styles.ticketDetailsText} ${styles.ticketDetailsTextWidth}`}>
                  <FormattedMessage id="bets.player.ticket.p5" />
                </p>
                <p className={`${styles.commonText} ${styles.ticketDetailsText} ${styles.ticketDetailsLongText}`}>
                  {currencyFormatter(totalCost.currency, totalCost.getHumanAmount())}
                </p>
              </div>
              <div className={styles.ticketDetailsWrapper}>
                <p className={`${styles.commonText} ${styles.ticketDetailsText} ${styles.ticketDetailsTextWidth}`}>
                  <FormattedMessage id="bets.player.ticket.p7" />
                </p>
                <p className={`${styles.commonText} ${styles.ticketDetailsText} ${styles.ticketDetailsLongText}`}>
                  {dayjs(betDetails.createdAt).format('DD/MM/YY HH:mm')}
                </p>
              </div>
              {isPaid() && (
                <div className={styles.ticketDetailsWrapper}>
                  <p className={`${styles.commonText} ${styles.ticketDetailsText} ${styles.ticketDetailsTextWidth}`}>
                    <FormattedMessage id="bets.player.ticket.paid.out.at" />
                  </p>
                  <p className={`${styles.commonText} ${styles.ticketDetailsText} ${styles.ticketDetailsLongText}`}>
                    {dayjs(betDetails.paidOutAt).format('DD/MM/YY HH:mm')}
                  </p>
                </div>
              )}

              <div className={styles.ticketDetailsWrapper}>
                <p className={`${styles.commonText} ${styles.ticketDetailsText} ${styles.ticketDetailsTextWidth}`}>
                  <FormattedMessage id="bets.player.ticket.p6" />
                </p>
                <p className={`${styles.commonText} ${styles.ticketDetailsText} ${styles.ticketDetailsLongText}`}>
                  {getStatusFormatedMessage(betDetails.status)}
                  {isPaid() && (
                    <>
                      , <FormattedMessage id="bets.history.details.status.paid" />
                    </>
                  )}
                </p>
              </div>
              {tax.getHumanAmount() !== 0 && (
                <div className={styles.ticketDetailsWrapper}>
                  <p className={`${styles.commonText} ${styles.ticketDetailsText} ${styles.ticketDetailsTextWidth}`}>
                    <FormattedMessage id="bets.player.ticket.p9" />
                  </p>
                  <p className={`${styles.commonText} ${styles.ticketDetailsText} ${styles.ticketDetailsLongText}`}>
                    {currencyFormatter(tax.currency, tax.getHumanAmount(), '0.00')}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {renderRetailButtons()}
    </div>
  );
});

export default SingleBetDetails;
