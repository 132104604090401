export const frankCurrencyFormatter = (currency: string): string => (currency === 'XAF' ? 'F CFA' : currency);

export const getHumanAmount = (amount: number): number => amount / 100;

export const currencyFormatter = (
  currency: string,
  value: string | number,
  placeholder: string | number = ''
): string => {
  return `${value ? Number(value).toLocaleString() : placeholder} ${frankCurrencyFormatter(currency)} `;
};

export const moneyWithoutCurrency = (value: string | number): string => {
  return Number(value).toLocaleString();
};
