import { action, observable } from 'mobx';

import LotteryEntity from '../entities/selections/LotteryEntity';
import { TicketPlainInterface } from '../../shared/interfaces/Stores';
import { LotteryDataInterface } from '../../shared/interfaces/Stores/LotteriesStoreInterface';

export default class LotteriesStore {
  @observable lotteriesList: LotteryEntity[] = [];

  addLottery(lottery: LotteryDataInterface): void {
    this.lotteriesList.push(new LotteryEntity(lottery));
  }

  removeLottery(id: string): void {
    this.lotteriesList = this.lotteriesList.filter((elem: LotteryEntity) => id !== elem.id);
  }

  clearLotteriesList(): void {
    this.lotteriesList = [];
  }

  @action getCurrentLottery(): LotteryEntity {
    return this.lotteriesList[this.lotteriesList.length - 1];
  }

  getTotalPrice(): number {
    const amount = this.lotteriesList.reduce((acc: number, cur: LotteryEntity): number => {
      if (cur.ticket) {
        return acc + cur.ticket.betCost.getHumanAmount();
      }

      return acc + 0;
    }, 0);

    return amount;
  }

  getCurrency(): string {
    if (this.lotteriesList.length === 0) {
      return '';
    }

    return this.lotteriesList[0].ticket.betCost.currency;
  }

  toPlain(): TicketPlainInterface[] {
    return this.lotteriesList.map((elem: LotteryEntity) => elem.toPlain());
  }
}
