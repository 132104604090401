const randomGenerator = (min: number, max: number, count: number, unique: boolean): number[] => {
  const pickNumbers: number[] = [];

  for (let i = 0; i < count; i++) {
    let ranNum: number = Math.random() * (max - min) + min;

    while (unique && pickNumbers.indexOf(Math.round(ranNum)) > -1) {
      ranNum = Math.random() * (max - min) + min;
    }
    pickNumbers[i] = Math.round(ranNum);
  }
  return pickNumbers;
};

export default randomGenerator;
