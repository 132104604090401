import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import { RootStoreContext } from '../../lobby-engine/stores/RootStore';

import SingleBetDetails from '../components/GamesPicker/UserBets/SingleBetDetails';
import Spinner from '../../shared/components/Spinner';

interface BetDetailsContainerProps {
  onBetDetailsClose(): void;
  gameId: string;
}

const BetDetailsContainer: React.FC<BetDetailsContainerProps> = observer((props: BetDetailsContainerProps) => {
  const { onBetDetailsClose } = props;
  const betDetailsContext = useContext(RootStoreContext).betDetails;
  const userSelection = betDetailsContext.userSelection;
  const betResults = betDetailsContext.betResults;

  // useEffect(() => {
  //   betDetailsContext.fetchBetLines();
  //   betDetailsContext.fetchBetPermutations();

  //   return ((): void => {
  //     betDetailsContext.clearBetDetails();
  //     betDetailsContext.saveSearchableId(null);
  //   });
  // }, [betDetailsContext, userToken, gameId]);

  const renderContent = (): JSX.Element => {
    const { betDetails, error, isLoading, lines } = betDetailsContext;

    if (lines.isLoading || isLoading) {
      return <Spinner />;
    }

    if (error) {
      return <React.Fragment>{`${error.message}`}</React.Fragment>;
    }

    if (!betDetails || !lines.data) {
      return <>No bet details</>;
    }

    return <SingleBetDetails {...{ betDetails, userSelection, onBetDetailsClose, betResults }} />;
  };

  return <React.Fragment>{renderContent()}</React.Fragment>;
});

export default BetDetailsContainer;
