import React from 'react';

import ModalConnector from '../../../shared/components/Modals/ModalConnector';
import { Modal } from '../generic';

import { TICKET_SUMMARY_MODAL } from '../../../shared/utils/modalNames';
import { ModalInterface } from '../../../shared/interfaces/Lobby/ModalsInterface';

import ownStyles from './TicketSummaryModal.scss';
import TicketSummaryContainer from '../../containers/TicketSummaryContainer';

interface TicketSummaryModalProps extends ModalInterface {
  data: {
    cancellation?: boolean;
  };
}

const TicketSummaryModal: React.FC<{}> = () => {
  return (
    <ModalConnector name={TICKET_SUMMARY_MODAL}>
      {({ isOpen, data, onClose }: TicketSummaryModalProps): JSX.Element => {
        return (
          <Modal onClose={onClose} {...{ isOpen }} contentOutside modalClassName={ownStyles.container}>
            <TicketSummaryContainer onBackClick={onClose} cancellation={data.cancellation} />
          </Modal>
        );
      }}
    </ModalConnector>
  );
};

export default TicketSummaryModal;
