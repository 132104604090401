/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { QrScanner } from '@yudiel/react-qr-scanner';
import pageNames from '../../../../shared/utils/pageNames';

import ownStyles from './ScanBarrcode.scss';

import { RootStoreContext } from '../../../../lobby-engine/stores/RootStore';
import { observer } from 'mobx-react';
import Spinner from '../../../../shared/components/Spinner';
import Button from '../../generic/Button/Button';
import Input from '../../../../shared/components/Input/Input';

const ScanBarrcode: React.FC<{}> = observer(() => {
  const { betDetails, navigation } = useContext(RootStoreContext);
  const [isParsingScan, setIsParsingScan] = useState<boolean>(false);
  const [scanError, setScanError] = useState<string | null>(null);
  const [manualTicketId, setManualTicketId] = useState<string>('');

  const intl = useIntl();
  const placeholderMessage = intl.formatMessage({ id: 'retail.scan.manual.placeholder' });

  const handleError = (): void => {
    setScanError('retail.scanning.error');
  };

  useEffect(() => {
    if (scanError != null)
      setTimeout(() => {
        setScanError(null);
      }, 3 * 1000);
  }, [scanError]);

  const handleManualTicketIdChange = (data: string): void => {
    const cleanData = data.replace(/\s/g, '');
    setManualTicketId(cleanData);
  };

  const handleManualEntry = async (): Promise<void> => {
    return await handleScan(manualTicketId);
  };

  const handleScan = async (data: string): Promise<void> => {
    //Don't work if we're already working
    if (!isParsingScan) {
      setIsParsingScan(true);
      data = data.replace('|', '#');
      const elements = data.split('#');
      if (elements.length != 2) {
        setIsParsingScan(false);
        setScanError('retail.scan.error.wrong_format');

        return;
      }
      try {
        await betDetails.fetchBetByKeySecret(elements[0], elements[1]);
        navigation.changePage(pageNames.USER_BETS_SINGLE);
        setIsParsingScan(false);
        setScanError(null);
      } catch (error) {
        if (error.statusCode && error.statusCode == 403) {
          if (error.message == 'Ticket expired') {
            setScanError('retail.scan.error.ticket_expired');
          } else if (error.message == 'Forbidden') {
            setScanError('retail.scan.error.forbidden');
          } else {
            setScanError('retail.scan.error.other');
          }
        } else {
          setScanError('retail.scan.error.wrong_bet_id');
        }
        setIsParsingScan(false);
      }
    }
  };

  return (
    <div className={ownStyles.container}>
      <div className={ownStyles.cameraContainer}>
        <div className={ownStyles.cameraView}>
          <QrScanner onDecode={handleScan} onError={handleError} stopDecoding={isParsingScan} />
        </div>
      </div>
      <div className={ownStyles.error}>&nbsp;{scanError && <FormattedMessage id={scanError} />}</div>

      <div className={ownStyles.manualInputContainer}>
        <label>
          <FormattedMessage id={'retail.scan.manual.label'} />
        </label>
        <Input
          onValueChange={(value: string): void => handleManualTicketIdChange(value)}
          placeholder={placeholderMessage}
          inputClassName={ownStyles.manualInput}
          containerClassName={ownStyles.manualInputFormContainer}
          name={'ManualTicketId'}
          type={'text'}
        ></Input>
        {betDetails.isLoading == true ? (
          <Spinner />
        ) : (
          <Button onButtonClick={handleManualEntry}>
            <FormattedMessage id={'retail.scan.manual.button'} />
          </Button>
        )}
      </div>
    </div>
  );
});

export default ScanBarrcode;
