import ApiLobbyInterface from '../../shared/interfaces/Api/ApiLobbyInterface';
import { LobbyInterface } from '../../shared/interfaces/Lobby/LobbyInterface';

class ApiLobbyConnector {
  public api: ApiLobbyInterface;

  public constructor(api: ApiLobbyInterface) {
    this.api = api;
  }

  public getLobby(lobbyId: string): Promise<LobbyInterface> {
    return this.api.getLobby(lobbyId).then((response) => {
      if (!response.ok) {
        throw Error('LOBBY_NOT_FOUND');
      }
      return response.json().then((data) => ({
        data,
      }));
    });
  }
}

export default ApiLobbyConnector;
