import { NOT_ENOUGH_MONEY } from '.';

interface CallbackData {
  cb: null | CallableFunction;
  txt: string;
}

interface CallbacksInterface {
  loginCb?: CallableFunction;
  noMoneyCb?: CallableFunction;
}

const getErrorCallback = (code: number, slug: string, callbacks: CallbacksInterface): CallbackData => {
  if (code === 401) {
    return {
      cb: callbacks.loginCb,
      txt: 'error.callback.login.txt',
    };
  }

  if (code === 400 && slug === NOT_ENOUGH_MONEY) {
    return {
      cb: callbacks.noMoneyCb,
      txt: 'error.callback.deposit.txt',
    };
  }

  return { cb: null, txt: '' };
};

export default getErrorCallback;
