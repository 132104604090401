import React, { useEffect, useContext } from 'react';
import { observer } from 'mobx-react';

import Spinner from '../../shared/components/Spinner';
import LotteryContainer from './LotteryContainer';

import { RootStoreContext } from '../../lobby-engine/stores/RootStore';

import styles from '../components/GamesPicker/Game/Game.scss';
import ErrorPage from '../../shared/components/Error';

interface GameContainerProps {
  gameId: string;
  onClose(): void;
}

const GameContainer: React.FC<GameContainerProps> = observer((props: GameContainerProps) => {
  const { onClose } = props;
  const game = useContext(RootStoreContext).game;
  const lotteries = useContext(RootStoreContext).lotteries;
  const gameStructure = game.gameStructure.data;
  const error = game.gameStructure.error;
  const isLoading = game.gameStructure.isLoading;

  useEffect(() => {
    game.fetchGameStructure();

    return (): void => {
      if (game.currentLottery) {
        if (!game.currentLottery.isConfirmed || !game.currentLottery.isValid) {
          lotteries.removeLottery(game.currentLottery.id);
        }
      }

      game.resetGameData();
    };
  }, [game, lotteries]);

  const renderContent = (): JSX.Element => {
    if (isLoading || (!game.currentLottery && !error)) {
      return <Spinner />;
    }

    if (error) {
      return ErrorPage(error);
    }

    if (!gameStructure) {
      return null;
    }

    return <LotteryContainer {...{ onClose, game }} lottery={game.currentLottery} />;
  };

  return <div className={styles.gameContainer}>{renderContent()}</div>;
});

export default GameContainer;
