import React from 'react';

import SingleBet from '../SingleBet';
import Pagination from '../../Pagination';

import styles from './BetsList.scss';

import { UserBetDataInterface } from '../../../../../shared/interfaces/GamesPicker';
import { HeadersInterface } from '../../../../../shared/interfaces';

interface BetsListViewProps {
  userBets: UserBetDataInterface[];
  userBetsHeaders: HeadersInterface;
  onBetDetailsOpen(id: string): void;
  onPaginationPageChange(page: number): void;
}

const BetsListView: React.FC<BetsListViewProps> = (props: BetsListViewProps) => {
  const { userBetsHeaders, onBetDetailsOpen, onPaginationPageChange } = props;

  const renderBets = (): JSX.Element[] => {
    return props.userBets.map((elem: UserBetDataInterface) => {
      return <SingleBet key={elem.id} bet={elem} onBetDetailsOpen={onBetDetailsOpen} />;
    });
  };

  return (
    <div className={styles.betsList}>
      {renderBets()}
      <div className={styles.paginationWrapper}>
        <Pagination
          previousLabel={'-'}
          nextLabel={'+'}
          onPageChange={onPaginationPageChange}
          pageCount={parseInt(userBetsHeaders.xLastPage)}
          paginationPage={parseInt(userBetsHeaders.xPage)}
        />
      </div>
    </div>
  );
};

export default BetsListView;
