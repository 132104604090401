import { observable, action } from 'mobx';

import ModalStoreInterface from '../../shared/interfaces/Stores/ModalInterface';

class ModalStore implements ModalStoreInterface {
  @observable name = '';
  @observable modalProps = {};
  @observable isOpen = false;

  @action
  showModal = (name: string, modalProps: object): void => {
    this.name = name;
    this.modalProps = modalProps;
    this.isOpen = true;
  };

  @action
  hideModal = (): void => {
    this.name = '';
    this.modalProps = {};
    this.isOpen = false;
  };
}

export default ModalStore;
